import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Panel from "./Panel";
import ErrorModal from "./ErrorModal";
import AddProgram from "./AddProgram";
import SelectComponent from "./Select";
import TextInput from "./Input/TextInput";
import RadioButton from "./Button/RadioButton";
import SaveAndCancel from "./Button/SaveAndCancel";

import InstitutionService from "../services/institution";
import StaffService from "../services/staff";

const testID = "NewInstitutionScreen";

const inputValueInitialState = {
  institution: "",
  initialProgram: {
    name: "",
    is_predictions_enabled: true,
  },
};

export default function InstitutionForm({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();

  const [inputValue, setInputValue] = useState(inputValueInitialState);
  const [selectedAccountRepresentative, setSelectedAccountRepresentative] =
    useState({ value: "", label: "" });
  const [initialPrograms, setInitialPrograms] = useState([
    { name: "", id: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [accountRepresentatives, setAccountRepresentatives] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(["", ""]);
  const [overrideMessage, setOverrideMessage] = useState(false);

  const { institution, initialProgram } = inputValue;

  const toggleError = (message) => {
    setErrorMessage(message);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async () => {
    const { data } = await InstitutionService.get(params.id);

    setInputValue({
      institution: data.name,
    });
    setSelectedAccountRepresentative({
      value: data.account_representative.id,
      label:
        data.account_representative.first_name +
        " " +
        data.account_representative.last_name,
    });
    setInitialPrograms(data.programs);
  };

  const getAccountRepresentatives = async () => {
    const response = await StaffService.getAll({
      is_account_representative: true,
    });

    setAccountRepresentatives(
      response.data.results.map((account) => {
        return {
          label: account.first_name + " " + account.last_name,
          value: account.id,
        };
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputValue((prev) => {
      return name.includes("initialProgram")
        ? {
            ...prev,
            initialProgram: {
              ...prev.initialProgram,
              [name.split(".")[1]]: value,
            },
          }
        : {
            ...prev,
            [name]: value,
          };
    });
  };

  const clearForm = () => {
    setInputValue(inputValueInitialState);
  };

  const institutionData = {
    name: institution,
    accountRepresentativeId: selectedAccountRepresentative.value,
    initialProgram,
    institutionId: params.id,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (
      !validateFields([
        "selectedAccountRepresentative",
        "initialProgram",
        "institution",
      ])
    ) {
      return;
    }

    setLoading(true);

    try {
      const response = await InstitutionService.create(institutionData);
      if (response.status === 201) {
        navigate("/home/admin/institutions/");
        setLoading(false);
        setError(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        setOverrideMessage(true);
        setErrorMessage([e.response.status, e.response.data.message]);
      } else {
        setErrorMessage([e.response.status, e.response.statusText]);
      }
      setLoading(false);
      setError(true);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!validateFields(["selectedAccountRepresentative", "institution"])) {
      return;
    }

    setLoading(true);

    try {
      const response = await InstitutionService.update(institutionData);
      if (response.status === 200) {
        setLoading(false);
        setError(false);
        navigate("/home/admin/institutions/");
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        setOverrideMessage(true);
        setErrorMessage([e.response.status, e.response.data.message]);
      } else {
        setErrorMessage([e.response.status, e.response.statusText]);
      }
      setLoading(false);
      setError(true);
    }
  };

  const validateField = (fieldName) => {
    const validation = {
      selectedAccountRepresentative: () => {
        return !selectedAccountRepresentative.value ? true : false;
      },
      institution: () => {
        return !institution ? true : false;
      },
      initialProgram: () => {
        return !initialProgram.name ? true : false;
      },
    };

    return validation[fieldName]();
  };

  const validateFields = (fields) => {
    for (let field of fields) {
      if (validateField(field)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    getAccountRepresentatives();
  }, []);

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      clearForm();
    }
  }, []);

  return (
    <>
      <Panel
        testID={testID}
        title={isEdit ? "Institution" : "Create Institution"}
        edit={false}
        disable={false}
        color="#eee"
      >
        <Container>
          <form onSubmit={isEdit ? handleUpdate : handleSubmit}>
            <TextInput
              text="Institution"
              type="text"
              name="institution"
              value={institution}
              error={validateField("institution") && submitted}
              onChange={handleChange}
              placeholder="Enter the institution's name"
              inputColor={"#332525"}
            />
            <Select
              label="Account Representative"
              options={accountRepresentatives}
              selected={selectedAccountRepresentative}
              onChange={setSelectedAccountRepresentative}
              error={
                validateField("selectedAccountRepresentative") && submitted
              }
              placeholder={"Select an Account Rep"}
              style={{ width: "100%", marginBottom: isEdit ? "15px" : 0 }}
              listColor="#767676"
            />
            {!isEdit && (
              <>
                <Divider />
                <TextInput
                  text="Initial Program"
                  type="text"
                  name="initialProgram.name"
                  value={initialProgram.name}
                  error={validateField("initialProgram") && submitted}
                  onChange={handleChange}
                  placeholder="Enter the name of the first program under this institution"
                  required
                />
                <RadioButton
                  testID={`${testID}-InitialProgram-Checkbox`}
                  buttonLabel="Enable Predictions"
                  value={initialProgram.is_predictions_enabled}
                  onChange={(e) =>
                    handleChange({
                      ...e,
                      target: {
                        ...e.target,
                        name: "initialProgram.is_predictions_enabled",
                      },
                    })
                  }
                  borderColor={"#093156"}
                  color={"#fff"}
                  dark
                />
              </>
            )}
            <SaveAndCancel
              testID={testID}
              loading={loading}
              onClick={() => navigate("/home/admin/institutions/")}
            />
          </form>
        </Container>
      </Panel>
      {isEdit && (
        <AddProgram
          institutionId={params.id}
          initialPrograms={initialPrograms}
          setError={setError}
          setErrorMessage={setErrorMessage}
          setOverrideMessage={setOverrideMessage}
        />
      )}
      <ErrorModal
        overrideMessage={overrideMessage}
        color="#eee"
        buttonLabel={"Back"}
        callback={() => {
          toggleError(["", ""]);
          setOverrideMessage(false);
        }}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
        error={error}
      />
    </>
  );
}

const Select = styled(SelectComponent)`
  width: 100%;

  ul {
    width: 100%;
    border: 1px solid #c2c2c2;
    border-top: none;
    border-bottom: none;
    border-right: none;
  }

  li {
    margin-bottom: 0.5rem;
    padding: 8px 0px 10px;
    cursor: pointer;
    font-family: "Lato";
    color: #332525;
    &:hover {
      background-color: lightgray;
    }
  }
`;

const Container = styled.div``;

const Divider = styled.hr`
  border: 1px solid #c2c2c2;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  margin: 12px 0px;
`;

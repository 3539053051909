import React from "react";
import styled from "styled-components";

import { ReactComponent as CheckIcon } from "../../assets/CheckIcon.svg";
import { ReactComponent as CheckIconDark } from "../../assets/CheckIconDark.svg";

const Checkbox = ({
  onChange,
  value,
  dark,
  light,
  color,
  borderColor,
  testID,
}) => {
  return (
    <CheckboxInput
      data-e2e-id={testID}
      onClick={() => onChange(!value)}
      checked={value}
      color={color}
      borderColor={borderColor}
    >
      {dark && value ? <CheckIconDark /> : null}
      {light ? <CheckIcon /> : null}
    </CheckboxInput>
  );
};

export default Checkbox;

const CheckboxInput = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 22px;
  border: 2px solid ${(props) => props.borderColor};
  height: 22px;
  border-radius: 3px;
  background: ${(props) => props.checked && props.color};
`;

import api from "./api";

export default class InsightsService {
  static async getAll(params) {
    let response = api.get("/insights/", {
      params,
    });
    return response;
  }
  static async get(id, params) {
    let response = api.get(`/insights/${id}/`, {
      params,
    });
    return response;
  }
}

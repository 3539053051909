import styled from "styled-components";
import Modal from "./Modal";

const ErrorModal = ({
  testID,
  isOpen,
  callback,
  buttonLabel,
  errorMessage = ["", ""],
  error = true,
  overrideMessage = false,
  color,
  isMultipleErrors = false,
}) => {
  const onClick = () => {
    callback();
    return;
  };

  const getMessageFromCode = () => {
    if (overrideMessage) {
      return errorMessage[1];
    }

    switch (errorMessage[0]) {
      case 500:
        return "Server error, verify the form/file data and try again later.";
      case 400:
        return "Verify the form/file data and try again.";
      default:
        return "";
    }
  };

  const getErrorsFromLine = (error) => {
    let errorMsg = [];
    for (let key of Object.keys(error)) {
      errorMsg.push(`{${key} - ${error[key].join("; ")}}`);
    }
    return errorMsg.join(", ");
  };

  const getErrorBody = () => {
    if (isMultipleErrors) {
      return errorMessage[1].map((error, i) => {
        if (Object.keys(error).length > 0) {
          return (
            <div key={`e-${i}`} style={{ fontWeight: "700" }}>
              Line: {i + 2}: &nbsp; {getErrorsFromLine(error)}
            </div>
          );
        }
        return <></>;
      });
    }
    return (
      getMessageFromCode() &&
      error && <div style={{ fontWeight: "700" }}>{getMessageFromCode()}</div>
    );
  };

  return (
    <div>
      {isOpen && (
        <Modal
          testID={testID}
          title={
            error
              ? "Error"
              : "" + (errorMessage[1] && error ? ": " : "" + errorMessage[1])
          }
          color={color}
          error={true}
          flex={true}
          onClick={onClick}
          cancelLabel={buttonLabel}
        >
          <TextContainer>
            <div style={{ maxHeight: "200px", overflow: "auto" }}>
              {getErrorBody()}
            </div>
            {!error && (
              <div style={{ fontWeight: "700" }}> {errorMessage[0]} </div>
            )}
          </TextContainer>
        </Modal>
      )}
    </div>
  );
};

const TextContainer = styled.div`
  width: 650px;
  display: flex;
  font-weight: 700;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0px;
`;

export default ErrorModal;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import moment from "moment";

import Modal from "../../components/Modal";
import Warning from "../../components/Warning/Warning";
import Subtitle from "../../components/Subtitle";
import Button from "../../components/Button/Button";
import RadioButton from "../../components/Button/RadioButton";
import CompleteApplicantList from "../../components/CompleteApplicantList";
import Table from "../../components/Table/Table";
import TableOptionsComponent from "../../components/TableOptions";
import ErrorModal from "../../components/ErrorModal";

import { ReactComponent as Line } from "../../assets/Line.svg";
import { ReactComponent as FilesIcon } from "../../assets/Files.svg";
import { ReactComponent as InfoIcon } from "../../assets/circle-alert.svg";
import {
  Section,
  TableContainer,
  BoldText,
  DeleteModalText,
  DeleteModalTextContainer,
  HorizontalLine,
  LineContainer,
  RightContainer,
  Select,
  SelectedFileContainer,
  Text,
  SelectWrap,
} from "./styles";

import {
  AnalyzedDataTableTitleStaff,
  AnalyzedDataTableTitle,
} from "../../services/data";
import AnalyzedDataService from "../../services/analyzedData";
import usePagination from "../../hooks/usePagination";
import { userSelector } from "../../store/reducers/userReducer";
import { institutionSelector } from "../../store/reducers/institutionReducer";
import { setPeriod, setProgram } from "../../store/reducers/institutionReducer";
import {
  downloadFileFromData,
  fetchPeriods,
  fetchProgramsStaffUser,
  mapPrograms,
} from "../../utils/analysis";
import { parseDate, parseName } from "../../utils/parses";

export default function AnalyzedData() {
  const dispatch = useDispatch();
  const {
    is_staff,
    institution: institutionState,
    programs: userPrograms,
  } = useSelector(userSelector);
  const { selectedInstitution, selectedPeriod, selectedProgram } =
    useSelector(institutionSelector);

  const programsState =
    (is_staff ? institutionState?.programs : userPrograms) ?? [];
  const parsedPrograms = mapPrograms(programsState);

  const [openModal, setOpenModal] = useState(false);
  const [deleteModalFile, setDeleteModalFile] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isPrediction, setIsPrediction] = useState(false);
  const [isSkill, setIsSkill] = useState(false);
  const [isInsight, setIsInsight] = useState(false);
  const [programs, setPrograms] = useState(parsedPrograms);
  const [periods, setPeriods] = useState(null);
  const [programSelected, setProgramSelected] = useState(selectedProgram);
  const [periodSelected, setPeriodSelected] = useState(selectedPeriod);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isMultipleErrors, setIsMultipleErrors] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(["", ""]);

  const titles = is_staff
    ? AnalyzedDataTableTitleStaff
    : AnalyzedDataTableTitle;
  const renderRowFunction = is_staff ? renderRowStaff : renderRow;

  const getColor = () => {
    if (isDragReject) {
      return "blue";
    }
    return "black";
  };

  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    forceReload,
    isLoading,
  ] = usePagination(AnalyzedDataService.getAll, {}, true);

  const getType = () => {
    if (isInsight) return "INSIGHTS";
    if (isPrediction) return "PREDICTIONS";
    if (isSkill) return "SKILLS";

    return false;
  };

  const tableOptions = [
    {
      label: "Download",
      callback: async (id) => {
        const response = await AnalyzedDataService.getDownload(id);
        const currentDate = moment(new Date()).format("MM_DD_YYYY");
        const selectedAnalyzedData = data.results.find(
          (analyzedData) => analyzedData.id === id
        );
        const fileName = `${selectedProgram.label}_${selectedPeriod.label}_${selectedAnalyzedData.type}_${currentDate}.csv`;

        downloadFileFromData(fileName, response.data);
      },
    },
    {
      label: "Delete",
      callback: async (id) => {
        setOpenDeleteModal(true);
        setDeleteModalFile(id);
      },
    },
  ];

  const clearFiles = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
  };

  const uploadAnalyzedData = async () => {
    let shouldReload = true;

    setIsLoadingUpload(true);

    try {
      if (acceptedFiles.length > 0) {
        const type = getType();
        if (!type)
          throw new Error(
            "Please, select the matching analyzed data type for this data file"
          );

        const bodyFormData = new FormData();

        bodyFormData.append("file", acceptedFiles[0]);
        bodyFormData.append("type", type);
        bodyFormData.append(
          "institution_id",
          selectedInstitution.value || institutionState.id
        );
        bodyFormData.append("period_id", periodSelected.value);
        bodyFormData.append("period__id", periodSelected.value);
        bodyFormData.append("program_id", programSelected.value);

        const response = await AnalyzedDataService.create(bodyFormData);
        if (response.status !== 201) {
          throw response;
        }

        await dispatch(
          setProgram({
            value: programSelected.value,
            label: programSelected.label,
            is_predictions_enabled: programSelected.is_predictions_enabled,
          })
        );
        await dispatch(
          setPeriod({
            value: periodSelected.value,
            label: periodSelected.label,
          })
        );
      } else {
        throw new Error("Please, select a file");
      }
    } catch (e) {
      shouldReload = false;
      handleError(e);
    } finally {
      setIsLoadingUpload(false);
      clearFiles();

      if (shouldReload) {
        setOpenModal(false);
        forceReload();
      }
    }
  };

  const handleClick = () => {
    clearFiles();
    setIsLoadingUpload(false);
    setOpenModal(false);
    setOpenDeleteModal(false);
  };

  const { getRootProps, open, acceptedFiles, getInputProps, isDragReject } =
    useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      maxFiles: 1,
      accept: ".csv",
    });

  const handleError = (e) => {
    setIsMultipleErrors(false);
    setOverrideMessage(true);

    if (e?.response?.data) {
      if (
        e.response.data?.errors.some((error) => Object.keys(error).length > 0)
      ) {
        setIsMultipleErrors(true);
        return setErrorMessage([e.response.status, e.response.data.errors]);
      }

      return setErrorMessage([e.response.status, e.response.data.error]);
    }

    if (e.message) {
      return setErrorMessage([400, e.message]);
    }

    return setErrorMessage([e.response.status, e.response.statusText]);
  };

  const deleteData = async () => {
    try {
      setIsLoadingDelete(true);
      const response = await AnalyzedDataService.delete(deleteModalFile);
      if (response) {
        forceReload();
      }
    } catch (e) {
      setErrorMessage([e.response.status, e.response.statusText]);
    } finally {
      setOpenDeleteModal(false);
      clearFiles();
      setIsLoadingDelete(false);
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteModalFile(null);
  };

  function renderRow(item) {
    return (
      <tr key={item.id}>
        <td>
          <TableOptionsComponent id={item.id} options={tableOptions} />
        </td>
        <td>{parseDate(item.data_analyzed)}</td>
        <td> {item.type} </td>
        <td> {item?.total_of_applicants || "--"} </td>
      </tr>
    );
  }

  function renderRowStaff(item) {
    return (
      <tr key={item.id}>
        <td>
          {" "}
          <TableOptionsComponent id={item.id} options={tableOptions} />{" "}
        </td>
        <td>{parseDate(item.data_analyzed)}</td>
        <td> {item.type} </td>
        <td> {"Upload"} </td>
        <td> {item.action_by || "-"} </td>
      </tr>
    );
  }

  useEffect(() => {
    if (openModal) {
      setProgramSelected(selectedProgram);
      setPeriodSelected(selectedPeriod);
    }
  }, [openModal]);

  useEffect(() => {
    const fetchPrograms = async () => {
      const programs = is_staff
        ? await fetchProgramsStaffUser(selectedInstitution.value)
        : mapPrograms(programsState);

      setPrograms(programs);
      setProgramSelected(programs[0]);
    };

    fetchPrograms();

    return () => {};
  }, [selectedInstitution?.value]);

  useEffect(() => {
    if (programSelected && programSelected.value) {
      fetchPeriods(programSelected.value).then((parsedPeriods) => {
        setPeriods(parsedPeriods);
        if (parsedPeriods[0]) {
          setPeriodSelected(parsedPeriods[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programSelected?.value]);

  return (
    <>
      {data.results &&
        (data.results.length === 0 || selectedPeriod.value === "") &&
        !isLoading && (
          <Warning>
            <InfoIcon />
            No analyzed data available for the selected period
          </Warning>
        )}
      {selectedPeriod.value &&
        selectedProgram.value &&
        data.results.length > 0 && (
          <CompleteApplicantList
            style={{ marginTop: "12px" }}
            selectedPeriod={selectedPeriod}
            selectedProgram={selectedProgram}
          />
        )}

      <Section>
        <Subtitle>ANALYZED DATA HISTORY</Subtitle>
        {is_staff && (
          <Button
            style={{ margin: "0px", position: "relative" }}
            onClick={() => setOpenModal(!openModal)}
          >
            Upload Analyzed Data
          </Button>
        )}
      </Section>
      <TableContainer>
        <Table
          withOptions
          titles={titles}
          label="Analyzed Data per page"
          data={data.results}
          loading={isLoading}
          totalPages={options.totalPages}
          rowsPerPage={options.rowsPerPage}
          pageSelected={debouncedOptions.displayPageSelected}
          withDebouncer={true}
          onChange={onChange}
          onChangeDebounced={onChangeDebounced}
          renderRow={renderRowFunction}
        />
      </TableContainer>
      {openModal && (
        <Modal
          flex={true}
          onClick={handleClick}
          title="Analyzed Data Upload"
          isLoading={isLoadingUpload}
          onSubmit={uploadAnalyzedData}
          label="Upload File"
          color="#eee"
        >
          <LeftContainer>
            <SelectWrap>
              <Select
                smallSpanFont
                label="Program"
                options={
                  programs ? programs : [{ value: "", label: "Loading" }]
                }
                selected={programSelected}
                onChange={setProgramSelected}
                size={15}
                title={programSelected?.label}
              />{" "}
              <Select
                label="Period"
                options={periods ? periods : [{ value: "", label: "Loading" }]}
                selected={periodSelected}
                onChange={setPeriodSelected}
                size={15}
                title={periodSelected?.label}
              />{" "}
            </SelectWrap>
            <RadioButtonsWrap>
              <RadioButton
                buttonLabel="Predictions"
                name="prediction"
                dark={true}
                value={isPrediction}
                onChange={() => {
                  setIsPrediction(true);
                  setIsSkill(false);
                  setIsInsight(false);
                }}
                disabled={!programSelected.is_predictions_enabled}
              />
              <RadioButton
                buttonLabel="Skills"
                name="competency"
                dark={true}
                value={isSkill}
                onChange={() => {
                  setIsPrediction(false);
                  setIsSkill(true);
                  setIsInsight(false);
                }}
              />
              <RadioButton
                buttonLabel="Insights"
                name="insights"
                dark={true}
                value={isInsight}
                onChange={() => {
                  setIsPrediction(false);
                  setIsSkill(false);
                  setIsInsight(true);
                }}
              />
            </RadioButtonsWrap>
          </LeftContainer>
          <RightContainer
            height={"236px"}
            color={getColor()}
            {...getRootProps({ className: "dropzone" })}
          >
            {!(acceptedFiles.length > 0) ? (
              <>
                <h3>Drag and Drop</h3>
                <LineContainer>
                  <Line />
                  <span>or </span>
                  <Line />
                </LineContainer>
                <input {...getInputProps()} />
                <Button type="button" onClick={open}>
                  Upload
                </Button>
                <span style={{ marginTop: "-35px" }}> CSV File Only </span>
              </>
            ) : (
              <SelectedFileContainer>
                <FilesIcon />
                <Text>
                  <BoldText>{parseName(acceptedFiles[0].name)}</BoldText>
                  &nbsp;
                  {"is attached!"}
                </Text>
                <LineContainer>
                  <HorizontalLine />
                </LineContainer>
              </SelectedFileContainer>
            )}
          </RightContainer>{" "}
        </Modal>
      )}
      {openDeleteModal && (
        <Modal
          flex={true}
          onClick={closeDeleteModal}
          label={"Delete Data"}
          onSubmit={deleteData}
          isLoading={isLoadingDelete}
          noBorder
          deleteModal={true}
        >
          <DeleteModalTextContainer>
            <DeleteModalText>
              Are you sure you want to delete{" "}
              {data.results
                .filter((item) => item.id === deleteModalFile)[0]
                .type.toUpperCase()}{" "}
              data from ?
            </DeleteModalText>
            <DeleteModalText>This cannot be undone.</DeleteModalText>
          </DeleteModalTextContainer>
        </Modal>
      )}
      <ErrorModal
        color="#eee"
        title="Error"
        buttonLabel={"Back"}
        callback={() => {
          setErrorMessage(["", ""]);
          setOverrideMessage(false);
          setIsMultipleErrors(false);
        }}
        overrideMessage={overrideMessage}
        isMultipleErrors={isMultipleErrors}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
      />
    </>
  );
}

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

const RadioButtonsWrap = styled.div`
  margin-top: 22px;

  p {
    margin-right: 0;
  }
`;

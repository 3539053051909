import styled from "styled-components";

export default function Toggle({ testID, onChange, value, ...props }) {
  return (
    <Container
      data-e2e-id={`${testID}-Toggle`}
      onClick={() => onChange(value)}
      {...props}
    >
      <Switch>
        <div className={value ? "checked" : "unchecked"} />
        <span></span>
      </Switch>
      <p> {props.label}</p>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 4px 10px 0px;

  p {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    white-space: nowrap;
    font-weight: normal;
    color: #332525;
    margin-left: 12px;
  }
`;

const Switch = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 24px;
  height: 12px;
  border: 1px solid #373535;
  border-radius: 34px;

  div {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span:before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    left: 1px;
    bottom: 1px;
    background-color: #373535;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  div.checked + span:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }
`;

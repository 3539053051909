import api from "./api";

export default class CompetenciesService {
  static async getAll(params) {
    let response = api.get("/competencies/", {
      params,
    });
    return response;
  }

  static async get(id, params) {
    let response = api.get(`/competencies/${id}/`, {
      params,
    });
    return response;
  }
}

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Card from "../../components/Card";
import SelectV2 from "../../components/SelectV2";
import Table from "../../components/Table/Table";
import Paragraph from "../../components/Paragraph";
import SearchInput from "../../components/SearchInput";
import Warning from "../../components/Warning/Warning";
import SortButton from "../../components/Sort/SortButton";
import TableOptionsComponent from "../../components/TableOptions";
import { ReactComponent as InfoIcon } from "../../assets/circle-alert.svg";
import { ReactComponent as SeenIcon } from "../../assets/seen.svg";

import usePagination from "../../hooks/usePagination";
import PredictionsService from "../../services/predictions";
import {
  PredictionTableTitles,
  SortPredictionTitles,
} from "../../services/data";
import { institutionSelector } from "../../store/reducers/institutionReducer";

export default function Predictions() {
  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    isLoading,
  ] = usePagination(PredictionsService.getAll, SortPredictionTitles[0], true);
  const navigate = useNavigate();

  const [cardData, setCardData] = useState([]);
  const [loadingCards, setLoadingCards] = useState(false);
  const { selectedPeriod } = useSelector(institutionSelector);

  const fetchData = async () => {
    setLoadingCards(true);

    const response = await PredictionsService.getAll({
      student__period: selectedPeriod.value,
      origin_data__period: selectedPeriod.value,
      period__id: selectedPeriod.value,
    });

    setCardData(response.data);
    setLoadingCards(false);
  };

  const tableOptions = [
    {
      label: "Detailed View",
      callback: async (id) => {
        navigate(`/home/analysis/${id}/applicant/`);
      },
      newTabOption: true,
      newTabCallback: async (id) => {
        window.open(`/home/analysis/${id}/applicant/?newTab=true`);
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [selectedPeriod]);

  return (
    <div>
      {" "}
      <Paragraph notJustify={true}>
        Machine learning-produced prediction of applicants’ likelihood of
        admission (or alternately requested prediction).{" "}
      </Paragraph>
      {data.results &&
        (data.results.length === 0 || selectedPeriod.value === "") &&
        !loadingCards && (
          <div style={{ marginTop: "20px" }}>
            <Warning>
              <InfoIcon />
              There is currently no analyzed data available for the selected
              class.
            </Warning>
          </div>
        )}
      {data.results.length > 0 && selectedPeriod.value && (
        <CardContainer isLoading={loadingCards}>
          <Card
            noPointer
            name="Tier 1"
            isLoading={loadingCards}
            number={cardData.tier_1 ? cardData.tier_1 : 0}
            staticColor="#0C7E55"
            label="applicants"
            gray={true}
            barMax={cardData.count}
          />
          <Card
            noPointer
            name="Tier 2"
            isLoading={loadingCards}
            number={cardData.tier_2 ? cardData.tier_2 : 0}
            staticColor="#E88239"
            label="applicants"
            gray={true}
            barMax={cardData.count}
          />
          <Card
            noPointer
            name="Tier 3"
            staticColor="#930BB5"
            isLoading={loadingCards}
            number={cardData.tier_3 ? cardData.tier_3 : 0}
            label="applicants"
            gray={true}
            barMax={cardData.count}
          />
        </CardContainer>
      )}
      <SortContainer>
        <SearchInput
          placeholder="Search by CAS ID"
          max={true}
          value={debouncedOptions.displaySearchText}
          onChange={(e) => {
            onChangeDebounced({ displaySearchText: e.target.value });
          }}
        />{" "}
        <SelectV2
          style={{ margin: "0 22px" }}
          label="Sort by:"
          options={SortPredictionTitles}
          selected={options.columnSorted}
          onChange={(columnSorted) => onChange({ columnSorted })}
        />
        <SortButton
          value={options.ascending}
          onChange={(value) => {
            onChange({ ascending: value });
          }}
        />{" "}
      </SortContainer>
      <TableContainer>
        <Table
          titles={PredictionTableTitles}
          label="Applicants per page"
          data={data.results}
          loading={isLoading}
          totalPages={options.totalPages}
          rowsPerPage={options.rowsPerPage}
          pageSelected={debouncedOptions.displayPageSelected}
          withDebouncer={true}
          onChange={onChange}
          withOptions
          onChangeDebounced={onChangeDebounced}
          renderRow={(item) => (
            <tr key={item.id}>
              <td>
                {" "}
                <TableOptionsComponent
                  id={item.student.id}
                  options={tableOptions}
                />{" "}
              </td>
              <td>
                {item.student.cas_id} {item.seen && <SeenIcon />}{" "}
              </td>
              <td> {item.tier} </td>
              <td>
                {" "}
                {item.prediction_score % 1 === 0
                  ? Math.round(item.prediction_score)
                  : item.prediction_score}{" "}
              </td>
              <td> {item.status} </td>
            </tr>
          )}
        />
      </TableContainer>
    </div>
  );
}

const CardContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 44px;
  gap: 22px;

  .card {
    width: 100%;
    div {
      ${(props) => !props.isLoading && "justify-content: start;"}
      span {
        margin-right: 5px;
      }
    }
  }
`;

const SortContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 44px;
`;

const TableContainer = styled.section`
  display: flex;
  margin-top: 22px;
`;

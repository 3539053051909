import styled from "styled-components";

const Paragraph = styled.p`
  max-width: 700px;
  text-align: justify;
  ${(props) => props.notJustify && `text-align: left;`}
  font-weight: 500;
`;

export default Paragraph;

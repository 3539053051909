import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import TextInput from "../../components/Input/TextInput";
import Panel from "../../components/Panel";
import Header from "../../components/Header";
import RadioButton from "../../components/Button/RadioButton";
import BackComponent from "../../components/Back";
import Toggle from "../../components/Toggle";
import SaveAndCancel from "../../components/Button/SaveAndCancel";
import ErrorModal from "../../components/ErrorModal";
import ProgramFilter from "../../components/Sort/Filter";

import UserService from "../../services/user";
import InstitutionService from "../../services/institution";
import { userSelector } from "../../store/reducers/userReducer";
import { institutionSelector } from "../../store/reducers/institutionReducer";

const testID = "NewInstUserScreen";

export default function SaveUser() {
  const userAdmin = useSelector(userSelector);
  const { selectedInstitution } = useSelector(institutionSelector);
  const params = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    number: "",
    programs: [],
    isActive: false,
    userAccess: false,
  });
  const [institutionPrograms, setInstitutionPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [errorMessage, setErrorMessage] = useState(["", ""]);
  const [overrideMessage, setOverrideMessage] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { email, firstName, lastName, number } = user;

  const toggleError = (message) => {
    setErrorMessage(message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prev) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));
  };

  const hasProgramAccess = (id) => {
    return user.programs.find((program) => program.id === id);
  };

  const handleProgramAccess = (isNewValue, program) => {
    isNewValue ? addProgram(program) : removeProgram(program.id);
  };

  const addProgram = (program) => {
    setUser({
      ...user,
      programs: user.programs.concat(program),
    });
  };

  const removeProgram = (id) => {
    setUser({
      ...user,
      programs: user.programs.filter((program) => program.id !== id),
    });
  };

  const handleSelectAll = () => {
    const updatedIsCheckAll = !isCheckAll;

    setUser({
      ...user,
      programs: updatedIsCheckAll
        ? institutionPrograms
        : institutionPrograms.filter((program) => program.disabled),
    });
    setIsCheckAll(updatedIsCheckAll);
  };

  const fetchUserData = useCallback(async () => {
    const { data: userData } = await UserService.getUser(params.id);

    const usersProgramsUnion = [
      ...userData.programs,
      ...userAdmin.programs.filter(
        (program) =>
          !userData.programs.find(
            (userProgram) => userProgram.id === program.id
          )
      ),
    ];
    const userHasAccessToAllPrograms = userAdmin.is_staff
      ? userData.programs.length === userData.institution.programs.length
      : userData.programs.length === usersProgramsUnion.length;

    setUser({
      id: userData.id,
      firstName: userData.first_name,
      lastName: userData.last_name,
      number: userData.phone_number,
      email: userData.email,
      isActive: userData.is_active,
      userAccess: userData.is_user_admin,
      programs: userData.programs,
      isActive: userData.is_active,
    });
    setInstitutionPrograms(
      userAdmin.is_staff
        ? userData.institution.programs
        : usersProgramsUnion.map((program) => ({
            ...program,
            disabled:
              userData.programs.find(
                (userProgram) => userProgram.id === program.id
              ) &&
              !userAdmin.programs.find(
                (userProgram) => userProgram.id === program.id
              ),
          }))
    );
    setIsCheckAll(userHasAccessToAllPrograms);

    return () => {}; // cleanup function
  }, []);

  const fetchPrograms = useCallback(async () => {
    if (userAdmin.is_staff) {
      const institutionId = selectedInstitution.value;
      const { data: institutionData } = await InstitutionService.get(
        institutionId
      );

      setInstitutionPrograms(institutionData.programs);
    } else {
      setInstitutionPrograms(userAdmin.programs);
    }

    return () => {}; // cleanup function
  }, [selectedInstitution.value]);

  useEffect(() => {
    if (params.id) {
      return fetchUserData();
    }

    return fetchPrograms();
  }, [fetchUserData, fetchPrograms, selectedInstitution.value]);

  const handleUserAccess = (e) => {
    const access = e.target.value;
    setUser({
      ...user,
      userAccess: access,
    });
  };

  const getProgramsAccess = () => user.programs.map((item) => item.id);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (
      !validateFields([
        "email",
        "number",
        "firstName",
        "lastName",
        "selectedInstitution",
      ])
    ) {
      return;
    }

    setLoading(true);

    try {
      const userData = {
        id: params.id,
        institutionId: selectedInstitution.value
          ? selectedInstitution.value
          : userAdmin.institution_id,
        isActive: user.isActive,
        email,
        firstName,
        lastName,
        phone: number,
        userAccess: user.userAccess,
        programsAccess: getProgramsAccess(),
      };
      const response = await UserService.save(userData);

      if (!(response.status === 201 || response.status === 200)) {
        throw new Error(
          `Error ${params.id ? "updating" : "creating"} institutional user.`
        );
      }

      navigate("/home/users");
    } catch (err) {
      if (err?.response?.data?.email) {
        setOverrideMessage(true);
        setErrorMessage([err.response.status, "Email already in use."]);
      } else {
        setErrorMessage([err.response.status, err.response.statusText]);
      }
    } finally {
      setLoading(false);
    }
  };

  const validateField = (fieldName) => {
    const hasSelectedInstitution = userAdmin.is_staff
      ? selectedInstitution.value
      : userAdmin.institution_id;

    const validation = {
      email: () => {
        return !(
          email &&
          email
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        );
      },
      firstName: () => {
        return !firstName ? true : false;
      },
      lastName: () => {
        return !lastName ? true : false;
      },
      number: () => {
        return !number ? true : false;
      },
      selectedInstitution: () => {
        return !hasSelectedInstitution ? true : false;
      },
    };
    return validation[fieldName]();
  };

  const validateFields = (fields) => {
    for (const field of fields) {
      if (validateField(field)) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Header title="User Administration" />
      {!params.id && <ProgramFilter isOnlyInstitution />}
      <BackComponent
        testID={testID}
        to="/home/users"
        label="Back to all Users"
        style={{ marginBottom: "12.5px" }}
      />
      <Container>
        <Panel
          testID={testID}
          title={params.id != null ? "Edit User" : "New User"}
          edit={false}
          disable={false}
          color="#eee"
        >
          <form onSubmit={handleSubmit}>
            <TextInput
              text="Primary User/Email Address"
              type="email"
              name="email"
              placeholder="Enter an email address"
              value={email}
              error={validateField("email") && submitted}
              onChange={handleChange}
              inputColor={"#332525"}
            />
            <TextInput
              text="First Name"
              type="text"
              name="firstName"
              placeholder="Enter the user’s first name"
              value={firstName}
              error={validateField("firstName") && submitted}
              onChange={handleChange}
              inputColor={"#332525"}
            />
            <TextInput
              text="Last Name"
              type="text"
              name="lastName"
              placeholder="Enter the user’s last name"
              value={lastName}
              error={validateField("lastName") && submitted}
              onChange={handleChange}
              inputColor={"#332525"}
            />
            <TextInput
              text="Phone Number"
              type="tel"
              name="number"
              value={number}
              error={validateField("number") && submitted}
              inputColor={"#332525"}
              onChange={handleChange}
              placeholder="(555) 123-4567"
            />
            <Wrap>
              <Heading data-e2e-id={`${testID}-Checkbox-Title`}>
                User Administration Access
              </Heading>
              <RadioButton
                testID={`${testID}-Checkbox`}
                buttonLabel="Permit user access"
                dark={true}
                color="#fff"
                borderColor="#332525"
                value={user.userAccess}
                name="userAccess"
                onChange={handleUserAccess}
              />
            </Wrap>
            <Wrap data-e2e-id={`${testID}-ProgramAccess`} noMargin>
              <Heading>Programs</Heading>
              <ToggleV2
                value={isCheckAll}
                testID={testID}
                label={"Select All"}
                onChange={handleSelectAll}
              />
              <ProgramsContainer>
                {institutionPrograms.map((institutionProgram) => (
                  <RadioButton
                    buttonLabel={institutionProgram.name}
                    dark={true}
                    key={institutionProgram.id}
                    color="#fff"
                    borderColor="#332525"
                    value={hasProgramAccess(institutionProgram.id)}
                    name="programsAccess"
                    onChange={(e) =>
                      handleProgramAccess(e.target.value, institutionProgram)
                    }
                    disabled={institutionProgram.disabled}
                  />
                ))}
              </ProgramsContainer>
            </Wrap>
            <SaveAndCancel
              testID={testID}
              loading={loading}
              onClick={() => navigate("/home/users/")}
            />
          </form>
        </Panel>
      </Container>
      <ErrorModal
        color="#eee"
        overrideMessage={overrideMessage}
        buttonLabel={"Back"}
        callback={() => {
          toggleError(["", ""]);
          setOverrideMessage(false);
        }}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
        error={true}
      />
    </>
  );
}

const ToggleV2 = styled(Toggle)`
  label > span {
    padding: 0px !important;
  }
`;

const Container = styled.div`
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 22px;
  padding-bottom: 22px;

  background-color: #f9f9f9;
  height: 100%;
  span {
    display: flex;
    p {
      color: #093156;
      margin-left: 10px;
    }
    a {
      text-decoration: none;
    }
  }
`;

const Wrap = styled.div`
  margin: ${({ noMargin }) => (noMargin ? "0" : "20px 0")};
`;

const ProgramsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: fit-content;
  max-height: 200px;
`;

const Heading = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #767676;
  margin: 0;
`;

import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import GlobalStyle from "./utils/globalStyle";
import HomeLayout from "./layouts/HomeLayout";
import RouteGuard from "./components/RouteGuard/RouteGuard";

import Analysis from "./screens/Analysis";
import Predictions from "./screens/Analysis/Predictions";
import Insights from "./screens/Analysis/Insights";
import Competencies from "./screens/Analysis/Competencies";
import ApplicantBreaddown from "./screens/Analysis/ApplicantBreakdown";

import Transfer from "./screens/Transfer";
import OriginData from "./screens/Transfer/OriginData";
import AnalyzedData from "./screens/Transfer/AnalyzedData";
import FileStorage from "./screens/Transfer/FileStorage";

import Login from "./screens/Login";
import Support from "./screens/Support";
import ResetPassword from "./screens/ResetPassword";
import RecoverPassword from "./screens/RecoverPassword";

import UserAdministration from "./screens/UserAdmin/";
import SaveUser from "./screens/UserAdmin/NewUser";
import ViewUser from "./screens/UserAdmin/ViewUser";

import AdminPanel from "./screens/AdminPanel";
import Admin from "./screens/AdminPanel/NewInstitution";
import Institutions from "./screens/AdminPanel/Institutions";
import ViewInstitution from "./screens/AdminPanel/ViewInstitution";
import Users from "./screens/AdminPanel/Users";
import EditInstitution from "./screens/AdminPanel/EditInstitution";
import SaveStaffUser from "./screens/AdminPanel/SaveStaffUser";
import ViewStaffUser from "./screens/AdminPanel/ViewStaffUser";

import { userSelector } from "./store/reducers/userReducer";

function App() {
  const { is_staff } = useSelector(userSelector);

  return (
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        {/* Authentication routes */}
        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="recover-password" element={<RecoverPassword />} />

        <Route path="home" element={<RouteGuard component={<HomeLayout />} />}>
          {/*  Analysis Tab */}
          <Route path="analysis" element={<Analysis />}>
            <Route path="predictions" element={<Predictions />} />
            <Route path="insights" element={<Insights />} />
            <Route path="skills" element={<Competencies />} />
            <Route path="" element={<Navigate to="insights" />} />
          </Route>
          <Route
            path="analysis/:id/applicant"
            element={<ApplicantBreaddown />}
          />
          {/* Transfer Tab  */}
          <Route path="transfer" element={<Transfer />}>
            {is_staff && <Route path="origin" element={<OriginData />} />}
            <Route path="analyzed" element={<AnalyzedData />} />
            <Route path="storage" element={<FileStorage />} />
            <Route
              path=""
              element={<Navigate to={is_staff ? "origin" : "analyzed"} />}
            />
          </Route>
          {/* Admin section for the University:  Users */}

          <Route path="users" element={<UserAdministration />} />
          <Route path="users/:id" element={<ViewUser />} />
          <Route path="users/new" element={<SaveUser />} />
          <Route path="users/:id/edit" element={<SaveUser />} />

          {/*  Admin section for the Harqen team  */}
          <Route path="admin" element={<AdminPanel />}>
            <Route path="institutions/new" element={<Admin />} />
            <Route path="institutions" element={<Institutions />} />
            <Route path="institutions/:id" element={<ViewInstitution />} />
            <Route path="institutions/:id/edit" element={<EditInstitution />} />

            <Route path="users" element={<Users />} />
            <Route path="users/new" element={<SaveStaffUser />} />
            <Route path="users/:id" element={<ViewStaffUser />} />
            <Route path="users/:id/edit" element={<SaveStaffUser />} />
            <Route path="" element={<Navigate to="institutions" />} />
          </Route>

          {/* Support Route */}
          <Route path="support" element={<Support />} />
        </Route>
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { useLocation } from "react-router-dom";

import TabContainer, { TabItem } from "../../components/Tab";
import TabSelected from "../../components/Tab/TabSelected";
import BackComponent from "../../components/Back";
import Header from "../../components/Header";

function AdminPanel() {
  const location = useLocation();
  const showBackbutton =
    location.pathname !== "/home/admin/institutions" &&
    location.pathname !== "/home/admin/users";

  const backButtonProps = {
    label: "Back to all Institutions",
    to: "/home/admin/institutions",
  };

  if (location.pathname.includes("/users")) {
    backButtonProps.label = "Back to all Admin Users";
    backButtonProps.to = "/home/admin/users";
  }

  return (
    <>
      <Header testID={"AdminPanel"} title="Administration Panel" />

      {showBackbutton && (
        <BackComponent testID={"AdminPanel"} {...backButtonProps} />
      )}

      {location.pathname.includes("/users/") ||
      location.pathname.includes("/institutions/") ? null : (
        <TabContainer style={{ paddingTop: "44px", background: "white" }}>
          <TabItem testID={"Institutions"} to="/home/admin/institutions">
            Institutions
          </TabItem>
          <TabItem testID={"AdminUsers"} to="/home/admin/users">
            Admin Users
          </TabItem>
        </TabContainer>
      )}
      {/* Renders the tab selected from the TabContainer */}
      <TabSelected />
    </>
  );
}

export default AdminPanel;

import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";

import Button from "../components/Button/Button";
import { ReactComponent as LogoLogin } from "../assets/logo_login.svg";
import RoundedError from "../assets/RoundedError.svg";
import RoundedErrorWhite from "../assets/RoundedErrorWhite.svg";

import AuthService from "../services/authentication";

export default function RecoverPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState(
    "Please, type your email below. We will send you a link to update your password."
  );

  const getParsedMessage = (message) => {
    if (message[0] === "This field may not be blank.")
      return "This field cannot be blank.";
    else if (
      message[0] ===
      "We couldn't find an account associated with that email. Please try a different e-mail address."
    )
      return "We couldn't find an account associated with that email. Please try a different email address.";
    else return message;
  };

  const handleRecoverPassword = async () => {
    setLoading(true);
    try {
      await AuthService.resetPassword(email);
      setWarningMessage(
        "The link was successfully sent. Check your email to recover your password."
      );
      setMessage("");
    } catch (error) {
      let strMessageError = error.response.data.email;
      setMessage(strMessageError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <Logo />

        <InfoContainer data-e2e-id={"RecoverScreen-Info"}>
          {message !== "" ? (
            <ErrorMessage>
              {" "}
              <ErrorImage src={RoundedError} alt="error" />
              {getParsedMessage(message)}
            </ErrorMessage>
          ) : (
            <InfoMessage>
              {" "}
              <ErrorImage src={RoundedErrorWhite} alt="error" />
              {warningMessage}
            </InfoMessage>
          )}
        </InfoContainer>

        <Title data-e2e-id={"RecoverScreen-Title"}>Recover Password</Title>
        <TextInputV2
          data-e2e-id={"RecoverScreen-Input"}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
          placeholder="Type your Email"
        />
        <ResetPasswordButton
          bold={false}
          onClick={() => {
            handleRecoverPassword();
          }}
        >
          {" "}
          {loading ? (
            <ClipLoader color={"#FFF"} loading={loading} />
          ) : (
            "Reset"
          )}{" "}
        </ResetPasswordButton>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(225deg, #1c3f5c 0%, #2d6672 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  padding: 44px 0 66px 0;
`;

const InfoMessage = styled.span`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
`;

const ErrorMessage = styled.span`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #fe7a7a;
  text-align: center;
`;

const ErrorImage = styled.img`
  position: relative;
  top: 3px;
  height: 20px;
  width: 20px;
  margin-right: 4px;
  color: #fe7a7a;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Title = styled.span`
  font-family: "Bebas Neue";
  color: white;
  font-size: 20px;
  letter-spacing: 0.05em;
`;

const TextInputV2 = styled.input`
  width: 100%;
  padding: 10px 22px;
  border: none;
  border-radius: 5px;
  margin-top: 13px;

  ::placeholder {
    font-weight: 500;
  }
`;

const Logo = styled(LogoLogin)``;

const ResetPasswordButton = styled(Button)`
  margin-top: 20px;
  border: 1px solid #ffffff;
  width: 100%;
`;

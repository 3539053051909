import React, { useState } from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

import Button from "./Button/Button";
import { ReactComponent as DownloadIcon } from "../assets/DownloadIcon.svg";

import { downloadFileFromData } from "../utils/analysis";
import AnalyzedDataService from "../services/analyzedData";
import { parseDate } from "../utils/parses";

export default function CompleteApplicantList({
  selectedPeriod,
  selectedProgram,
  ...props
}) {
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    setLoading(true);

    const response = await AnalyzedDataService.getDownloadConcatenated(
      selectedPeriod.value
    );
    const currentDate = parseDate(new Date(), "MM_DD_YYYY");
    const fileName = `${selectedProgram.label}_${selectedPeriod.label}_ANALYZED_DATA_${currentDate}.csv`;

    setLoading(false);

    downloadFileFromData(fileName, response.data);
  };

  return (
    <Container {...props}>
      <LeftSection>
        <Title>Complete Applicant List</Title>
        <Description>
          Download the fully-concatenated file for all applicants under&nbsp;
          {selectedProgram.label} - {selectedPeriod.label}.
        </Description>
        <LastProcessInfo>Last processed 12/23/2021 5:36 PM EST</LastProcessInfo>
      </LeftSection>
      <RightSection onClick={downloadFile}>
        <Button disabled={loading}>
          {loading ? (
            <ClipLoader size={24} loading={loading} color={"#FFF"} />
          ) : (
            <>
              <DownloadIcon stroke="white" /> Download CSV
            </>
          )}
        </Button>
      </RightSection>
    </Container>
  );
}

const Container = styled.div`
  background-color: white;
  padding: 22px;
  border: 1px solid #c2c2c2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
`;

const LeftSection = styled.div``;

const RightSection = styled.div``;

const Title = styled.h1`
  font-weight: bold;
`;

const Description = styled.p`
  font-weight: 500;
  margin: 6px 0;
`;

const LastProcessInfo = styled.p`
  color: #767676;
  font-size: 14px;
  line-height: 20px;
`;

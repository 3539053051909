import styled from "styled-components";

import { ReactComponent as TrashIconComponent } from "../../assets/trash.svg";

const TextInput = ({
  testID,
  text,
  value,
  name,
  type,
  onChange,
  placeholder,
  inputColor,
  placeholderColor,
  deletable = false,
  onDelete,
  ...props
}) => {
  return (
    <Container data-e2e-id={testID} {...props}>
      <Label>{text}</Label>
      <InputContainer>
        <Input
          value={value}
          type={type}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          inputColor={inputColor}
          placeholderColor={placeholderColor}
        />
        {deletable && <TrashIcon onClick={onDelete} />}
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid ${(props) => (props.error ? "red" : "#c2c2c2")};
  border-radius: 5px;
  padding: 12px 16px 12px;
  margin-bottom: 10px;
`;

const Label = styled.p`
  font-weight: bold;
  color: #767676;
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Input = styled.input`
  color: ${(props) => (props.inputColor ? props.inputColor : "#767676")};
  border: none;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) =>
      props.placeholderColor ? props.placeholderColor : "#767676"};
    opacity: 1;
    font-weight: 500;
  }
`;

const TrashIcon = styled(TrashIconComponent)`
  cursor: pointer;
`;

export default TextInput;

import styled, { css } from "styled-components";
import SelectV2 from "../../components/SelectV2";

const svgGrabArea = (color) => {
  return (
    "data:image/svg+xml,%3csvg width='100%25' height='100%25' " +
    "xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' " +
    "height='100%25' fill='none' rx='5' ry='5' stroke='" +
    color +
    "' " +
    "stroke-width='2' stroke-dasharray='12' stroke-dashoffset='84' " +
    "stroke-linecap='square'/%3e%3c/svg%3e"
  );
};

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  margin-top: 46px;
  margin-bottom: 11.5px;
  align-items: flex-end;
  justify-content: space-between;
`;

export const TableContainer = styled.section`
  display: flex;
  flex: 1;
`;

export const DeleteModalText = styled.p`
  font-family: Lato;
  font-weight: 700;
`;

export const DeleteModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 650px;
  ${({ hasRadius }) =>
    hasRadius
      ? css`
          border-radius: 5px;
          padding: 0px 0px 10px 0px;
        `
      : css`
          padding: 20px 0;
        `}
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  color: #093156;
  font-size: 16px;
  font-weight: 400;
`;

export const BoldText = styled.div`
  font-weight: 700;
  color: #093156;
`;

export const SelectedFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export const HorizontalLine = styled.hr`
  width: 440px;
  border-top: 1px solid #093156;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 22px 0px;
`;

export const RightContainer = styled.div`
  margin: 22px 0px;
  background: #f9f9f9;
  width: 100%;
  height: ${({ height }) => height || "auto"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  ${({ color }) =>
    css`
      background-image: url("${svgGrabArea(color)}");
    `};

  h3 {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  span {
    margin: -10px 12px;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    line-height: 20px;
    align-self: center;
    color: #767676;
  }
`;

export const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 12px;
`;

export const SelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22.5px;
`;

export const Select = styled(SelectV2)`
  height: 44px;
  width: 162px;

  label {
    background-color: white;
    padding: 0px 3px;
    left: 8px;
  }

  ${({ smallSpanFont }) =>
    smallSpanFont &&
    css`
      :nth-child(3) {
        span {
          font-size: 14px;
        }
      }
    `}
`;

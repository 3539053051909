import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import TextInput from "../../components/Input/TextInput";
import Panel from "../../components/Panel";
import ImageInput from "../../components/ImageInput";
import RadioButton from "../../components/Button/RadioButton";

import SaveAndCancel from "../../components/Button/SaveAndCancel";
import ErrorModal from "../../components/ErrorModal";

import StaffService from "../../services/staff";

const testID = "NewStaffScreen";

export default function SaveStaffUser() {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [accountRep, setAccountRep] = useState(false);
  const [warningMessage, setWarningMessage] = useState(["", ""]);
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    number: "",
  });
  const { email, firstName, lastName, number } = user;
  const [overrideMessage, setOverrideMessage] = useState(false);
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(["", ""]);
  const [submitted, setSubmitted] = useState(false);

  const toggleWarning = (message) => {
    setWarningMessage(message);
  };

  const toggleError = (message) => {
    setErrorMessage(message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));
  };

  const fetchData = useCallback(async () => {
    const { data } = await StaffService.get(params.id);
    setUser({
      firstName: data.first_name,
      lastName: data.last_name,
      number: data.phone_number,
      email: data.email,
      profilePicture: data.profile_picture,
    });
    setAccountRep(data.is_account_representative);
  }, [params.id]);

  useEffect(() => {
    if (params.id != null) {
      fetchData();
    }
  }, [fetchData, params.id]);

  const onChangeProfilePicture = (file) => {
    if (file?.type !== "image/jpeg") {
      toggleWarning([
        "The file should be in JPEG format",
        "Error: Wrong image type",
      ]);
    } else {
      setUser({
        ...user,
        profilePicture: URL.createObjectURL(file),
      });
      setImage(file);
    }
  };

  const handleAccountRepresentative = (e) => {
    setAccountRep(e.target.value);
  };

  const validateField = (fieldName) => {
    const validation = {
      email: () => {
        return !(
          email &&
          email
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        );
      },
      firstName: () => {
        return !firstName ? true : false;
      },
      lastName: () => {
        return !lastName ? true : false;
      },
      number: () => {
        return !number ? true : false;
      },
    };
    return validation[fieldName]();
  };

  const validateFields = (fields) => {
    for (let field of fields) {
      if (validateField(field)) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!validateFields(["firstName", "lastName", "number", "email"])) return;

    setLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("first_name", firstName);
    bodyFormData.append("last_name", lastName);
    bodyFormData.append("phone_number", number);
    bodyFormData.append("is_account_representative", accountRep);

    if (image) {
      bodyFormData.append("image", image);
    }

    try {
      const response = await StaffService.save(bodyFormData, params.id);
      if (response.status === 201 || response.status === 200) {
        navigate("/home/admin/users");
      }
    } catch (e) {
      if (e?.response?.data?.email) {
        setOverrideMessage(true);
        setErrorMessage([e.response.status, "Email already in use."]);
      } else {
        setErrorMessage([e.response.status, e.response.statusText]);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Panel
        testID={testID}
        title="ADMIN USERS"
        edit={false}
        disable={false}
        color="#eee"
      >
        <Container>
          <form onSubmit={handleSubmit}>
            <TextInput
              text="Admin User/ Email Address"
              type="text"
              name="email"
              value={email}
              onChange={handleChange}
              error={validateField("email") && submitted}
              inputColor={"#332525"}
              placeholder="Enter an email address"
            />
            <TextInput
              text="First Name"
              type="text"
              name="firstName"
              value={firstName}
              onChange={handleChange}
              error={validateField("firstName") && submitted}
              inputColor={"#332525"}
              placeholder="Enter a name"
            />
            <Divider />
            <TextInput
              text="Last Name"
              type="text"
              name="lastName"
              value={lastName}
              onChange={handleChange}
              error={validateField("lastName") && submitted}
              inputColor={"#332525"}
              placeholder="Enter a name"
            />
            <TextInput
              text="Phone Number"
              type="tel"
              name="number"
              value={number}
              onChange={handleChange}
              error={validateField("number") && submitted}
              inputColor={"#332525"}
              placeholder="(555) 202-5555"
            />
            <RadioButton
              testID={"NewStaffScreen-Checkbox"}
              value={accountRep}
              name="accountRep"
              light={true}
              color="#093156"
              buttonLabel="Account Representative?"
              onChange={handleAccountRepresentative}
            />
            <ImageInput
              testID={testID}
              buttonText={"Upload Picture"}
              headerText={"Profile Picture"}
              descriptionText={
                "Image files should be in JPEG format and 400x400 pixels."
              }
              image={user.profilePicture}
              onChange={onChangeProfilePicture}
            />
            <SaveAndCancel
              testID={testID}
              loading={loading}
              onClick={() => navigate("/home/admin/users")}
            />
          </form>
        </Container>
      </Panel>
      <ErrorModal
        color="#eee"
        buttonLabel={"Back"}
        error={false}
        callback={() => {
          toggleWarning(["", ""]);
        }}
        isOpen={warningMessage[0]}
        errorMessage={warningMessage}
      />
      <ErrorModal
        overrideMessage={overrideMessage}
        color="#eee"
        buttonLabel={"Back"}
        callback={() => {
          toggleError(["", ""]);
          setOverrideMessage(false);
        }}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
        error={true}
      />
    </>
  );
}

const Divider = styled.hr`
  border-top: 1px solid #bbb;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  margin: 12px 0px;
`;

const Container = styled.div``;

import api from "./api";
import { UserStatusEnum } from "../utils/users";

export default class UserService {
  static async get(params) {
    return api.get("/users/", {
      params,
    });
  }

  static async getUser(id) {
    return api.get(`/users/${id}/`);
  }

  static async save(userInfo) {
    if (userInfo.id != null) {
      let response = await api.put(`/users/${userInfo.id}/`, {
        email: userInfo.email,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        is_active: userInfo.isActive,
        phone_number: userInfo.phone,
        institution_id: userInfo.institutionId,
        programs_id: userInfo.programsAccess,
        is_user_admin: userInfo.userAccess,
      });
      return response;
    } else {
      let response = await api.post("/users/", {
        email: userInfo.email,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        phone_number: userInfo.phone,
        institution_id: userInfo.institutionId,
        programs_id: userInfo.programsAccess,
        is_user_admin: userInfo.userAccess,
      });
      return response;
    }
  }

  static async setSelectedPeriod(id, periodId) {
    return api.patch(`/users/${id}/`, {
      period_selected_id: periodId,
    });
  }

  static async enable(id) {
    return api.patch(`/users/${id}/`, {
      is_active: UserStatusEnum.ACTIVE,
    });
  }

  static async getPrograms(params = {}) {
    return api.get(`users/programs/`, { ...params });
  }

  static async disable(id) {
    return api.delete(`/users/${id}/`);
  }

  static async resendActivationEmail(id) {
    return api.put(`/users/${id}/resend-activation-email/`);
  }
}

import api from "./api";

export default class AnalyzedDataService {
  static async getAll(params) {
    const response = await api.get("/data/analyzed/", { params });
    return response;
  }

  static async delete(id) {
    const response = await api.delete(`/data/analyzed/${id}`);
    return response;
  }

  static async get(id) {
    const response = await api.get(`/data/analyzed/${id}`);
    return response;
  }

  static async getDownload(id) {
    const response = await api.get(`/data/analyzed/${id}/download`);
    return response;
  }

  static async getDownloadConcatenated(id) {
    const response = await api.get(
      `/data/analyzed/concatenate/?period_id=${id}`
    );
    return response;
  }

  static async create(dataInfo) {
    const response = await api({
      method: "post",
      url: `/data/analyzed/`,
      data: dataInfo,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  }
}

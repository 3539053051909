import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styled from "styled-components";

function TabItem({ testID, ...props }) {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({
    path: resolved.pathname,
    end: false,
  });

  return (
    <Container
      {...props}
      data-e2e-id={`${testID}-TabItem`}
      selected={Boolean(match)}
    />
  );
}

const Container = styled(Link)`
  display: inline-block;
  margin-right: 77px;
  font-weight: ${(props) => (props.selected ? "700" : "500")};
  color: ${(props) => (props.selected ? "#093156" : "#332525")};
  border: none;
  padding-bottom: 8px;
  text-decoration: none;
  border-bottom: ${(props) => (props.selected ? "2px solid #093156" : "none")};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export default TabItem;

import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";

import AuthService from "../services/authentication";

export default function ResetPasswordButton({ email, onReset }) {
  const [loading, setLoading] = useState(false);
  const resetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    await AuthService.resetPassword(email);
    setLoading(false);
    onReset && onReset("Password reset link has been sent.");
  };

  return (
    <>
      {email && (
        <Reset onClick={resetPassword} loading={loading}>
          {loading ? <ClipLoader /> : "Reset Password"}
        </Reset>
      )}
    </>
  );
}

const Reset = styled.span`
  font-family: "Lato", sans-serif;
  font-weight: 500;
  line-height: 19px;
  text-decoration-line: underline;
  color: #093156;
  margin-top: 44px;
  cursor: pointer;
  ${(props) => {
    return props.loading ? `padding-left: 20px;` : "";
  }}
`;

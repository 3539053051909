import React, { useState } from "react";
import styled, { css } from "styled-components";

import Modal from "./Modal";
import TextInput from "./Input/TextInput";

function DeleteModal({
  title = "Delete Data",
  itemToBeDeletedName,
  onSubmit,
  closeModal,
}) {
  const [confirmationText, setConfirmationText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      flex={true}
      label={title}
      isLoading={isLoading}
      deleteModal={true}
      disabled={confirmationText !== "DELETE PERMANENTLY"}
      onClick={() => {
        closeModal();
        setConfirmationText("");
      }}
      onSubmit={async () => {
        setIsLoading(true);
        await onSubmit();
        setIsLoading(false);
      }}
      noBorder
    >
      <DeleteModalTextContainer>
        <DeleteModalText>
          Are you sure you want to delete data from {itemToBeDeletedName}?
        </DeleteModalText>
        <DeleteModalText>
          Type “DELETE PERMANENTLY” in order to delete your data. This cannot be
          undone.
        </DeleteModalText>
        <TextInputV2
          type="text"
          name="confirmationText"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          placeholder="Type Here"
          inputColor={"#332525"}
        />
      </DeleteModalTextContainer>
    </Modal>
  );
}

const DeleteModalText = styled.p`
  font-family: Lato;
  font-weight: 700;
`;

const DeleteModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 650px;
  ${({ hasRadius }) =>
    hasRadius
      ? css`
          border-radius: 5px;
          padding: 0px 0px 10px 0px;
        `
      : css`
          padding: 20px 0;
        `}
`;

const TextInputV2 = styled(TextInput)`
  height: 44px;
  padding: 0px;
  width: 100%;
  padding: 10px 22px;
  margin-top: 19px;
  border: 1px solid black;
  p {
    display: none;
  }
`;

export default DeleteModal;

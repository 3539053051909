// Reference: https://react-dnd.github.io/react-dnd/examples/sortable/simple
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from "styled-components";

import TextInput from "../Input/TextInput";
import DragIcon from "../../assets/dragItem.svg";

const DraggableListItem = ({
  testID,
  item,
  placeholderInput,
  index,
  moveItem,
  inputNamePrefix,
  onChangeInput = () => {},
  onDelete,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { ...item, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Container ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <img src={DragIcon} alt="Drag" />
      <TextInput
        testID={`${testID}-${index}-Name`}
        type="text"
        name={`${inputNamePrefix}${inputNamePrefix.length && "-"}name`}
        value={item.name}
        onChange={(e) => onChangeInput(index, e)}
        style={{ flexGrow: 1 }}
        inputColor="#332525"
        placeholder={placeholderInput}
        onDelete={onDelete}
        deletable
      />
    </Container>
  );
};

const Container = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  gap: 12px;

  p {
    margin-right: 0;
  }
`;

export default DraggableListItem;

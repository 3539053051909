import { useEffect } from "react";

export default function useClickOutside(ref, fn, guards = []) {
  const verify = (guards) => {
    for (let i = 0; i < guards.length; i++) {
      if (!guards[i]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        verify(guards)
      ) {
        fn && fn();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [fn]);
}

import React from "react";
import { ClipLoader } from "react-spinners";
import styled, { css } from "styled-components";

export default function Card({
  number,
  name,
  label = "",
  gray,
  view,
  titleColor,
  wrap,
  animated,
  noPointer,
  isLoading = false,
  onClick = () => {},
  staticColor = "",
  barMax = "100",
  ...props
}) {
  let color = "#930BB5";

  if (number > 75) {
    color = "#0C7E55";
  } else if (number >= 50) {
    color = "#E88239";
  }

  return (
    <Container
      noPointer={noPointer}
      onClick={() => {
        onClick();
      }}
      {...props}
      className="card"
      animated={animated}
    >
      <Title wrap={wrap} titleColor={titleColor}>
        {name}
      </Title>
      {!isLoading && (
        <>
          <NumberContainer>
            <Number>{number}</Number>
            <Label gray={gray}>{label}</Label>
          </NumberContainer>
          <ProgressBar
            value={number}
            max={barMax}
            color={staticColor || color}
          />
          {view && <View> {view}</View>}
        </>
      )}
      {isLoading && (
        <LoaderContainer>
          <ClipLoader />
        </LoaderContainer>
      )}
    </Container>
  );
}

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
`;

const Container = styled.div`
  cursor: pointer;
  ${(props) => props.noPointer && `cursor: inherit;`}
  display: flex;
  flex-direction: column;
  outline: 1px solid #c2c2c2;
  background-color: white;
  border-radius: 5px;
  width: 204px;
  height: fit-content;
  padding: 17px 22px 22px;
  @media screen and (max-width: 1310px) {
    padding: 17px 15px 22px !important;
  }
  ${(props) =>
    props.animated &&
    css`
      :hover {
        outline: 2px solid #093156;
        box-sizing: border-box;
        box-shadow: 10px 10px 20px #dedede;
        border-radius: 5px;
      }
    `}
`;

const Number = styled.span`
  font-weight: 800;
  font-size: 40px;
  color: #093156;
  line-height: 48px;
`;

const Title = styled.h1`
  font-family: "Bebas Neue", sans-serif;
  font-size: 20px;
  max-width: 200px;

  display: flex;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(props) => props.titleColor};
`;

const View = styled.span`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #332525;
  padding: 12px 0 0px;
`;

const ProgressBar = styled.progress`
  color: #0c7e55;
  width: 100%;
  height: 9px;
  display: flex;
  border-radius: 20px;
  margin-top: 5px;

  ::-webkit-progress-bar {
    border-radius: 20px;
    background-color: #c2c2c2;
  }

  ::-moz-progress-bar {
    background: ${(props) => props.color};
    border-radius: 20px;
  }

  ::-webkit-progress-value {
    background: ${(props) => props.color};
    border-radius: 20px;
  }
`;

const NumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 3px;
`;

const Label = styled.span`
  margin-bottom: 7px;
  margin-left: 5px;
  line-height: 1rem;
  color: ${(props) => props.gray && "#767676"};
`;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Header from "../../components/Header";
import BackComponent from "../../components/Back";
import BreakdownCard from "../../components/BreakdownCard";
import TierComponent from "../../components/TierComponent";
import { ReactComponent as Line } from "../../assets/Line2.svg";

import { Traits, Copies } from "../../services/data";
import StudentService from "../../services/student";
import useQuery from "../../hooks/useQuery";
import { institutionSelector } from "../../store/reducers/institutionReducer";

export default function ApplicantBreakdown() {
  const { id } = useParams();
  const query = useQuery();
  const isNewTab = query.get("newTab");

  const { selectedProgram } = useSelector(institutionSelector);

  const [insightsData, setInsightsData] = useState({
    cas: { cas_id: "" },
  });
  const [predictionsData, setPredictionsData] = useState({});
  const [competenciesData, setCompetenciesData] = useState({});
  const [selectedTrait, setSelectedTrait] = useState("Conscientiousness");
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [sidebarTop, setSidebarTop] = useState(undefined);
  const [sticky, setSticky] = useState(false);
  const [elemWidth, setElemWidth] = useState(0);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const [showMetrics, setShowMetrics] = useState(true);

  useEffect(() => {
    const div = document.getElementById("root-container");
    div.scrollTo("0", "0");
  }, []);

  useEffect(() => {
    const chatEl = document.querySelector(".sidebar").getBoundingClientRect();
    setSidebarTop(chatEl.top);
  }, []);

  useEffect(() => {
    setElemWidth(document.querySelector("#wrap").getBoundingClientRect().width);
    setSidebarWidth(
      window.innerWidth -
        document.querySelector("#wrap").getBoundingClientRect().width -
        198 - //sum of the paddings and margins in px
        (document.querySelector("#root-container").offsetWidth - //scrollbar width
          document.querySelector("#root-container").clientWidth)
    );
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const updateSize = () => {
    setElemWidth(document.querySelector("#wrap").getBoundingClientRect().width);
    setSidebarWidth(
      window.innerWidth -
        document.querySelector("#wrap").getBoundingClientRect().width -
        198 - //sum of the paddings and margins in px
        (document.querySelector("#root-container").offsetWidth - //scrollbar width
          document.querySelector("#root-container").clientWidth)
    );
    return;
  };

  useEffect(() => {
    if (!sidebarTop) {
      return;
    }
    document
      .querySelector("#root-container")
      .addEventListener("scroll", isSticky);

    return () => {
      document
        .querySelector("#root-container")
        .removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);

  const isSticky = () => {
    const scrollTop = document.querySelector("#root-container").scrollTop;
    if (scrollTop >= sidebarTop) {
      if (window.innerHeight >= 700) setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const studentsData = await StudentService.get(id);
      setStudentData(studentsData?.data);

      const insightsResponse = studentsData.data?.insights[0];
      const predictionsResponse = studentsData.data?.predictions[0];
      const competenciesResponse = studentsData.data?.competencies[0];

      setInsightsData(insightsResponse ? insightsResponse : false);
      setPredictionsData(predictionsResponse ? predictionsResponse : false);
      setCompetenciesData(competenciesResponse ? competenciesResponse : false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const getTraits = () => {
    if (loading || selectedTrait === "Writing Level" || !showMetrics) {
      return <></>;
    }

    return (
      <>
        {Traits[selectedTrait].map((traitName) => {
          return (
            <TraitWrapper>
              <p className="traitTitle"> {traitName.name}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <progress
                  value={insightsData ? insightsData[traitName.value] : 0}
                  className="higher"
                  max="100"
                />{" "}
                <span className="higherNumber">
                  {" "}
                  {insightsData ? insightsData[traitName.value] : 0}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <progress
                  value={
                    insightsData ? insightsData[traitName.value + "_median"] : 0
                  }
                  className="lower"
                  max="100"
                />
                <span className="lowerNumber">
                  {" "}
                  {insightsData ? insightsData[traitName.value + "_median"] : 0}
                </span>{" "}
              </div>
            </TraitWrapper>
          );
        })}
      </>
    );
  };

  const getNumber = (value) => {
    if (value === undefined || value === null || isNaN(value)) {
      return "--";
    }
    return value;
  };

  return (
    <div>
      {" "}
      <Header style={{ marginBottom: "0px" }} title="Applicant Breakdown" />
      <BackComponentV2
        label={"Back to Applicant Analysis"}
        to={-1}
        newTab={isNewTab}
      />
      <Container>
        <Wrap id="wrap">
          <Applicant>
            <CasID>
              {" "}
              <Title> cas id: {studentData?.cas_id || ""}</Title>{" "}
            </CasID>
            <ApplicantInfo>
              <p>Program:</p>
              <span> {studentData?.period?.program?.name || ""} </span>
            </ApplicantInfo>
            <ApplicantInfo>
              <p>Period:</p>
              <span> {studentData?.period?.name || ""} </span>
            </ApplicantInfo>
          </Applicant>
          {selectedProgram.is_predictions_enabled && (
            <Predictions>
              <Title>PREDICTIONS</Title>
              <TierComponent
                isLoading={loading}
                predictionsData={predictionsData}
              />
            </Predictions>
          )}
          <InsightsContainer>
            <Title> INSIGHTS </Title>
            <CardsWrap>
              {" "}
              <BreakdownCard
                isLoading={loading}
                name="CONSCIENTIOUSNESS"
                median={insightsData.conscientiousness_median}
                number={getNumber(Math.round(insightsData?.conscientiousness))}
                titleColor="#093156"
                view="Detailed View"
                selected={selectedTrait === "Conscientiousness"}
                onClick={() => {
                  setSelectedTrait("Conscientiousness");
                  setShowMetrics(true);
                }}
                animated
              />
              <BreakdownCard
                isLoading={loading}
                name="POSITIVE ATTITUDE"
                titleColor="#093156"
                median={insightsData.positive_attitude_median}
                selected={selectedTrait === "Positive Attitude"}
                number={getNumber(Math.round(insightsData?.positive_attitude))}
                view="Detailed View"
                onClick={() => {
                  setSelectedTrait("Positive Attitude");
                  setShowMetrics(true);
                }}
                animated
              />
              <BreakdownCard
                isLoading={loading}
                name="NEGATIVE ATTITUDE"
                titleColor="#093156"
                median={insightsData.negative_attitude_median}
                selected={selectedTrait === "Negative Attitude"}
                number={getNumber(Math.round(insightsData?.negative_attitude))}
                view="Detailed View"
                label="Lower is better"
                onClick={() => {
                  setSelectedTrait("Negative Attitude");
                  setShowMetrics(true);
                }}
                animated
              />
              <BreakdownCard
                isLoading={loading}
                name="WRITING LEVEL"
                titleColor="#093156"
                selected={selectedTrait === "Writing Level"}
                median={insightsData.writing_attitude_median}
                number={getNumber(Math.round(insightsData?.writing_attitude))}
                onClick={() => {
                  setSelectedTrait("Writing Level");
                  setShowMetrics(false);
                }}
                view="Detailed View"
                animated
              />
            </CardsWrap>
            {insightsData?.proactiveness > 0 ||
            insightsData?.empathy > 0 ||
            insightsData?.enthusiasm > 0 ? (
              <CardsWrap>
                {insightsData?.proactiveness > 0 && (
                  <BreakdownCard
                    isLoading={loading}
                    name="PROACTIVENESS"
                    titleColor="#093156"
                    selected={selectedTrait === "Proactiveness"}
                    median={insightsData.proactiveness_median}
                    number={getNumber(Math.round(insightsData?.proactiveness))}
                    onClick={() => {
                      setSelectedTrait("Proactiveness");
                      setShowMetrics(false);
                    }}
                    view="Detailed View"
                    animated
                  />
                )}
                {insightsData?.empathy > 0 && (
                  <BreakdownCard
                    isLoading={loading}
                    name="EMPATHY"
                    titleColor="#093156"
                    selected={selectedTrait === "Empathy"}
                    median={insightsData.empathy_median}
                    number={getNumber(Math.round(insightsData?.empathy))}
                    onClick={() => {
                      setSelectedTrait("Empathy");
                      setShowMetrics(false);
                    }}
                    view="Detailed View"
                    animated
                  />
                )}
                {insightsData?.enthusiasm > 0 && (
                  <BreakdownCard
                    isLoading={loading}
                    name="ENTHUSIASM"
                    titleColor="#093156"
                    selected={selectedTrait === "Enthusiasm"}
                    median={insightsData.enthusiasm_median}
                    number={getNumber(Math.round(insightsData?.enthusiasm))}
                    onClick={() => {
                      setSelectedTrait("Enthusiasm");
                      setShowMetrics(false);
                    }}
                    view="Detailed View"
                    animated
                  />
                )}
              </CardsWrap>
            ) : null}
          </InsightsContainer>
          <CompetenciesContainer>
            <Title> Skills </Title>
            <CardsWrap>
              {" "}
              <BreakdownCard
                isLoading={loading}
                name="Leadership"
                number={
                  competenciesData
                    ? getNumber(
                        Math.round(competenciesData.leading_and_deciding)
                      )
                    : "--"
                }
                median={competenciesData.leading_and_deciding_median}
                titleColor="#093156"
                view="Detailed View"
                animated
                selected={selectedTrait === "Leadership"}
                onClick={() => {
                  setSelectedTrait("Leadership");
                  setShowMetrics(false);
                }}
              />
              <BreakdownCard
                isLoading={loading}
                name="Collaboration"
                selected={selectedTrait === "Collaboration"}
                median={competenciesData.supporting_and_cooperating_median}
                number={
                  competenciesData
                    ? getNumber(
                        Math.round(competenciesData.supporting_and_cooperating)
                      )
                    : "--"
                }
                titleColor="#093156"
                view="Detailed View"
                animated
                onClick={() => {
                  setSelectedTrait("Collaboration");
                  setShowMetrics(false);
                }}
              />
              <BreakdownCard
                isLoading={loading}
                name="Communication"
                median={competenciesData.interacting_and_presenting_median}
                selected={selectedTrait === "Communication"}
                number={
                  competenciesData
                    ? getNumber(
                        Math.round(competenciesData.interacting_and_presenting)
                      )
                    : "--"
                }
                view="Detailed View"
                titleColor="#093156"
                animated
                onClick={() => {
                  setSelectedTrait("Communication");
                  setShowMetrics(false);
                }}
              />
              <BreakdownCard
                isLoading={loading}
                name="Analytical Thinking"
                median={competenciesData.analyzing_and_interpreting_median}
                selected={selectedTrait === "Analytical Thinking"}
                number={
                  competenciesData
                    ? getNumber(
                        Math.round(competenciesData.analyzing_and_interpreting)
                      )
                    : "--"
                }
                view="Detailed View"
                titleColor="#093156"
                animated
                onClick={() => {
                  setSelectedTrait("Analytical Thinking");
                  setShowMetrics(false);
                }}
              />
            </CardsWrap>
            <CardsWrap>
              {" "}
              <BreakdownCard
                isLoading={loading}
                name="Conceptual Thinking"
                median={competenciesData.creating_and_conceptualizing_median}
                number={
                  competenciesData
                    ? getNumber(
                        Math.round(
                          competenciesData.creating_and_conceptualizing
                        )
                      )
                    : "--"
                }
                view="Detailed View"
                titleColor="#093156"
                animated
                selected={selectedTrait === "Conceptual Thinking"}
                onClick={() => {
                  setSelectedTrait("Conceptual Thinking");
                  setShowMetrics(false);
                }}
              />
              <BreakdownCard
                isLoading={loading}
                name="Goal Execution"
                selected={selectedTrait === "Goal Execution"}
                median={competenciesData.organizing_and_executing_median}
                titleColor="#093156"
                number={
                  competenciesData
                    ? getNumber(
                        Math.round(competenciesData.organizing_and_executing)
                      )
                    : "--"
                }
                view="Detailed View"
                animated
                onClick={() => {
                  setSelectedTrait("Goal Execution");
                  setShowMetrics(false);
                }}
              />
              <BreakdownCard
                isLoading={loading}
                name="Adaptive"
                titleColor="#093156"
                selected={selectedTrait === "Adaptive"}
                median={competenciesData.adapting_and_coping_median}
                number={
                  competenciesData
                    ? getNumber(
                        Math.round(competenciesData.adapting_and_coping)
                      )
                    : "--"
                }
                view="Detailed View"
                animated
                onClick={() => {
                  setSelectedTrait("Adaptive");
                  setShowMetrics(false);
                }}
              />
              <BreakdownCard
                isLoading={loading}
                name="Resourcefulness"
                selected={selectedTrait === "Resourcefulness"}
                median={competenciesData.enterprising_and_performing_median}
                titleColor="#093156"
                number={
                  competenciesData
                    ? getNumber(
                        Math.round(competenciesData.enterprising_and_performing)
                      )
                    : "--"
                }
                view="Detailed View"
                animated
                onClick={() => {
                  setSelectedTrait("Resourcefulness");
                  setShowMetrics(false);
                }}
              />
            </CardsWrap>
          </CompetenciesContainer>
        </Wrap>
        <PlaceholderDiv sticky={sticky} />
        <Conscientiousness
          elemWidth={elemWidth}
          sidebarWidth={sidebarWidth}
          sticky={sticky}
          className="sidebar"
        >
          {" "}
          <Title> {selectedTrait} </Title> <p> {Copies[selectedTrait]}</p>
          {showMetrics && (
            <>
              <WrapSubtitles>
                <div className="applicant" />
                <span> Applicant </span>

                <div className="benchmark" />
                <span> Class Benchmark </span>
              </WrapSubtitles>
              <Line />
              <TraitContainer>{getTraits()}</TraitContainer>
            </>
          )}
        </Conscientiousness>
      </Container>
    </div>
  );
}

const TraitWrapper = styled.div`
  padding-right: 10px;
  progress::-webkit-progress-bar {
    background-color: white;
    width: 100%;
  }
  progress {
    background-color: white;
  }
`;

const BackComponentV2 = styled(BackComponent)`
  padding-top: 44px;
`;

const Container = styled.section`
  background: #f9f9f9;
  padding: 22px 44px;
  display: flex;
`;

const CardsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 22px;
  gap: 22px;
`;

const WrapSubtitles = styled.div`
  margin-top: 64px;
  margin-bottom: 43px;
  @media screen and (max-height: 800px) {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  display: flex;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const Predictions = styled.div`
  margin-top: 44px;
`;

const Applicant = styled.div`
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  padding-right: 10%;
`;

const CasID = styled.span`
  padding: 12px 0 12px 26px;
`;

const Title = styled.label`
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #332525;
`;

const ApplicantInfo = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: "Lato";
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #767676;
    white-space: nowrap;
  }

  span {
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #332525;
    white-space: nowrap;
    margin-left: 12px;
  }
`;

const InsightsContainer = styled.div`
  margin-top: 66px;
`;

const CompetenciesContainer = styled.div`
  margin-top: 66px;
`;

const PlaceholderDiv = styled.div`
  width: 30%;
  margin-left: 22px;
  ${(props) => {
    if (!props.sticky) {
      return "display: none;";
    }
  }}
`;

const Conscientiousness = styled.section`
  width: 30%;
  background: #ffffff;
  margin-left: 22px;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 22px 23px 51px 21px;
  height: fit-content;
  ${(props) => {
    if (props.sticky) {
      return `
      position: fixed;
      width: ${props.sidebarWidth}px;
      top: 10px;
      left: calc(${props.elemWidth}px + 132px);
      z-index: 123123123231;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      `;
    }
  }}

  p {
    margin-top: 23px;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #332525;
  }

  span {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #332525;
    margin-left: 12px;
  }

  .applicant {
    width: 22px;
    height: 22px;
    background: #093156;
    border-radius: 5px;
  }

  .benchmark {
    width: 22px;
    height: 22px;
    background: #c2c2c2;
    border-radius: 5px;
    margin-left: 43px;
    @media screen and (max-width: 1400px) {
      margin-left: 12px;
    }
  }
`;

const TraitContainer = styled.div`
  margin-top: 44px;
  max-height: 400px;
  @media screen and (max-height: 800px) {
    margin-top: 16px;
    max-height: 300px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 30px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #111;
  }
  overflow: auto;
  .traitTitle {
    font-family: "Lato";
    font-style: bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #332525;
  }

  .higherNumber {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #093156;
    align-self: flex-end;
    margin-top: 5px;
  }

  .lowerNumber {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #332525;
    align-self: flex-end;
    margin-top: 5px;
  }

  .lower {
    width: 100%;

    height: 13px;
    display: flex;
    border-radius: 20px;
    margin-top: 5px;
    background: #fff;
    border-color: #fff;

    ::-webkit-progress-bar {
      border-radius: 20px;
      background: #fff;
    }

    ::-moz-progress-bar {
      background: #c2c2c2;
      border-radius: 20px;
    }

    ::-webkit-progress-value {
      background: #c2c2c2;
      border-radius: 20px;
    }
  }

  .higher {
    height: 13px;
    width: 100%;
    display: flex;
    border-radius: 20px;
    margin-top: 12px;
    background: #fff;
    border-color: #fff;

    ::-webkit-progress-bar {
      border-radius: 20px;
    }

    ::-moz-progress-bar {
      background: #093156;
      border-radius: 20px;
    }

    ::-webkit-progress-value {
      background: #093156;
      border-radius: 20px;
    }
  }
`;

import api from "./api";

export default class FileStorageService {
  static async getAll(params) {
    return api.get("/storage/", { params });
  }

  static async create(data) {
    return api.post("/storage/", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static async delete(id) {
    return api.delete(`/storage/${id}/`);
  }

  static async getDownload(id) {
    return api.get(`/storage/${id}/download_file/`);
  }
}

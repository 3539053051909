import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";

import Modal from "../../components/Modal";
import Header from "../../components/Header";
import Paragraph from "../../components/Paragraph";
import TabSelected from "../../components/Tab/TabSelected";
import TabContainer, { TabItem } from "../../components/Tab";
import ProgramFilter from "../../components/Sort/Filter";

import { institutionSelector } from "../../store/reducers/institutionReducer";

function Analysis() {
  const { selectedProgram } = useSelector(institutionSelector);
  const location = useLocation();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(
      location.pathname.includes("analysis/predictions") &&
        !selectedProgram.is_predictions_enabled
    );
  }, [location, selectedProgram]);

  return (
    <>
      <Header
        testID={"Analysis"}
        style={{ marginBottom: "0px" }}
        title="Applicant Analysis"
      />

      <ProgramFilter />

      <TabContainer style={{ paddingTop: "44px", background: "white" }}>
        <TabItem to="/home/analysis/insights">Insights</TabItem>
        <TabItem to="/home/analysis/skills">Skills</TabItem>
        <TabItem
          to="/home/analysis/predictions"
          disabled={!selectedProgram.is_predictions_enabled}
        >
          Predictions
        </TabItem>
      </TabContainer>

      {/* Renders the tab selected from the TabContainer */}
      <TabSelected />

      {openModal && (
        <Modal
          flex={true}
          title="Attention"
          cancelLabel={"OK"}
          onClick={() => setOpenModal(false)}
          color={"#eee"}
          noBorder
        >
          <Paragraph>
            Predication and Diversity analyses are premium services. Please{" "}
            <UnderlinedText to={"/home/support"}>
              contact your account representative
            </UnderlinedText>{" "}
            for more information.
          </Paragraph>
        </Modal>
      )}
    </>
  );
}

const UnderlinedText = styled(Link)`
  font-weight: 400;
  color: #093156;
  border-bottom: 1px solid #093156;
  text-decoration: none;
`;

export default Analysis;

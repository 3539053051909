import React, { useRef } from "react";
import styled from "styled-components";

import { ReactComponent as ArrowDown } from "../assets/arrow-down.svg";

const SCROLL_STEP = 200;
export default function Carousel({ children }) {
  const scrollViewRef = useRef();

  function scroll(value) {
    let currentValue = scrollViewRef.current.scrollLeft;
    scrollViewRef.current.scrollLeft = currentValue + value;
  }

  return (
    <Container>
      <Button onClick={() => scroll(-SCROLL_STEP)}>
        <ArrowLeft />
      </Button>
      <Content ref={scrollViewRef}>{children}</Content>
      <Button onClick={() => scroll(SCROLL_STEP)}>
        <ArrowRight />
      </Button>
    </Container>
  );
}

const Button = styled.button`
  background: #eeeeee;
  height: 44px;
  width: 44px;
  border-radius: 22px;
  border: none;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  ${Button}:first-child {
    margin-right: 5px;
  }

  ${Button}:last-child {
    margin-left: 5px;
  }
`;

const Content = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: row;
  width: 100%;
  max-width: 100%;

  & > * {
    margin-right: 12px;
    min-width: calc(25% - 12px);
  }

  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ArrowLeft = styled(ArrowDown)`
  transform: rotate(90deg);
`;

const ArrowRight = styled(ArrowDown)`
  transform: rotate(-90deg);
`;

import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import update from "immutability-helper";
import styled from "styled-components";

import AttachUsersToProgramsModal from "./AttachUsersToProgramsModal";
import RadioButton from "./Button/RadioButton";
import AddIcon from "../assets/AddIcon.svg";
import TextInput from "./Input/TextInput";
import Button from "./Button/Button";

import InstitutionService from "../services/institution";
import {
  setProgram,
  institutionSelector,
  setPeriod,
} from "../store/reducers/institutionReducer";
import DraggableList from "./Draggable/List";

const testID = "NewInstitutionScreen";

function AddProgram({
  institutionId,
  initialPrograms = [{ name: "", id: "" }],
  setError,
  setErrorMessage,
  setOverrideMessage,
}) {
  const dispatch = useDispatch();
  const { selectedProgram, selectedPeriod } = useSelector(institutionSelector);

  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openUsersModal, setOpenUsersModal] = useState(false);

  const newPrograms = programs.filter((program) => !program.id);

  const handleAddChange = (i, e) => {
    const updatedPrograms = [...programs];
    updatedPrograms[i][e.target.name] = e.target.value;

    setPrograms(updatedPrograms);
  };

  const handlePeriodAddChange = (programIndex, periodIndex, e) => {
    const updatedPrograms = [...programs];
    updatedPrograms[programIndex].periods[periodIndex].name = e.target.value;

    setPrograms(updatedPrograms);
  };

  const handleMovePeriodItem = useCallback(
    (programIndex, dragIndex, hoverIndex) => {
      setPrograms((prevPrograms) => {
        const updatedPrograms = [...prevPrograms];
        const program = { ...updatedPrograms[programIndex] };

        const updatedPeriods = update(program.periods, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, program.periods[dragIndex]],
          ],
        });
        program.periods = updatedPeriods;

        updatedPrograms[programIndex] = program;

        return updatedPrograms;
      });
    },
    []
  );

  const handleAddProgram = () => {
    setPrograms((prevPrograms) => [
      ...prevPrograms,
      { name: "", is_predictions_enabled: true, is_active: true, periods: [] },
    ]);
  };

  const handleResetPrograms = () => {
    setPrograms(initialPrograms);
  };

  const handleSavePrograms = async () => {
    if (!validatePrograms()) return;

    setLoading(true);

    if (programs.some((program) => !program.id)) {
      setOpenUsersModal(true);
    } else {
      await savePrograms();
    }

    setLoading(false);
  };

  const savePrograms = async (usersByPrograms = []) => {
    const updatedNewPrograms = newPrograms.map((program, index) => ({
      ...program,
      users: [...usersByPrograms[index]],
    }));

    try {
      const response = await InstitutionService.savePrograms(
        programs.filter((program) => !!program.id).concat(updatedNewPrograms),
        institutionId
      );

      if (response.status !== 200) throw response;

      const updatedSelectedProgram = programs.find(
        (program) => program.id === selectedProgram.value
      );

      if (updatedSelectedProgram) {
        const updatedSelectedPeriod = updatedSelectedProgram.periods.find(
          (period) => period.id === selectedPeriod.value
        );

        dispatch(
          setProgram({
            value: updatedSelectedProgram.id,
            label: updatedSelectedProgram.name,
            is_predictions_enabled:
              updatedSelectedProgram.is_predictions_enabled,
          })
        );

        if (updatedSelectedPeriod) {
          dispatch(
            setPeriod({
              value: updatedSelectedPeriod.id,
              label: updatedSelectedPeriod.name,
            })
          );
        }
      }

      setErrorMessage(["Your changes have been saved.", "success"]);
      setOverrideMessage(true);
      setError(false);
    } catch (e) {
      setErrorMessage([e.response.status, e.response.statusText]);
      setOverrideMessage(true);
      setError(true);
    }
  };

  const validatePrograms = () => {
    if (programs.some((program) => !program.name)) {
      setErrorMessage([400, "Please, provide a name to all programs."]);
      setOverrideMessage(true);
      setError(true);
      return false;
    }

    return true;
  };

  useEffect(() => {
    setPrograms(initialPrograms);

    return () => {};
  }, [initialPrograms]);

  return (
    <>
      <ProgramWrap data-e2e-id={`${testID}-Programs`}>
        <h2>PROGRAMS</h2>
        <InputWrap>
          {programs.map((program, index) => (
            <div key={index}>
              <InputWrapRow>
                <TextInput
                  testID={`${testID}-Program-${index}-Name`}
                  type="text"
                  name="name"
                  value={program.name}
                  onChange={(e) => handleAddChange(index, e)}
                  style={{
                    padding: "10px",
                    flexGrow: 1,
                  }}
                  inputColor="#332525"
                  placeholder={
                    index === 0
                      ? "Magic Hat Conjuring"
                      : "Enter the Program Name"
                  }
                />
                <RadioButton
                  testID={`${testID}-Program-${index}-Predictions`}
                  buttonLabel="Enable Predictions"
                  value={program.is_predictions_enabled}
                  onChange={(e) =>
                    handleAddChange(index, {
                      ...e,
                      target: {
                        ...e.target,
                        name: "is_predictions_enabled",
                      },
                    })
                  }
                  borderColor={"#093156"}
                  color={"#093156"}
                  light
                />
                <RadioButton
                  testID={`${testID}-Program-${index}-Active`}
                  buttonLabel="Disable Program"
                  value={!program.is_active}
                  onChange={(e) =>
                    handleAddChange(index, {
                      ...e,
                      target: {
                        ...e.target,
                        value: !e.target.value,
                        name: "is_active",
                      },
                    })
                  }
                  borderColor={"#093156"}
                  color={"#093156"}
                  light
                />
              </InputWrapRow>
              <DraggableList
                testID={`${testID}-Program-${index}-Period`}
                title="PERIODS"
                items={program?.periods ?? []}
                onChangeInput={handlePeriodAddChange.bind(null, index)}
                onMoveItem={handleMovePeriodItem.bind(null, index)}
                placeholderInput={"Type to add Period"}
                setErrorMessage={setErrorMessage}
              />
            </div>
          ))}
          <ButtonWrap>
            <AddButton
              data-e2e-id={`${testID}-Programs-Add`}
              type="button"
              onClick={() => handleAddProgram()}
            >
              <img src={AddIcon} alt="Add" />
              <p>Add New Program</p>
            </AddButton>
            <ButtonWrapSub data-e2e-id={`${testID}-Programs-Buttons`}>
              <Button
                style={{ marginRight: "22px" }}
                children="Cancel"
                color="#FFFFFF"
                textColor="#093156"
                onClick={handleResetPrograms}
                disabled={loading}
              />
              <Button onClick={handleSavePrograms} disabled={loading}>
                {loading ? (
                  <ClipLoader size={24} loading={loading} color={"#FFF"} />
                ) : (
                  "Save"
                )}
              </Button>
            </ButtonWrapSub>
          </ButtonWrap>
        </InputWrap>
      </ProgramWrap>
      <AttachUsersToProgramsModal
        opened={openUsersModal}
        institutionId={institutionId}
        programs={newPrograms}
        onSubmit={(usersByPrograms) => {
          savePrograms(usersByPrograms);
        }}
        onDismiss={() => setOpenUsersModal(false)}
      />
    </>
  );
}

const ProgramWrap = styled.div`
  margin-top: 70px;
  margin-bottom: 44px;

  h2 {
    font-family: "Bebas Neue";
    font-weight: normal;
    font-size: 30px;
    color: #332525;
    margin-bottom: 20px;
  }
`;

const InputWrap = styled.div`
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  padding: 20px;
  background: #ffffff;
`;

const InputWrapRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  gap: 12px;

  p {
    margin-right: 0;
  }
`;

const AddButton = styled.div`
  cursor: pointer;
  display: flex;
  align-self: start;
  p {
    font-family: Lato;
    font-weight: bold;
    font-size: 16px;
    color: #093156;
    margin-left: 10px;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -22px;
`;

const ButtonWrapSub = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;
`;

export default AddProgram;

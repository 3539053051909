import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";

import Button from "../components/Button/Button";
import { ReactComponent as LogoLogin } from "../assets/logo_login.svg";
import RoundedError from "../assets/RoundedError.svg";

import api from "../services/api";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const isNewUser = queryParams.has("new_user");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMesage] = useState("");

  const getParsedMessage = (message) => {
    if (message[0] === "This field may not be blank.")
      return ["This field cannot be blank."];
    else return message;
  };

  useEffect(() => {
    if (token) {
      verifyLinkValid();
    }
  }, [token]);

  const verifyLinkValid = async () => {
    try {
      await api.post("/reset-password/verify-token/", {
        token: token,
      });
    } catch (err) {
      const url = "/login";

      if (err.response.status == 404) {
        return navigate(url + "?invalid=true");
      }

      if (err.response.status == 401) {
        return navigate(url + "?expired-reset-token");
      }
    }
  };

  const handleResetPassword = async () => {
    if (password === confirmPassword) {
      try {
        const response = await api.post("/password_reset/confirm/", {
          password: password,
          token: token,
        });

        navigate("/login");
        return response;
      } catch (error) {
        const errorStr = error.response.data.password;
        setErrorMesage(errorStr);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMesage(["Your password doesn't match. Verify and try again."]);
    }
  };

  return (
    <Container>
      <Content>
        <Logo />
        <Description>Please set your password below.</Description>
        <Title>Create a password</Title>
        <TextInputV2
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          placeholder="Type your new password"
        />
        <TextInputV2
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          type="password"
          placeholder="Type new password again to confirm"
        />
        <ResetPasswordButton
          bold={false}
          onClick={() => {
            handleResetPassword();
          }}
        >
          {" "}
          {loading ? (
            <ClipLoader color={"#FFF"} loading={loading} />
          ) : isNewUser ? (
            "Save"
          ) : (
            "Reset"
          )}{" "}
        </ResetPasswordButton>
        {errorMessage !== "" && errorMessage.length >= 1 ? (
          getParsedMessage(errorMessage)?.map((item) => (
            <ErrorMessage>
              {" "}
              <ErrorImage src={RoundedError} alt="error" />
              {item}
            </ErrorMessage>
          ))
        ) : (
          <></>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(225deg, #1c3f5c 0%, #2d6672 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Description = styled.p`
  font-weight: 400;
  margin: 55px 0;
  color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

const Title = styled.span`
  font-family: "Bebas Neue";
  color: white;
  font-size: 20px;
  letter-spacing: 0.05em;
`;

const TextInputV2 = styled.input`
  width: 100%;
  padding: 10px 22px;
  border: none;
  border-radius: 5px;
  margin-top: 13px;

  ::placeholder {
    font-weight: 500;
  }
`;

const Logo = styled(LogoLogin)``;

const ResetPasswordButton = styled(Button)`
  margin-top: 20px;
  border: 1px solid #ffffff;
  width: 100%;
`;

const ErrorMessage = styled.span`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #fe7a7a;
  text-align: center;
`;

const ErrorImage = styled.img`
  position: relative;
  top: 3px;
  height: 20px;
  width: 20px;
  margin-right: 4px;
`;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Card from "../../components/Card";
import Carousel from "../../components/Carousel";
import SelectV2 from "../../components/SelectV2";
import Table from "../../components/Table/Table";
import Paragraph from "../../components/Paragraph";
import SearchInput from "../../components/SearchInput";
import Warning from "../../components/Warning/Warning";
import SortButton from "../../components/Sort/SortButton";
import TableOptionsComponent from "../../components/TableOptions";
import { ReactComponent as InfoIcon } from "../../assets/circle-alert.svg";

import usePagination from "../../hooks/usePagination";
import InsightsService from "../../services/insights";
import { InsightTableTitles, SortInsightTitles } from "../../services/data";
import { institutionSelector } from "../../store/reducers/institutionReducer";

export default function Insights() {
  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    isLoading,
  ] = usePagination(InsightsService.getAll, SortInsightTitles[0], true);
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [loadingCards, setLoadingCards] = useState(false);
  const { selectedPeriod } = useSelector(institutionSelector);

  const getDropdownFilters = () => {
    return {
      student__period: selectedPeriod.value,
      origin_data__period: selectedPeriod.value,
      period__id: selectedPeriod.value,
    };
  };

  const fetchData = async () => {
    setLoadingCards(true);
    const options = { simplified: "True" };
    const response = await InsightsService.getAll({
      ...options,
      ...getDropdownFilters(),
    });

    const filteredData = Object.entries(response.data)
      .filter(([k, _]) => k.includes("median"))
      .filter(([_, v]) => !!v);

    setCardData(filteredData);
    setLoadingCards(false);
  };

  const getTitles = (sorted = false) => {
    const titles = sorted ? SortInsightTitles : InsightTableTitles;

    if (data.results.length === 0 || selectedPeriod.value === "") return titles;

    return [
      titles[0],
      ...titles.filter((title) => {
        const label = sorted ? title.label : title;
        return cardData.some(([k, _]) =>
          k.includes(label.toLowerCase().replace(/\s/g, "_"))
        );
      }),
    ];
  };

  const tableOptions = [
    {
      label: "Detailed View",
      callback: async (id) => {
        navigate(`/home/analysis/${id}/applicant/`);
      },
      newTabOption: true,
      newTabCallback: async (id) => {
        window.open(`/home/analysis/${id}/applicant/?newTab=true`);
      },
    },
  ];

  const CardCarousel = cardData.length > 4 ? Carousel : React.Fragment;

  useEffect(() => {
    fetchData();
  }, [selectedPeriod]);

  return (
    <div>
      <Paragraph>
        The distinguishing characteristics of a person that tend to be stable
        over time.{" "}
      </Paragraph>
      {data.results &&
        (data.results.length === 0 || selectedPeriod.value === "") &&
        !loadingCards && (
          <div style={{ marginTop: "20px" }}>
            <Warning>
              <InfoIcon />
              There is currently no analyzed data available for the selected
              class.
            </Warning>
          </div>
        )}
      {data.results.length > 0 && selectedPeriod.value && (
        <CardContainer>
          <CardCarousel>
            {cardData.map(([key, value], index) => {
              const splittedName = key
                .split("_")
                .filter((_, index, arr) => index < arr.length - 1);
              const name = splittedName.join(" ").toUpperCase();

              return (
                <Card
                  key={index}
                  noPointer
                  name={name}
                  label={name === "NEGATIVE ATTITUDE" ? "Lower is better" : ""}
                  isLoading={loadingCards}
                  number={Math.round(value)}
                />
              );
            })}
          </CardCarousel>
        </CardContainer>
      )}

      <SortContainer>
        <SearchInput
          placeholder="Search by CAS ID"
          max={true}
          value={debouncedOptions.displaySearchText}
          onChange={(e) => {
            onChangeDebounced({ displaySearchText: e.target.value });
          }}
        />{" "}
        <SelectV2
          style={{ margin: "0 22px" }}
          label="Sort by:"
          options={getTitles(true)}
          selected={options.columnSorted}
          onChange={(columnSorted) => onChange({ columnSorted })}
        />
        <SortButton
          value={options.ascending}
          onChange={(value) => {
            onChange({ ascending: value });
          }}
        />{" "}
      </SortContainer>

      <TableContainer>
        <Table
          titles={getTitles()}
          label="Applicants per page"
          data={data.results}
          totalPages={options.totalPages}
          rowsPerPage={options.rowsPerPage}
          loading={isLoading}
          pageSelected={debouncedOptions.displayPageSelected}
          withDebouncer={true}
          withOptions
          onChange={onChange}
          onChangeDebounced={onChangeDebounced}
          renderRow={(item) => (
            <tr key={item.id}>
              <td>
                {" "}
                <TableOptionsComponent
                  id={item.student.id}
                  options={tableOptions}
                />{" "}
              </td>
              <td> {item.student.cas_id} </td>
              <td> {Math.round(item.conscientiousness)} </td>
              <td> {Math.round(item.positive_attitude)} </td>
              <td> {Math.round(item.negative_attitude)} </td>
              <td> {Math.round(item.writing_attitude)} </td>
              {item.proactiveness > 0 && (
                <td> {Math.round(item.proactiveness)} </td>
              )}
              {item.empathy > 0 && <td> {Math.round(item.empathy)} </td>}
              {item.enthusiasm > 0 && <td> {Math.round(item.enthusiasm)} </td>}
            </tr>
          )}
        />
      </TableContainer>
    </div>
  );
}

const CardContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 44px;
  .card {
    width: 23%;
  }
`;

const SortContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 44px;
`;

const TableContainer = styled.section`
  display: flex;
  margin-top: 22px;
`;

import React, { useEffect } from "react";
import styled from "styled-components";

import Pagination from "./Pagination";

const Table = ({
  data = [],
  titles,
  rowsPerPage,
  pageSelected,
  withDebouncer = false,
  withOptions = false,
  onChangeDebounced = () => {},
  totalPages,
  bottomPadding,
  onChange,
  label,
  fixed = false, // If the first column is fixed or not.
  renderRow, // Function  used to cutomize the row.
  periodSelectedVerification = false,
  testID,
}) => {
  function getRow(item, index) {
    if (renderRow) {
      return renderRow(item, index);
    }

    return (
      <tr key={index}>
        {titles.map((_, i) => (
          <td key={i}> {item[i]} </td>
        ))}
      </tr>
    );
  }

  function onHover(row) {
    const td = row.querySelector("td:first-child");
    td.classList.add("td-hover");
  }

  function onHouverOut(row) {
    const td = row.querySelector("td:first-child");
    td.classList.remove("td-hover");
  }

  useEffect(() => {
    const rows = document.querySelectorAll("#main-table tbody tr");

    rows.forEach((row) => {
      row.addEventListener("mouseover", () => onHover(row));
      row.addEventListener("mouseout", () => onHouverOut(row));
    });

    return () => {
      rows.forEach((row) => row.removeEventListener("mouseover", null));
      rows.forEach((row) => row.removeEventListener("mouseout", null));
    };
  }, []);

  return (
    <Container data-e2e-id={`${testID}-Table`}>
      <TableWrapper bottomPadding={bottomPadding}>
        <TableContainer fixed={fixed} id="main-table">
          <thead>
            <tr>
              {withOptions && <th></th>}
              {titles.map((title, index) => (
                <th key={index}>{title}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.map((item, i) => (
              <React.Fragment key={i}>{getRow(item, i)}</React.Fragment>
            ))}
          </tbody>
        </TableContainer>
      </TableWrapper>
      <Pagination
        label={label}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onChange={onChange}
        onChangeDebounced={onChangeDebounced}
        withDebouncer={withDebouncer}
        pageSelected={pageSelected}
      />
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow-x: auto;
`;

export const TableContainer = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: white;

  th,
  td {
    text-align: left;
  }

  th {
    padding: 10px 22px;
    background-color: #eeeeee;
    font-weight: bold;
    border-top: 1px solid #c2c2c2;
  }

  th:first-child {
    border-left: 1px solid #c2c2c2;
    border-top-left-radius: 5px;
    position: ${(props) => (props.fixed ? "sticky" : "relative")};
    left: 0;
  }

  th:last-child {
    border-right: 1px solid #c2c2c2;
    border-top-right-radius: 5px;
  }

  td {
    padding: 12px 22px;
    border-bottom: 1px solid #c2c2c2;
    background-color: white;
  }

  td > p {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td:first-child {
    border-left: 1px solid #c2c2c2;
    position: ${(props) => (props.fixed ? "sticky" : "relative")};
    ${(props) => props.fixed && "border-right: 1px solid #c2c2c2;"};
    left: 0;
    padding: 0 0 0 10px;
    width: 45px;
  }

  td:last-child {
    border-right: 1px solid #c2c2c2;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }

  tbody tr:hover td {
    background: #ced6dd !important;
  }

  .td-hover {
    background: #ced6dd !important;
  }
`;

export default Table;

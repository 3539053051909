import styled from "styled-components";

export default function InputV2({ onChange, ...props }) {
  return (
    <Container {...props}>
      <Label>{props.label}</Label>
      <input
        onChange={(e) => {
          onChange(e);
        }}
        placeholder={props.placeholder}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  padding: 12px 12px 8px 12px;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  height: 44px;

  input {
    font-weight: 500;
    color: ${(props) => (props.inputColor ? props.inputColor : "#767676")};
    border: none;
    width: 107px;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: ${(props) =>
        props.placeholderColor ? props.placeholderColor : "#767676"};
      opacity: 1;
      font-weight: 500;
    }
  }
`;

const Label = styled.label`
  font-family: "Lato";
  font-size: 14px;
  color: #093156;
  background: #ffffff;
  white-space: nowrap;
  position: absolute;
  padding: 0px 3px;
  top: -13px;
  left: 8px;
`;

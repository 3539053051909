import styled from "styled-components";

const Success = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  background: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  color: #155724;
  margin-bottom: 10px;
  svg {
    margin-right: 12px;
  }
`;

export default Success;

import { useSelector } from "react-redux";

import Header from "../../components/Header";
import TabSelected from "../../components/Tab/TabSelected";
import TabContainer, { TabItem } from "../../components/Tab";
import ProgramFilter from "../../components/Sort/Filter";

import { userSelector } from "../../store/reducers/userReducer";

function Transfer() {
  const { is_staff } = useSelector(userSelector);

  return (
    <>
      <Header
        testID={"Transfer"}
        style={{ marginBottom: "0px" }}
        title="Data Transfer"
      />

      <ProgramFilter />

      <TabContainer style={{ paddingTop: "44px", background: "white" }}>
        {is_staff && (
          <TabItem testID={"Origin"} to="/home/transfer/origin">
            Origin Data
          </TabItem>
        )}
        <TabItem testID={"Analyzed"} to="/home/transfer/analyzed">
          Analyzed Data
        </TabItem>
        <TabItem testID={"FileStorage"} to="/home/transfer/storage">
          File Storage
        </TabItem>
      </TabContainer>

      {/* Renders the tab selected from the TabContainer */}
      <TabSelected />
    </>
  );
}

export default Transfer;

import StaffService from "../services/staff";
import UserService from "../services/user";

/**
 * @param {Object} user
 * @param {Function} setErrorMessage
 * @param {Function} reload
 * @returns
 */
const getDisable = (user, setErrorMessage, reload) => {
  if (user.is_active === 1) {
    return {
      label: "Disable",
      callback: async (id) => {
        try {
          user.is_staff
            ? await StaffService.delete(id)
            : await UserService.disable(id);
        } catch (e) {
          setErrorMessage([e.response.status, e.response.statusText]);
        } finally {
          reload();
        }
      },
    };
  }

  return {
    label: "Activate",
    callback: async (id) => {
      try {
        user.is_staff
          ? await StaffService.enable(id)
          : await UserService.enable(id);
      } catch (e) {
        setErrorMessage([e.response.status, e.response.statusText]);
      } finally {
        reload();
      }
    },
  };
};

export class UserStatusEnum {
  static ACTIVE = 1;
  static DISABLED = 0;
  static CREATED = 2;
}

/**
 * @param {Number} id
 * @param {Object} user
 * @param {Number} curr
 * @param {Function} navigate
 * @param {Function} setError
 * @returns
 */
export const getTableOptions = (
  id,
  user,
  curr,
  navigate,
  setModalMessage,
  reload
) => {
  if (user.is_active === UserStatusEnum.CREATED) {
    return [
      {
        label: "Delete",
        callback: async (id) => {
          try {
            user.is_staff
              ? await StaffService.delete(id)
              : await UserService.disable(id);
          } catch (e) {
            setModalMessage([e.response.status, e.response.statusText]);
          } finally {
            reload();
          }
        },
      },
      {
        label: "Resend Activation Email",
        callback: async (id) => {
          try {
            const response = user.is_staff
              ? await StaffService.resendActivationEmail(id)
              : await UserService.resendActivationEmail(id);

            if (response.status !== 200) throw response;

            setModalMessage(["New activation link has been sent.", "success"]);
          } catch (e) {
            setModalMessage([e.response.status, e.response.statusText]);
          }
        },
      },
    ];
  }

  const path = user.is_staff ? `/home/admin/users/${id}` : `/home/users/${id}`;

  const defaultOptions = [
    {
      label: "Edit",
      callback: async (id) => navigate(`${id}/edit`),
      newTabOption: true,
      newTabCallback: async () => window.open(`${path}/edit`),
    },
    {
      label: "View",
      callback: async (id) => navigate(`${id}/`),
      newTabOption: true,
      newTabCallback: async () => window.open(path),
    },
  ];

  if (curr === id) {
    return defaultOptions;
  }

  return [getDisable(user, setModalMessage, reload), ...defaultOptions];
};

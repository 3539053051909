import React from "react";
import styled from "styled-components";

import arrowLeft from "../../assets/ArrowLeft.svg";
import arrowRight from "../../assets/ArrowRight.svg";

export default function Pagination({
  rowsPerPage,
  pageSelected,
  label,
  totalPages,
  onChange,
  onChangeDebounced,
  withDebouncer,
}) {
  return (
    <Container>
      <LeftContainer>
        <RowsPerPage>
          <Select
            value={rowsPerPage}
            onChange={(e) => onChange({ rowsPerPage: e.target.value })}
          >
            <option value="10"> 10</option>
            <option value="20"> 20</option>
            <option value="30"> 30</option>
          </Select>
        </RowsPerPage>
        <Label>{label} </Label>
      </LeftContainer>
      <RightContainer>
        <p>Page </p>
        <Input
          onChange={(e) => {
            if (withDebouncer) {
              onChangeDebounced({
                displayPageSelected: parseInt(e.target.value),
              });
            } else {
              onChange({ displayPageSelected: parseInt(e.target.value) });
            }
          }}
          type={"number"}
          value={pageSelected}
        />
        <p> of {totalPages} </p>
        <ArrowIcon
          onClick={() => {
            if (pageSelected - 1 >= 1) {
              if (withDebouncer) {
                onChangeDebounced({ displayPageSelected: pageSelected - 1 });
              } else {
                onChange({ displayPageSelected: pageSelected - 1 });
              }
            }
          }}
          src={arrowLeft}
          alt="Arrow Left"
        />
        <ArrowIcon
          onClick={() => {
            if (pageSelected + 1 <= totalPages) {
              if (withDebouncer) {
                onChangeDebounced({ displayPageSelected: pageSelected + 1 });
              } else {
                onChange({ displayPageSelected: pageSelected + 1 });
              }
            }
          }}
          src={arrowRight}
          alt="Arrow Right"
        />
      </RightContainer>
    </Container>
  );
}

const ArrowIcon = styled.img`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 22px;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #093156;
`;

const RowsPerPage = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 5px;
  width: 75px;
  height: 30px;
  padding: 0px 12px;
`;

const Select = styled.select`
  border-radius: 5px;
  width: 100%;
  border: none;
  background: #ffffff;

  :focus {
    outline: none;
  }
`;

const Label = styled.p`
  display: flex;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-left: 12px;
  color: #093156;
`;

const Input = styled.input`
  width: 61px;
  height: 30px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  line-height: 24px;
  color: #332525;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  padding: 0px 20px;
  margin: 0px 12px;
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #093156;
    font-family: "Lato";
    font-size: 14px;
    margin: 0 10px;
  }

  img {
    width: 12px;
    height: 10.3px;
    margin-left: 10px;
  }
`;

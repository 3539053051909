import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import BackIcon from "../assets/BackIcon.svg";

const BackComponent = ({
  testID,
  label,
  to = "",
  onClick = () => {},
  newTab = false,
  ...props
}) => {
  const navigate = useNavigate();

  function _onClick() {
    if (newTab) {
      window.top.location.reload(true);
      window.top.close();
      return;
    }
    onClick();
    to && navigate(to);
  }

  return (
    <Back data-e2e-id={`${testID}-Back`} onClick={_onClick} {...props}>
      <img src={BackIcon} alt="Back" />
      <p> {label} </p>
    </Back>
  );
};

const Back = styled.span`
  display: flex;
  padding-bottom: 22.5px;
  padding-left: 44px;
  padding-top: 44px;
  background: white;

  &:hover {
    cursor: pointer;
  }

  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #093156;
    margin-left: 10px;
  }
`;

export default BackComponent;

import api from "./api";

class AuthService {
  static async login(email, password, keepMeLoggedIn) {
    const response = await api.post("/login/", {
      email,
      password,
    });

    localStorage.setItem("token", response.data.token);

    if (keepMeLoggedIn) {
      localStorage.setItem("keepMeLoggedIn", keepMeLoggedIn);
      localStorage.setItem("keepMeLoggedInEmail", email);
      sessionStorage.setItem("keepMeLoggedIn", keepMeLoggedIn);
      sessionStorage.setItem("keepMeLoggedInEmail", email);
    }

    return response;
  }

  static async verifyToken(token) {
    let response = await api.post("/verify_token/", {
      token,
    });
    return response;
  }

  static async resetPassword(email) {
    return api.post("/password_reset/", { email });
  }

  static logout() {
    if (
      localStorage.getItem("keepMeLoggedIn") ||
      sessionStorage.getItem("keepMeLoggedIn")
    ) {
      localStorage.removeItem("token");
    } else {
      localStorage.clear();
      sessionStorage.clear();
    }
  }
}

export default AuthService;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Button from "../../components/Button/Button";
import SelectV2 from "../../components/SelectV2";
import SortButton from "../../components/Sort/SortButton";
import Table from "../../components/Table/Table";
import SearchInput from "../../components/SearchInput";
import TableOptionsComponent from "../../components/TableOptions";
import ErrorModal from "../../components/ErrorModal";
import StatusLink from "../../components/StatusLink";

import StaffService from "../../services/staff";
import { UserAdminTableTitles } from "../../services/data";
import usePagination from "../../hooks/usePagination";
import { userSelector } from "../../store/reducers/userReducer";
import { parseDate, parseUserStatus } from "../../utils/parses";
import { getTableOptions, UserStatusEnum } from "../../utils/users";

const TITLES = [
  { label: "Name", value: "first_name" },
  { label: "User", value: "email" },
  { label: "Status", value: "is_active" },
];

const testID = "AdminUsersScreen";

export default function Users() {
  const navigate = useNavigate();
  const { id } = useSelector(userSelector);

  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    forceReload,
  ] = usePagination(StaffService.getAll, TITLES[0]);
  const [errorMessage, setErrorMessage] = useState(["", ""]);

  return (
    <Container>
      <ButtonContainer>
        <Button testID={`${testID}-Create`} to="new">
          Create a New User
        </Button>
      </ButtonContainer>
      <SortContainer>
        <SearchInput
          testID={testID}
          placeholder="Search by User or Name"
          max={true}
          value={debouncedOptions.displaySearchText}
          onChange={(e) => {
            onChangeDebounced({ displaySearchText: e.target.value });
          }}
        />{" "}
        <SelectV2
          testID={testID}
          style={{ margin: "0 22px" }}
          label="Sort by:"
          options={TITLES}
          selected={options.columnSorted}
          onChange={(columnSorted) => onChange({ columnSorted })}
        />
        <SortButton
          value={options.ascending}
          onChange={(value) => {
            onChange({ ascending: value });
          }}
        />{" "}
      </SortContainer>
      <Table
        testID={testID}
        titles={UserAdminTableTitles}
        label="Applicants per page"
        data={data.results}
        totalPages={options.totalPages}
        rowsPerPage={options.rowsPerPage}
        pageSelected={debouncedOptions.displayPageSelected}
        withDebouncer={true}
        onChange={onChange}
        withOptions={true}
        onChangeDebounced={onChangeDebounced}
        renderRow={(item, index) => (
          <tr key={item.id}>
            <td>
              <TableOptionsComponent
                id={item.id}
                testID={`${testID}-${index}`}
                options={getTableOptions(
                  item.id,
                  item,
                  id,
                  navigate,
                  setErrorMessage,
                  forceReload
                )}
              />
            </td>
            <td> {item.email} </td>
            <td>
              {" "}
              {item.first_name} {item.last_name}{" "}
            </td>
            <td> {item.is_account_representative ? "Yes" : "No"} </td>
            <td>
              {" "}
              {parseDate(item.last_login, "MM/DD/YYYY - hh:mmA") || "-"}{" "}
            </td>
            <td>
              <StatusLink
                disabled={item.is_active !== UserStatusEnum.ACTIVE}
                onClick={() => navigate(`${item.id}`)}
              >
                {parseUserStatus(item.is_active)}
              </StatusLink>
            </td>
          </tr>
        )}
      />
      <ErrorModal
        color="#eee"
        buttonLabel={"Back"}
        error={false}
        callback={() => setErrorMessage(["", ""])}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
      />
    </Container>
  );
}

const Container = styled.section``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SortContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 22px;
`;

const Active = styled.a`
  color: #0c7b7e;
  text-decoration: none;
`;

const Disabled = styled.a`
  text-decoration: none;
  color: #767676;
`;

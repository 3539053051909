export const UserTableTitle = ["User", "Name", "Programs Attached", "Status"];

export const CompentenciesTableTitle = [
  "CAS ID",
  "Leadership",
  "Collaboration",
  "Communication",
  "Analytical Thinking",
  "Conceptual Thinking",
  "Goal Execution",
  "Adaptive",
  "Resourcefulness",
];

export const SortCompetenciesTitles = [
  { label: "CAS ID", value: "student__cas_id" },
  { label: "Adaptive", value: "adapting_and_coping" },
  {
    label: "Analytical Thinking",
    value: "analyzing_and_interpreting",
  },
  {
    label: "Conceptual Thinking",
    value: "creating_and_conceptualizing",
  },
  {
    label: "Resourcefulness",
    value: "enterprising_and_performing",
  },
  {
    label: "Communication",
    value: "interacting_and_presenting",
  },
  { label: "Leadership", value: "leading_and_deciding" },
  { label: "Goal Execution", value: "organizing_and_executing" },
  {
    label: "Collaboration",
    value: "supporting_and_cooperating",
  },
];

export const InsightTableTitles = [
  "CAS ID",
  "Conscientiousness",
  "Positive Attitude",
  "Negative Attitude",
  "Writing Level",
  "Proactiveness",
  "Empathy",
  "Enthusiasm",
];

export const SortInsightTitles = [
  { label: "CAS ID", value: "student__cas_id" },
  { label: "Conscientiousness", value: "conscientiousness" },
  { label: "Positive Attitude", value: "positive_attitude" },
  { label: "Negative Attitude", value: "negative_attitude" },
  { label: "Writing Level", value: "writing_attitude" },
  { label: "Proactiveness", value: "proactiveness" },
  { label: "Empathy", value: "empathy" },
  { label: "Enthusiasm", value: "enthusiasm" },
];

export const PredictionTableTitles = ["CAS ID", "Tier", "Score", "Status"];

export const SortPredictionTitles = [
  { label: "CAS ID", value: "student__cas_id" },
  { label: "Tier", value: "tier" },
  { label: "Status", value: "status" },
  { label: "Score", value: "prediction_score" },
];

export const OriginDataTableTitle = [
  "File",
  "# of Applicants",
  "Uploaded Date",
  "Uploaded By",
  "Prediction Analyzed",
  "Insights Analyzed",
  "Skills Analyzed",
];

export const AnalyzedDataTableTitleStaff = [
  "Data Analyzed",
  "Type",
  "Action",
  "Action by",
];

export const AnalyzedDataTableTitle = [
  "Data Analyzed",
  "Type",
  "# of Total Applicants",
];

export const FileStorageDataTableTitle = [
  "File Name",
  "File Size",
  "Date Uploaded",
  "Uploaded By",
  "Notes",
];

export const InstitutionTableTitles = [
  "Institution",
  "# of Programs",
  "Account Rep",
  "Status",
];

export const Copies = {
  Conscientiousness:
    "The quality of being achievement oriented, dependable, and organized.",
  "Positive Attitude":
    "Tendency to display qualities such as a positive attitude, emotional stability, and social skill, including friendliness and cooperation.",
  "Negative Attitude":
    "Tendency to display qualities such as a negative attitude, neurotic behaviors, insecurity, and stress.",
  "Writing Level":
    "Representation of your verbal fluency, including the diversity of your vocabulary and your ability to coherently construct longer sentences and use bigger words.",
  Proactiveness: "The tendency to take initiative.",
  Empathy:
    "The ability to be aware of and understand the feelings and experiences of others.",
  Enthusiasm: "Feelings of joy and excitement.",
  Competencies:
    "Ability to apply knowledge, skills, and abilities to successfully complete tasks.",
  Leadership:
    "The ability to take control and make decisions on behalf of a team, facilitate team effort toward goals, and delegate.",
  Collaboration:
    "The ability to show respect and support to others, and work effectively with teams, clients, and staff.",
  Communication:
    "The ability to communicate and persuade others effectively across all levels of an organization.",
  "Analytical Thinking":
    " The ability to identify the heart of complex problems and issues and generate strategic solutions.",
  "Conceptual Thinking":
    "The ability to be open to new experiences and solve problems with creativity and innovation.",
  "Goal Execution":
    "The ability to follow directions and procedures, work in a systematic way, and meet agreed upon standards in their performance.",
  Adaptive:
    "The ability to respond to change and effectively manage pressure and stressors.",
  Resourcefulness:
    "The ability to focus one’s energy toward achieving work objectives and creating business success.",
};

export const Traits = {
  Conscientiousness: [
    { value: "adventurous", name: "Adventurous" },
    { value: "ambitious", name: "Ambitious" },
    { value: "assertive", name: "Assertive" },
    { value: "conscientious", name: "Conscientious" },
    { value: "disciplined", name: "Disciplined" },
    { value: "power", name: "Power" },
    { value: "reward", name: "Reward" },
    { value: "focus_future", name: "Focus Future" },
    { value: "admiration", name: "Admiration" },
    { value: "empathetic", name: "Empathetic" },
    { value: "genuine", name: "Genuine" },
    { value: "authentic", name: "Authentic" },
    { value: "inward_focus", name: "Inward_focus" },
    { value: "risk_aversion", name: "Risk_aversion" },
    { value: "focus_past", name: "Focus Past" },
    { value: "self_focus", name: "Self Focus" },
    { value: "ambi_feel", name: "Ambi Feel" },
  ],
  "Positive Attitude": [
    { value: "agreeableness", name: "Agreeableness" },
    { value: "cheerful", name: "Cheerful" },
    { value: "cooperative", name: "Cooperative" },
    { value: "dutiful", name: "Dutiful" },
    { value: "friendly", name: "Friendly" },
    { value: "generous", name: "Generous" },
    { value: "humble", name: "Humble" },
    { value: "sociable", name: "Sociable" },
    { value: "social_affiliation", name: "Social Affiliation" },
    { value: "social", name: "Social" },
    { value: "drives_affiliation", name: "Drives Affiliation" },
    { value: "emotionality", name: "Emotionality" },
    { value: "good_feel", name: "Good Feel" },
    { value: "gratitude", name: "Gratitude" },
    { value: "love", name: "Love" },
    { value: "no_emotion", name: "No Emotion" },
  ],
  "Negative Attitude": [
    { value: "aggressive", name: "Aggressive" },
    { value: "anxiety_prone", name: "Anxiety Prone" },
    { value: "cautious", name: "Cautious" },
    { value: "impulsive", name: "Impulsive" },
    { value: "melancholy", name: "Melancholy" },
    { value: "neuroticism", name: "Neuroticism" },
    { value: "self_conscious", name: "Self Conscious" },
    { value: "stress_prone", name: "Stress Prone" },
    { value: "negations", name: "Negations" },
    { value: "cognitive_processes", name: "Cognitive Processes" },
    { value: "differentiation", name: "Differentiation" },
    { value: "discrepancies", name: "Discrepancies" },
    { value: "tentative", name: "Tentative" },
    { value: "external_focus", name: "External Focus" },
    { value: "anger", name: "Anger" },
    { value: "bad_feel", name: "Bad Feel" },
    { value: "disgust", name: "Disgust" },
    { value: "fear", name: "Fear" },
    { value: "sadness", name: "Sadness" },
    { value: "energetic", name: "Energetic" },
    { value: "self_assured", name: "Self Assured" },
    { value: "trusting", name: "Trusting" },
    { value: "analytical_thinking", name: "Analytical Thinking" },
  ],
};

export const UserAdminTableTitles = [
  "User",
  "Name",
  "Account Rep",
  "Last Login",
  "Status",
];

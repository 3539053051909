import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";

import Modal from "../components/Modal";
import Paragraph from "../components/Paragraph";
import Button from "../components/Button/Button";
import ErrorModal from "../components/ErrorModal";
import RadioButton from "../components/Button/RadioButton";
import { ReactComponent as LogoLogin } from "../assets/logo_login.svg";
import RoundedError from "../assets/RoundedError.svg";

import AuthService from "../services/authentication";
import { setUser } from "../store/reducers/userReducer";
import {
  clearData,
  setInstitution,
  setPeriod,
  setProgram,
} from "../store/reducers/institutionReducer";

export default function Login() {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [message, setMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState(["", ""]);

  useEffect(() => {
    const invalid = params.get("invalid");
    const idleLoggedOut = params.has("idle-timeout");
    const expiredResetToken = params.has("expired-reset-token");

    if (invalid) {
      setErrorMessage(["", "This link has already been used or is invalid."]);
    } else if (idleLoggedOut) {
      setIsModalOpened(true);
    } else if (expiredResetToken) {
      setErrorMessage(["", "This link has expired."]);
    }
  }, [params]);

  const toggleError = (message) => {
    setErrorMessage(message);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/home/analysis");
    } else if (
      sessionStorage.getItem("keepMeLoggedIn") ||
      localStorage.getItem("keepMeLoggedIn")
    ) {
      setEmail((localStorage || sessionStorage).getItem("keepMeLoggedInEmail"));
      setRememberMe(true);
    }
  }, [sessionStorage, localStorage]);

  const getLastSelected = (response) => {
    if (!response.period_selected) {
      return null;
    }
    return {
      period: {
        value: response.period_selected.id,
        label: response.period_selected.name,
      },
      program: {
        value: response.period_selected.program.id,
        label: response.period_selected.program.name,
        is_predictions_enabled:
          response.period_selected.program.is_predictions_enabled,
      },
      institution: {
        value: response.period_selected.program.institution.id,
        label: response.period_selected.program.institution.name,
      },
    };
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await AuthService.login(email, password, rememberMe);
      const lastSelected = getLastSelected(response.data);
      const redirectTo =
        localStorage.getItem?.("redirect_to") ?? "/home/analysis";
      localStorage.removeItem("redirect_to");
      dispatch(clearData());
      dispatch(setUser(response.data));

      if (lastSelected) {
        dispatch(setInstitution(lastSelected.institution));
        dispatch(setProgram(lastSelected.program));
        dispatch(setPeriod(lastSelected.period));
      }

      navigate(redirectTo);
    } catch (error) {
      if (error.response.data.error !== "INCORRECT_PASSWORD") {
        if (error.response.data.error === "Institution is inactive") {
          setMessage("Institution is inactive");
        } else {
          setMessage(
            "Your account has been locked due to too many failed login attempts. We have sent you an email with a link to reset your password."
          );
        }
      } else {
        setMessage("Please check the username and password for any mistakes.");
      }
      setMessageError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <Container data-e2e-id="LoginScreen-Container">
      <Content onSubmit={handleLogin}>
        <Logo />
        {messageError ? (
          <ErrorContainer>
            <ErrorMessage data-e2e-id={"LoginScreen-ErrorMsg"}>
              {" "}
              <ErrorImage src={RoundedError} alt="error" />
              {message}
            </ErrorMessage>
          </ErrorContainer>
        ) : (
          <Description></Description>
        )}
        <Title data-e2e-id="LoginScreen-Title">Login</Title>

        <TextInputV2
          data-e2e-id="LoginScreen-EmailInput"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Email"
        />
        <TextInputV2
          data-e2e-id="LoginScreen-PassInput"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          placeholder="Password"
        />
        <div style={{ alignSelf: "flex-start" }}>
          <RadioButton
            testID={"LoginScreen-RememberMe"}
            name="remeberMe"
            buttonLabel="Remember my email"
            onChange={handleChange}
            light={rememberMe}
            textColor="#FFFFFF"
            borderColor="#FFFF"
          />
        </div>
        <LoginButton testID={"LoginScreen-Submit"} bold={false} type="submit">
          {" "}
          {loading ? (
            <ClipLoader color={"#FFF"} loading={loading} />
          ) : (
            "Log in"
          )}{" "}
        </LoginButton>
        <RecoverCredentialsContainer>
          <a href="/recover-password" data-e2e-id={"LoginScreen-ForgotPass"}>
            Forgot Password
          </a>
        </RecoverCredentialsContainer>
      </Content>
      <ErrorModal
        overrideMessage={true}
        color="#eee"
        buttonLabel={"Back"}
        callback={() => {
          toggleError(["", ""]);
        }}
        isOpen={errorMessage[1]}
        errorMessage={errorMessage}
        error={true}
        statusCode={false}
      />
      {isModalOpened && (
        <Modal
          title=""
          label={"OK"}
          onClick={() => setIsModalOpened(false)}
          onSubmit={() => setIsModalOpened(false)}
          color={"#eee"}
          cancelable={false}
          noBorder
        >
          <Paragraph>You have been logged out due to inactivity.</Paragraph>
        </Modal>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(225deg, #1c3f5c 0%, #2d6672 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  padding: 44px 0 40px 0;
`;

const ErrorMessage = styled.span`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #fe7a7a;
  text-align: left;
`;

const ErrorImage = styled.img`
  position: relative;
  top: 3px;
  height: 20px;
  width: 20px;
  margin-right: 4px;
`;

const Content = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Description = styled.p`
  font-weight: 400;
  margin: 55px 0;
  color: white;
`;

const Title = styled.span`
  font-family: "Bebas Neue";
  color: white;
  font-size: 20px;
  letter-spacing: 0.05em;
`;

const RecoverCredentialsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;

  a {
    font-size: 14px;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
    line-height: 20px;
  }
`;

const TextInputV2 = styled.input`
  width: 100%;
  padding: 10px 22px;
  border: none;
  border-radius: 5px;
  margin-top: 13px;

  ::placeholder {
    font-weight: 500;
  }
`;

const Logo = styled(LogoLogin)``;

const LoginButton = styled(Button)`
  border: 1px solid #ffffff;
  width: 100%;
`;

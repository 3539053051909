import api from "./api";

export default class InstitutionService {
  static async getPeriods(programId, params = {}) {
    const response = await api.get(`/programs/${programId}/periods/`, {
      ...params,
    });
    return response;
  }

  static async deletePeriod(periodId) {
    const response = await api.delete(`/programs/${periodId}/periods/`);
    return response;
  }

  static async create(institutionInfo) {
    const response = await api.post("/institutions/", {
      name: institutionInfo.name,
      account_representative_id: institutionInfo.accountRepresentativeId,
      initial_program_name: institutionInfo.initialProgram.name,
      initial_program_is_predictions_enabled:
        institutionInfo.initialProgram.is_predictions_enabled,
    });
    return response;
  }

  static async getAll(params) {
    const response = await api.get("/institutions/", {
      params,
    });
    return response;
  }

  static async get(id, params = {}) {
    const response = await api.get(`/institutions/${id}/`, { ...params });
    return response;
  }

  static async enable(institutionInfo) {
    const response = await api.put(`/institutions/${institutionInfo.id}/`, {
      name: institutionInfo.name,
      account_representative_id: institutionInfo.accountRepresentativeId,
      is_active: true,
    });
    return response;
  }

  static async update(institutionInfo) {
    const response = await api.put(
      `/institutions/${institutionInfo.institutionId}/`,
      {
        name: institutionInfo.name,
        account_representative_id: institutionInfo.accountRepresentativeId,
      }
    );
    return response;
  }

  static async savePrograms(programs, id) {
    return api.put(`/institutions/${id}/programs/`, programs);
  }

  static async delete(institutionId) {
    const response = await api.delete("/institutions/" + institutionId + "/");
    return response;
  }
}

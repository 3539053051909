import { ClipLoader } from "react-spinners";
import styled from "styled-components";

import Button from "./Button/Button";
import Panel from "./Panel";

const Modal = ({
  testID,
  onClick,
  color,
  shadow,
  onSubmit,
  isLoading,
  label,
  cancelLabel,
  title = "",
  flex = false,
  noBorder = false,
  error = false,
  disabled = false,
  deleteModal = false,
  cancelable = true,
  ...props
}) => {
  return (
    <Wrap flex={flex}>
      {" "}
      <Container error={error} flex={flex}>
        <Panel
          testID={`${testID}-Modal`}
          isModal={true}
          noBorder={noBorder}
          deleteModal={deleteModal}
          title={title}
          close={true}
          onClick={onClick}
          color={color}
        >
          <Content>{props.children}</Content>
          <ButtonContainer>
            {cancelable && (
              <Button color="#FFFFFF" textColor="#093156" onClick={onClick}>
                {cancelLabel ? cancelLabel : "Cancel"}
              </Button>
            )}
            {onSubmit && (
              <Button
                onClick={() => {
                  onSubmit();
                }}
                disabled={isLoading || disabled}
              >
                {isLoading ? (
                  <ClipLoader size={24} loading={isLoading} color={"#FFF"} />
                ) : (
                  label
                )}
              </Button>
            )}
          </ButtonContainer>
        </Panel>
      </Container>
    </Wrap>
  );
};

export default Modal;

const Container = styled.div`
  position: absolute;
  width: 712px;
  height: fit-content;
  background: #ffffff;
  ${(props) => {
    return props.flex ? "" : "left: 25%; top: 25%;";
  }}

  border: ${(props) => (props.shadow ? null : "1px solid #c2c2c2")};
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.shadow && "5px 5px 10px 5px rgba(0, 0, 0, 0.1)"};
  border-radius: 5px;
`;

const Content = styled.section`
  display: flex;
  flex: 0 auto;
  justify-content: space-between;
  gap: 22px;
`;

const Wrap = styled.div`
  z-index: 500;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: -0.34px;
  background: rgba(9, 49, 86, 0.5);
  ${(props) => {
    return props.flex
      ? "display: flex; align-items: center; justify-content: center"
      : "";
  }}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: -22px;
`;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import Modal from "../../components/Modal";
import Table from "../../components/Table/Table";
import Subtitle from "../../components/Subtitle";
import TextArea from "../../components/Textarea";
import Button from "../../components/Button/Button";
import ErrorModal from "../../components/ErrorModal";
import RadioButton from "../../components/Button/RadioButton";
import TableOptionsComponent from "../../components/TableOptions";

import { ReactComponent as Line } from "../../assets/Line.svg";
import { ReactComponent as FilesIcon } from "../../assets/Files.svg";
import {
  BoldText,
  DeleteModalText,
  DeleteModalTextContainer,
  HorizontalLine,
  LineContainer,
  LeftContainer,
  RightContainer,
  Section,
  Select,
  SelectedFileContainer,
  TableContainer,
  Text,
  SelectWrap,
} from "./styles";

import usePagination from "../../hooks/usePagination";
import FileStorageService from "../../services/fileStorage";
import { FileStorageDataTableTitle } from "../../services/data";
import { userSelector } from "../../store/reducers/userReducer";
import {
  institutionSelector,
  setProgram,
} from "../../store/reducers/institutionReducer";
import { fetchProgramsStaffUser, mapPrograms } from "../../utils/analysis";
import { parseByteSizes, parseDate, parseName } from "../../utils/parses";

const NOTES_MAX_LENGTH = 150;

function FileStorage() {
  const dispatch = useDispatch();
  const {
    is_staff,
    institution: institutionState,
    programs: userPrograms,
  } = useSelector(userSelector);
  const { selectedInstitution, selectedProgram } =
    useSelector(institutionSelector);

  const programsState =
    (is_staff ? institutionState?.programs : userPrograms) ?? [];
  const parsedPrograms = mapPrograms(programsState);

  const [notes, setNotes] = useState("");
  const [sendNotification, setSendNotification] = useState(false);
  const [notesCharCounter, setNotesCharCounter] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModalFile, setDeleteModalFile] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [programs, setPrograms] = useState(parsedPrograms);
  const [programSelected, setProgramSelected] = useState(selectedProgram);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(["", ""]);

  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    forceReload,
    isLoading,
  ] = usePagination(FileStorageService.getAll, {}, true);

  const { getRootProps, open, acceptedFiles, getInputProps, isDragReject } =
    useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      maxFiles: 1,
    });

  const tableOptions = [
    {
      label: "Download",
      callback: async (id) => {
        const response = await FileStorageService.getDownload(id);
        window.open(response.data);
      },
    },
    {
      label: "Delete",
      callback: async (id) => {
        setOpenDeleteModal(true);
        setDeleteModalFile(id);
      },
    },
  ];

  const handleClick = () => {
    setOpenModal(false);
    clearFiles();
  };

  const clearFiles = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
    setNotes("");
    setNotesCharCounter(0);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteModalFile(null);
  };

  const getColor = () => {
    if (isDragReject) {
      return "blue";
    }
    return "black";
  };

  const handleError = (e) => {
    setOverrideMessage(true);

    if (e?.response?.data?.file?.error) {
      return setErrorMessage([e.response.status, e.response.data.file.error]);
    }

    if (e.message) {
      return setErrorMessage([400, e.message]);
    }

    return setErrorMessage([e.response.status, e.response.statusText]);
  };

  const uploadFileStorage = async () => {
    let shouldReload = true;
    setIsLoadingUpload(true);

    try {
      if (acceptedFiles.length > 0) {
        const bodyFormData = new FormData();

        bodyFormData.append("file", acceptedFiles[0]);
        bodyFormData.append("notes", notes);
        bodyFormData.append("send_email_to_all_users", sendNotification);
        bodyFormData.append(
          "institution_id",
          selectedInstitution.value || institutionState.id
        );
        bodyFormData.append("program_id", programSelected.value);
        bodyFormData.append("program__id", programSelected.value);

        const response = await FileStorageService.create(bodyFormData);

        if (response.status !== 201) throw response;

        await dispatch(
          setProgram({
            value: programSelected.value,
            label: programSelected.label,
            is_predictions_enabled: programSelected.is_predictions_enabled,
          })
        );
      }
    } catch (e) {
      shouldReload = false;
      handleError(e);
    } finally {
      setIsLoadingUpload(false);
      clearFiles();

      if (shouldReload) {
        setOpenModal(false);
        forceReload();
      }
    }
  };

  const deleteData = async () => {
    setIsLoadingDelete(true);
    try {
      const response = await FileStorageService.delete(deleteModalFile);
      if (response.status !== 204) throw response;
    } catch (e) {
      handleError(e);
    } finally {
      setOpenDeleteModal(false);
      clearFiles();
      setIsLoadingDelete(false);
      forceReload();
    }
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      const programs = is_staff
        ? await fetchProgramsStaffUser(selectedInstitution.value)
        : mapPrograms(programsState);

      setPrograms(programs);
      setProgramSelected(programs[0]);
    };

    fetchPrograms();

    return () => {};
  }, [selectedInstitution?.value]);

  useEffect(() => {
    if (openModal) {
      setProgramSelected(selectedProgram);
    }
  }, [openModal]);

  return (
    <>
      <Section>
        <Subtitle>FILE STORED</Subtitle>
        <Button
          style={{ margin: "0px", position: "relative" }}
          onClick={() => setOpenModal((prevState) => !prevState)}
        >
          Upload New File
        </Button>
      </Section>
      <TableContainer>
        <Table
          withOptions
          titles={FileStorageDataTableTitle}
          label="Files per page"
          data={data.results}
          loading={isLoading}
          totalPages={options.totalPages}
          rowsPerPage={options.rowsPerPage}
          pageSelected={debouncedOptions.displayPageSelected}
          withDebouncer={true}
          onChange={onChange}
          onChangeDebounced={onChangeDebounced}
          renderRow={(item) => (
            <tr key={item.id}>
              <td>
                {" "}
                <TableOptionsComponent
                  id={item.id}
                  options={tableOptions ? tableOptions : []}
                />{" "}
              </td>
              <td>{item.file_name}</td>
              <td> {parseByteSizes(item.file_size)} </td>
              <td> {parseDate(item.uploaded_date) || "-"} </td>
              <td>{item.uploaded_by.email}</td>
              <td>
                <p title={item.notes}> {item.notes || "-"}</p>
              </td>
            </tr>
          )}
        />
      </TableContainer>
      {openModal && (
        <Modal
          flex={true}
          onClick={handleClick}
          label="Save"
          onSubmit={uploadFileStorage}
          title="File Upload"
          isLoading={isLoadingUpload}
          color="#eee"
        >
          <LeftContainer>
            <Select
              label="Program"
              options={programs.length ? programs : []}
              selected={programSelected}
              onChange={setProgramSelected}
              size={15}
              title={programSelected?.label}
            />
          </LeftContainer>
          <SelectWrap style={{ gap: 10 }}>
            <RightContainer
              height={"200px"}
              color={getColor()}
              {...getRootProps({ className: "dropzone" })}
            >
              {!(acceptedFiles.length > 0) ? (
                <>
                  <h3>Drag and Drop</h3>
                  <LineContainer>
                    <Line />
                    <span>or </span>
                    <Line />
                  </LineContainer>
                  <input {...getInputProps()} />
                  <Button
                    style={{ marginBottom: 0 }}
                    type="button"
                    onClick={open}
                  >
                    Upload
                  </Button>
                </>
              ) : (
                <SelectedFileContainer>
                  <FilesIcon />
                  <Text>
                    <BoldText>{parseName(acceptedFiles[0].name)}</BoldText>
                    &nbsp;
                    {"is attached!"}
                  </Text>
                  <LineContainer>
                    <HorizontalLine />
                  </LineContainer>
                </SelectedFileContainer>
              )}
            </RightContainer>{" "}
            <TextArea
              name={"notes"}
              label={"Notes"}
              placeholder={"Type here"}
              value={notes}
              onChange={(e) => {
                const { value } = e.target;
                setNotes(value);
                setNotesCharCounter(value.length);
              }}
              maxLength={NOTES_MAX_LENGTH}
              counter={notesCharCounter}
            />
            <RadioButton
              buttonLabel="Send notification to users"
              dark={true}
              value={sendNotification}
              onChange={({ target }) => setSendNotification(target.value)}
            />
          </SelectWrap>
        </Modal>
      )}
      {openDeleteModal && (
        <Modal
          onClick={closeDeleteModal}
          label={"Delete Data"}
          onSubmit={deleteData}
          isLoading={isLoadingDelete}
          noBorder
          deleteModal={true}
        >
          <DeleteModalTextContainer>
            <DeleteModalText>
              Are you sure you want to delete{" "}
              {data.results
                .find((item) => item.id === deleteModalFile)
                .file_name.toUpperCase()}{" "}
              data from ?
            </DeleteModalText>
            <DeleteModalText>This cannot be undone.</DeleteModalText>
          </DeleteModalTextContainer>
        </Modal>
      )}
      <ErrorModal
        color="#eee"
        title="Error"
        buttonLabel={"Back"}
        callback={() => {
          setErrorMessage(["", ""]);
          setOverrideMessage(false);
        }}
        overrideMessage={overrideMessage}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
      />
    </>
  );
}

export default FileStorage;

import InstitutionService from "../services/institution";

export function shouldShowName(data, titles) {
  let showName = false;
  let newTitles = titles.filter((title) => title !== "Name");

  for (const item of data.results) {
    if (item.student.first_name) {
      showName = true;
      newTitles = titles;
      break;
    }
  }

  return { showName, titles: newTitles };
}

/**
 * @param {Array} programs
 * @returns
 */
export const mapPrograms = (programs) =>
  programs
    .filter((program) => program.is_active)
    .map((program) => {
      return {
        label: program.name,
        value: program.id,
        is_predictions_enabled: program.is_predictions_enabled,
      };
    });

/**
 * @param {String} fileName
 * @param {Any} data
 */
export function downloadFileFromData(fileName, data) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * @param {Number} institutionId
 * @returns
 */
export const fetchProgramsStaffUser = async (institutionId) => {
  const response = await InstitutionService.get(institutionId);
  return mapPrograms(response.data.programs);
};

/**
 * @param {Number} programId
 * @returns
 */
export const fetchPeriods = async (programId) => {
  const response = await InstitutionService.getPeriods(programId);
  return response.data.map((period) => {
    return {
      value: period.id,
      label: period.name,
    };
  });
};

import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import Modal from "./Modal";
import Paragraph from "./Paragraph";

import AuthService from "../services/authentication";
import { logout } from "../store/reducers/userReducer";
import { clearData } from "../store/reducers/institutionReducer";
import idleConfig from "../idle.config";

function InactivityModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const intervalId = useRef(null);

  const [isModalOpened, setIsModalOpened] = useState(false);

  const onLogout = () => {
    dispatch(logout());
    dispatch(clearData());
    AuthService.logout();
    navigate("/login?idle-timeout");
  };

  const userIsActive = () => {
    clearInterval(intervalId.current);
    setIsModalOpened(false);
  };

  useIdleTimer({
    ...idleConfig,
    onIdle: () => setIsModalOpened(true),
  });

  useEffect(() => {
    if (isModalOpened) {
      intervalId.current = setInterval(() => {
        onLogout();
      }, 60 * 1000);

      return () => {
        clearInterval(intervalId.current);
        intervalId.current = null;
      };
    }
  }, [isModalOpened]);

  return (
    <div>
      {isModalOpened && (
        <Modal
          title=""
          label={"YES"}
          onClick={userIsActive}
          onSubmit={userIsActive}
          color={"#eee"}
          cancelable={false}
          noBorder
        >
          <Paragraph>Are you still using the application?</Paragraph>
        </Modal>
      )}
    </div>
  );
}

export default InactivityModal;

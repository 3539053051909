import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { institutionSelector } from "../../store/reducers/institutionReducer";

export default function TabSelected() {
  const { selectedProgram } = useSelector(institutionSelector);
  const location = useLocation();

  return (
    <Container
      disabled={
        location.pathname.includes("analysis/predictions") &&
        !selectedProgram.is_predictions_enabled
      }
    >
      <Outlet />
    </Container>
  );
}

const Container = styled.section`
  background-color: #f9f9f9;
  width: 100%;
  padding: 36px 44px;
  display: ${(props) => props.disabled && "none"};
  pointer-events: ${(props) => props.disabled && "none"};
`;

import React from "react";
import styled from "styled-components";

import Checkbox from "./Checkbox";

const RadioButton = ({
  buttonLabel,
  value,
  onChange,
  boldText,
  dark,
  light,
  color,
  textColor,
  borderColor,
  testID,
  disabled = false,
}) => {
  return (
    <Container disabled={disabled}>
      <Checkbox
        testID={`${testID}-Button`}
        value={value}
        onChange={
          disabled ? () => {} : (value) => onChange({ target: { value } })
        }
        dark={dark}
        light={light}
        color={color}
        borderColor={borderColor}
      ></Checkbox>
      <Label
        data-e2e-id={`${testID}-Label`}
        boldText={boldText}
        textColor={textColor}
      >
        {buttonLabel}
      </Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  margin: 5px 0px 15px;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  label {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }
`;

const Label = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  white-space: nowrap;
  font-weight: ${(props) => (props.boldText ? 700 : 400)};
  color: ${(props) => props.textColor};
  margin-left: 12px;
  margin-right: 44px;
`;

export default RadioButton;

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Button = ({
  children,
  color = "#093156",
  textColor = "#FFFFFF",
  to = "",
  type = "",
  disabled,
  bold = true,
  onClick = () => {},
  testID,
  ...props
}) => {
  const navigate = useNavigate();

  function _onClick() {
    if (!disabled) {
      onClick();
      to && navigate(to);
    }
  }

  return (
    <Container
      {...props}
      data-e2e-id={testID}
      type={type}
      onClick={_onClick}
      disabled={disabled}
      color={color}
    >
      <Text bold={bold} textColor={textColor}>
        {children}
      </Text>
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #093156;
  border-radius: 30px;
  padding: 10px 44px;
  background: ${(props) => props.color};
  margin-bottom: 22px;
  cursor: pointer;
  ${(props) => {
    return props.disabled
      ? ` 
      cursor: inherit;
      opacity: 0.2;
    `
      : ``;
  }}
  svg {
    margin-right: 12px;
    position: relative;
    top: 2px;
  }

  &:hover {
    background: #093156;
  }

  &:hover > p {
    color: #fff;
  }
`;

const Text = styled.p`
  color: ${(props) => props.textColor};
  font-weight: ${(props) => (props.bold ? "500" : "500")};
`;

export default Button;

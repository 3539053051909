import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import DeleteModal from "../DeleteModal";
import DraggableListItem from "./ListItem";

import InstitutionService from "../../services/institution";
import {
  institutionSelector,
  setPeriod,
} from "../../store/reducers/institutionReducer";

function DraggableList({
  testID = "",
  title = "",
  items = [],
  inputNamePrefix = "",
  placeholderInput = "",
  onChangeInput,
  onMoveItem,
  setErrorMessage,
}) {
  const dispatch = useDispatch();
  const { selectedPeriod } = useSelector(institutionSelector);

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);

  const openDeleteModal = (item) => {
    setIsDeleteModalOpened(true);
    setItemToBeDeleted(item);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpened(false);
    setItemToBeDeleted(null);
  };

  const handleDeleteItem = async () => {
    try {
      const response = await InstitutionService.deletePeriod(
        itemToBeDeleted.id
      );

      if (response.status !== 204)
        throw new Error("Error deleting period " + itemToBeDeleted.name);

      if (itemToBeDeleted.id === selectedPeriod.value) {
        const newSelectedPeriod =
          items[0].id !== itemToBeDeleted.id
            ? { value: items[0].id, label: items[0].name }
            : { value: "", label: "" };
        await dispatch(setPeriod(newSelectedPeriod));
      }
    } catch (error) {
      setErrorMessage([error.message, 400]);
    } finally {
      closeDeleteModal();
      window.location.reload();
    }
  };

  return items.length > 0 ? (
    <>
      <Container>
        <Title>{title}</Title>
        {items.map((item, index) => (
          <DraggableListItem
            key={item.id}
            testID={testID}
            item={item}
            placeholderInput={placeholderInput}
            index={index}
            moveItem={onMoveItem}
            inputNamePrefix={inputNamePrefix}
            onChangeInput={onChangeInput}
            onDelete={() => openDeleteModal(item)}
          />
        ))}
      </Container>
      {isDeleteModalOpened && (
        <DeleteModal
          itemToBeDeletedName={itemToBeDeleted.name}
          closeModal={closeDeleteModal}
          onSubmit={handleDeleteItem}
          title={"Delete Period"}
        />
      )}
    </>
  ) : null;
}

const Container = styled.ul`
  margin-left: 10%;
`;

const Title = styled.h3`
  font-size: 20px;
  font-family: "Bebas Neue";
  font-weight: normal;
  color: #332525;
  margin-bottom: 20px;
  margin-left: calc(32px + 12px) /* icon width + gap */;
`;

export default DraggableList;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import AuthService from "../../services/authentication";
import { clearData } from "../../store/reducers/institutionReducer";
import { logout } from "../../store/reducers/userReducer";

const RouteGuard = ({ component }) => {
  const [params] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shouldNotRender = !localStorage.getItem?.("token");

  useEffect(() => {
    if (shouldNotRender) {
      dispatch(logout());
      dispatch(clearData());
      AuthService.logout();

      if (params.has("program") && params.has("period")) {
        localStorage.setItem(
          "redirect_to",
          `${location.pathname}?${params.toString()}`
        );
      }

      navigate("/login");
    }
  }, [shouldNotRender]);

  return shouldNotRender ? <></> : component;
};

export default RouteGuard;

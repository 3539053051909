import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import { ReactComponent as Rectangle } from "../assets/Rectangle.svg";

const TierComponent = ({ predictionsData, isLoading }) => {
  const [predictions, setPredictions] = useState(predictionsData);

  useEffect(() => {
    setPredictions(predictionsData);
  }, [predictionsData]);

  const getTier = () => {
    const result = {
      tier1Width: 33.33,
      tier2Width: 33.33,
      tier3Width: 33.34,
    };

    if (predictionsData?.smallest_tier_1) {
      result.tier1Width = 100 - predictionsData.smallest_tier_1;
    }
    if (predictionsData?.smallest_tier_2) {
      result.tier2Width =
        predictionsData.smallest_tier_1 - predictionsData.smallest_tier_2;
      result.tier3Width = predictionsData.smallest_tier_2;
    }

    return result;
  };

  return (
    <TierContainer>
      <div
        style={{
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "row",
          gap: "3px",
        }}
      >
        {!isLoading && (
          <>
            <div style={{ width: `${getTier()["tier3Width"]}%` }}>
              <div className="tier-three" />
              <Subtitle> TIER 3</Subtitle>
            </div>
            <div style={{ width: `${getTier()["tier2Width"]}%` }}>
              <div className="tier-two" />
              <Subtitle> TIER 2</Subtitle>
            </div>
            <div style={{ width: `${getTier()["tier1Width"]}%` }}>
              <div className="tier-one" />
              <Subtitle> TIER 1</Subtitle>
            </div>
            {predictionsData?.prediction_score && (
              <ScoreContainer currentScore={predictions?.prediction_score}>
                <Rectangle />
                <ScoreSub>
                  {" "}
                  SCORE <br />{" "}
                  <Number>
                    {Math.round(
                      parseFloat(predictionsData?.prediction_score) * 10
                    ) / 10}
                  </Number>
                </ScoreSub>
              </ScoreContainer>
            )}
          </>
        )}
      </div>
    </TierContainer>
  );
};

export default TierComponent;

const moveHorizontally = (x) => keyframes`
  0% {
    left: calc(0% - 50px);
  }
  100% {
    left: calc(${x}% - 50px)};
`;

const ScoreContainer = styled.div`
  @keyframes slideInFromLeft {
    0% {
      left: calc(0% - 50px);
    }
    100% {
      left: ${(props) => `calc(${props.currentScore}% - 50px)`};
    }
  }

  display: flex;
  position: absolute;
  animation: ${(props) => moveHorizontally(props.currentScore)} 1s ease-in-out;
  animation-fill-mode: forwards;
  top: -110px;
  z-index: 50;
`;

const Subtitle = styled.h3`
  margin-top: 12px;
  font-family: "Bebas Neue";
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #093156;
  z-index: 1;
  position: relative;
`;

const Number = styled.span`
  font-family: "Lato";
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  align: center;
  color: #093156;
`;

const TierContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 150px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  box-shadow: 10px 10px 20px #dedede;
  border-radius: 5px;
  padding: 66px 21.97px 25px 22px;
  display: flex;
  gap: 3px;
  position: relative;
  .tier-three {
    height: 18px;
    background: #930bb5;
    border-radius: 20px 0 0 20px;
  }

  .tier-two {
    height: 18px;
    background: #e88239;
  }

  .tier-one {
    height: 18px;
    background: #0c7e55;
    border-radius: 0 20px 20px 0;
  }
`;

const ScoreSub = styled.h3`
  margin-top: 12px;
  font-family: "Bebas Neue";
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #093156;
  position: absolute;
  width: 100%;
  z-index: 3;
`;

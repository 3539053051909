import { useState, useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Panel from "../../components/Panel";
import DEFAULT_IMAGE from "../../components/ImageInput/DEFAULT_IMAGE.jpg";
import ResetPasswordButton from "../../components/ResetPasswordButton";
import ErrorModal from "../../components/ErrorModal";

import StaffService from "../../services/staff";
import { userSelector } from "../../store/reducers/userReducer";

const testID = "ViewStaffUser";

export default function ViewStaffUser() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = useSelector(userSelector);

  const [user, setUser] = useState({});
  const [warningMessage, setWarningMessage] = useState(["", ""]);
  const [errorMessage, setErrorMessage] = useState(["", ""]);

  const disableStaffUser = async () => {
    try {
      await StaffService.delete(user.id);
      navigate("/home/admin/users");
    } catch (e) {
      setErrorMessage([e.response.status, e.response.statusText]);
    }
  };

  const activateStaffUser = useCallback(async () => {
    try {
      await StaffService.enable(user.id);
      navigate("/home/admin/users");
    } catch (e) {
      setErrorMessage([e.response.status, e.response.statusText]);
    }
  }, [user]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(async () => {
    const { data } = await StaffService.get(params.id);
    setUser({
      firstName: data.first_name,
      lastName: data.last_name,
      number: data.phone_number,
      email: data.email,
      accountRep: data.is_account_representative,
      profilePicture: data.profile_picture,
      isActive: data.is_active,
      id: data.id,
    });
  }, [params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Container>
        <Panel
          testID={testID}
          title="View User"
          disableOnClick={disableStaffUser}
          disable={!!user.isActive && String(id) !== params.id}
          enable={!user.isActive && String(id) !== params.id}
          enableOnClick={activateStaffUser}
          edit={true}
          color="#eee"
        >
          <Content>
            <FormContent>
              <LineContainer>
                <div data-e2e-id={`${testID}-FirstName`}>
                  <FormTitle> First Name </FormTitle>
                  <p>{user.firstName}</p>
                </div>
                <div data-e2e-id={`${testID}-LastName`}>
                  <FormTitle> Last Name </FormTitle>
                  <p>{user.lastName}</p>
                </div>
              </LineContainer>

              <div data-e2e-id={`${testID}-Email`}>
                <FormTitle> User/Email Address </FormTitle>
                <p>{user.email}</p>
              </div>
              <div data-e2e-id={`${testID}-Phone`}>
                <FormTitle> Phone Number </FormTitle>
                <p>{user.number}</p>
              </div>
            </FormContent>
            <Divider />
            <div data-e2e-id={`${testID}-AccountRep`}>
              <FormTitle> Account Representative </FormTitle>
              <p>{user.accountRep ? "Yes" : "No"}</p>
              <Image
                src={user.profilePicture ? user.profilePicture : DEFAULT_IMAGE}
                alt="Profile Example"
              />
            </div>
          </Content>
          <ResetPasswordButton
            email={user.email}
            onReset={(message) => setWarningMessage([message, "success"])}
          />
          <ErrorModal
            color="#eee"
            buttonLabel={"OK"}
            error={false}
            callback={() => setWarningMessage(["", ""])}
            isOpen={warningMessage[0]}
            errorMessage={warningMessage}
          />
          <ErrorModal
            color="#eee"
            buttonLabel={"OK"}
            error={false}
            callback={() => setErrorMessage(["", ""])}
            isOpen={errorMessage[0]}
            errorMessage={errorMessage}
          />
        </Panel>
      </Container>
    </>
  );
}

const Image = styled.img`
  border-radius: 50%;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  span {
    display: flex;

    p {
      font-family: "Lato";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #093156;
      margin-left: 10px;
    }
  }
`;

const FormTitle = styled.h4`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
`;

const Content = styled.section`
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  gap: 44px;

  img {
    margin-top: 22.5px;
    width: 90px;
    height: 90px;
  }
`;

const FormContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Divider = styled.hr`
  border: 1px solid #c2c2c2;
`;

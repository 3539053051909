import styled from "styled-components";

const Subtitle = styled.h2`
  font-family: Bebas Neue;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.05em;
`;


export default Subtitle;
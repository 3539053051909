import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Header from "../../components/Header";
import SearchInput from "../../components/SearchInput";
import Button from "../../components/Button/Button";
import SelectV2 from "../../components/SelectV2";
import SortButton from "../../components/Sort/SortButton";
import Table from "../../components/Table/Table";
import TableOptionsComponent from "../../components/TableOptions";
import ErrorModal from "../../components/ErrorModal";
import ProgramFilter from "../../components/Sort/Filter";
import StatusLink from "../../components/StatusLink";

import UserService from "../../services/user";
import { UserTableTitle } from "../../services/data";
import { parseUserStatus } from "../../utils/parses";
import usePagination from "../../hooks/usePagination";
import { userSelector } from "../../store/reducers/userReducer";
import { getTableOptions, UserStatusEnum } from "../../utils/users";

const testID = "UserAdminScreen";

const TITLES = [
  { label: "Name", value: "first_name" },
  { label: "User", value: "email" },
  { label: "Status", value: "is_active" },
];

export default function UserAdministration() {
  const navigate = useNavigate();
  const { id } = useSelector(userSelector);
  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    forceReload,
  ] = usePagination(UserService.get, TITLES[0], false, true);

  const [errorMessage, setErrorMessage] = useState(["", ""]);

  return (
    <>
      <Header testID={"UserAdmin"} title="User Administration" />
      <ProgramFilter isOnlyInstitution />

      <WhiteSpace />

      <Container>
        <ButtonContainer>
          <Button testID={`${testID}-Create`} to="new">
            Create a New User
          </Button>
        </ButtonContainer>
        <SortContainer>
          <SearchInput
            testID={testID}
            placeholder="Search by User or Name"
            max={true}
            value={debouncedOptions.displaySearchText}
            onChange={(e) => {
              onChangeDebounced({ displaySearchText: e.target.value });
            }}
          />{" "}
          <SelectV2
            testID={testID}
            style={{ margin: "0 22px" }}
            label="Sort by:"
            options={TITLES}
            selected={options.columnSorted}
            onChange={(columnSorted) => onChange({ columnSorted })}
          />
          <SortButton
            value={options.ascending}
            onChange={(value) => {
              onChange({ ascending: value });
            }}
          />
        </SortContainer>
        <Table
          testID={testID}
          titles={UserTableTitle}
          label="Users per page"
          data={data.results}
          totalPages={options.totalPages}
          rowsPerPage={options.rowsPerPage}
          pageSelected={debouncedOptions.displayPageSelected}
          withDebouncer={true}
          onChange={onChange}
          onChangeDebounced={onChangeDebounced}
          withOptions
          renderRow={(item) => (
            <tr key={item.id}>
              <td>
                <TableOptionsComponent
                  id={item.id}
                  options={getTableOptions(
                    item.id,
                    item,
                    id,
                    navigate,
                    setErrorMessage,
                    forceReload
                  )}
                />
              </td>
              <td> {item.email} </td>
              <td>
                {" "}
                {item.first_name} {item.last_name}{" "}
              </td>
              <td> {item.programs.length} </td>
              <td>
                {" "}
                <StatusLink
                  disabled={item.is_active !== UserStatusEnum.ACTIVE}
                  onClick={() => navigate(`${item.id}`)}
                >
                  {parseUserStatus(item.is_active)}
                </StatusLink>
              </td>
            </tr>
          )}
        />
      </Container>
      <ErrorModal
        color="#eee"
        buttonLabel={"Back"}
        callback={() => setErrorMessage(["", ""])}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
        error={false}
      />
    </>
  );
}

const WhiteSpace = styled.div`
  width: 100%;
  height: 22px;
  background: white;
`;

const Container = styled.div`
  padding: 22px 40px;
  background-color: #f9f9f9;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SortContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

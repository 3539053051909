import React from "react";
import styled, { css } from "styled-components";

function TextArea({
  testID,
  label,
  placeholder,
  value,
  name,
  onChange,
  maxLength,
  counter,
  resizable = false,
  ...props
}) {
  return (
    <Container data-e2e-id={testID}>
      <Label>{label}</Label>
      <TextAreaInput
        {...props}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        resizable={resizable}
        maxLength={maxLength}
      />
      {!isNaN(counter) && !isNaN(maxLength) && (
        <CharCounter>
          {counter}/{maxLength}
        </CharCounter>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextAreaInput = styled.textarea`
  min-height: 100px;
  padding: 10px 22px;
  border: 1px solid #000000;
  border-radius: 5px;
  ${({ resizable }) =>
    !resizable &&
    css`
      resize: none;
    `}
`;

const Label = styled.label`
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #767676;
  margin-bottom: 6px;
`;

const CharCounter = styled.p`
  text-align: right;
  font-size: 12px;
`;

export default TextArea;

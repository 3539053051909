import api from "./api";

export default class NotificationService {
  static async get(params) {
    return api.get("/notifications/", { params });
  }

  static async create(notificationData) {
    let response = api.post("/notifications/", {
      recipient: notificationData.user_id,
      message: "A new upload has occurred",
    });
    return response;
  }

  static async deleteAll() {
    return await api.delete("/notifications/all/");
  }

  static async delete(id) {
    return await api.delete(`/notifications/${id}/`);
  }
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RadioButton from "./Button/RadioButton";
import Modal from "./Modal";

import UserService from "../services/user";

function AttachUsersToProgramsModal({
  institutionId,
  programs = [],
  opened = false,
  onDismiss = () => {},
  onSubmit = () => {},
}) {
  const [institutionUsers, setInstitutionUsers] = useState([]);
  const [selectedUsersByProgram, setSelectedUsersByProgram] = useState([]);
  const [currentProgramIndex, setCurrentProgramIndex] = useState(0);

  const currentProgram = programs[currentProgramIndex];

  const getInstitutionUsers = async () => {
    const { data: institutionUsersData } = await UserService.get({
      institution__id: institutionId,
    });

    setInstitutionUsers(institutionUsersData.results);
  };

  const onCancel = () => {
    setSelectedUsersByProgram([]);
    setCurrentProgramIndex(0);
    onDismiss();
  };

  useEffect(() => {
    if (institutionId) {
      getInstitutionUsers();
    }

    return () => {};
  }, [institutionId]);

  useEffect(() => {
    setSelectedUsersByProgram(
      Array.from({ length: programs.length }, () => [])
    );
  }, [programs]);

  return opened && currentProgram ? (
    <Modal
      onClick={onCancel}
      title={currentProgram.name}
      onSubmit={() => {
        if (currentProgramIndex + 1 === programs.length) {
          onSubmit(selectedUsersByProgram);
          onCancel();
        }

        setCurrentProgramIndex((prevState) => prevState + 1);
      }}
      label={currentProgramIndex + 1 < programs.length ? "Next" : "Save"}
      color="#eee"
    >
      <Container>
        {institutionUsers.map((user, index) => (
          <RadioButton
            key={index.toString()}
            buttonLabel={`${user.first_name} ${user.last_name}`}
            name={`user-${index}`}
            dark={true}
            value={selectedUsersByProgram[currentProgramIndex].some(
              (selectedUser) => selectedUser.id === user.id
            )}
            onChange={({ target }) => {
              const updatedSelectedUsersByProgram = [...selectedUsersByProgram];
              updatedSelectedUsersByProgram[currentProgramIndex] = target.value
                ? updatedSelectedUsersByProgram[currentProgramIndex].concat(
                    user
                  )
                : updatedSelectedUsersByProgram[currentProgramIndex].filter(
                    (selectedUser) => selectedUser.id !== user.id
                  );
              setSelectedUsersByProgram(updatedSelectedUsersByProgram);
            }}
          />
        ))}
      </Container>
    </Modal>
  ) : null;
}

const Container = styled.div``;

export default AttachUsersToProgramsModal;

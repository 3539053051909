import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import styled from "styled-components";

const NavItem = ({ icon, label, to = "", onClick = () => {}, testID }) => {
  let navigate = useNavigate();
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  function _onClick() {
    onClick();
    to && navigate(to);
  }

  return (
    <Container
      data-e2e-id={testID}
      onClick={_onClick}
      selected={to && Boolean(match)}
    >
      <img alt={label} src={icon} />
      {label && <Label>{label}</Label>}
    </Container>
  );
};

const Container = styled.button`
  background: ${(props) => (props.selected ? "#05213A" : "transparent")};
  border: none;
  width: 100%;
  padding: 12px 0;
  cursor: pointer;
`;

const Label = styled.p`
  font-size: 12px;
  color: #ffffff;
`;

export default NavItem;

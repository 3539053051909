import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: 16px;
    color: #332525;
  }
  html, body, #root {
    height: 100vh;
    min-height: 100vh;
  }
`;

export default GlobalStyle;

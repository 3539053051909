import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Card from "../../components/Card";
import Carousel from "../../components/Carousel";
import Paragraph from "../../components/Paragraph";
import SelectV2 from "../../components/SelectV2";
import SortButton from "../../components/Sort/SortButton";
import Table from "../../components/Table/Table";
import SearchInput from "../../components/SearchInput";
import TableOptionsComponent from "../../components/TableOptions";
import Warning from "../../components/Warning/Warning";
import { ReactComponent as InfoIcon } from "../../assets/circle-alert.svg";

import usePagination from "../../hooks/usePagination";
import CompetenciesService from "../../services/competencies";
import {
  CompentenciesTableTitle,
  SortCompetenciesTitles,
} from "../../services/data";
import { institutionSelector } from "../../store/reducers/institutionReducer";

export default function Competencies() {
  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    isLoading,
  ] = usePagination(
    CompetenciesService.getAll,
    SortCompetenciesTitles[0],
    true
  );
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [loadingCards, setLoadingCards] = useState(false);
  const { selectedPeriod } = useSelector(institutionSelector);

  const getDropdownFilters = () => {
    return {
      student__period: selectedPeriod.value,
      origin_data__period: selectedPeriod.value,
      period__id: selectedPeriod.value,
    };
  };

  const fetchData = async () => {
    setLoadingCards(true);
    const data = await CompetenciesService.getAll({
      ...getDropdownFilters(),
    });
    setCardData(data.data);
    setLoadingCards(false);
  };

  const tableOptions = [
    {
      label: "Detailed View",
      callback: async (id) => {
        navigate(`/home/analysis/${id}/applicant/`);
      },
      newTabOption: true,
      newTabCallback: async (id) => {
        window.open(`/home/analysis/${id}/applicant/?newTab=true`);
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [selectedPeriod]);

  return (
    <div>
      <Paragraph>
        Ability to apply knowledge, skills, and abilities to successfully
        complete tasks.{" "}
      </Paragraph>
      {data.results &&
        (data.results.length === 0 || selectedPeriod.value === "") &&
        !loadingCards && (
          <div style={{ marginTop: "20px" }}>
            <Warning>
              <InfoIcon />
              There is currently no analyzed data available for the selected
              class.
            </Warning>
          </div>
        )}
      {data.results.length > 0 && selectedPeriod.value !== "" && (
        <CardContainer>
          <Carousel>
            <Card
              noPointer
              name={<>Leadership</>}
              isLoading={loadingCards}
              number={
                cardData.leading_and_deciding_median == null
                  ? ""
                  : Math.round(cardData.leading_and_deciding_median.toFixed(2))
              }
              wrap
            />
            <Card
              noPointer
              name="Collaboration"
              isLoading={loadingCards}
              number={
                cardData.supporting_and_cooperating_median == null
                  ? ""
                  : Math.round(
                      cardData.supporting_and_cooperating_median.toFixed(2)
                    )
              }
            />
            <Card
              noPointer
              name="Communication"
              isLoading={loadingCards}
              number={
                cardData.interacting_and_presenting_median == null
                  ? " "
                  : Math.round(
                      cardData.interacting_and_presenting_median.toFixed(2)
                    )
              }
              wrap
            />
            <Card
              noPointer
              name="Analytical Thinking"
              isLoading={loadingCards}
              number={
                cardData.analyzing_and_interpreting_median == null
                  ? ""
                  : Math.round(
                      cardData.analyzing_and_interpreting_median.toFixed(2)
                    )
              }
              wrap
            />
            <Card
              noPointer
              name="Conceptual Thinking"
              isLoading={loadingCards}
              number={
                cardData.creating_and_conceptualizing_median == null
                  ? ""
                  : Math.round(
                      cardData.creating_and_conceptualizing_median.toFixed(2)
                    )
              }
              wrap
            />
            <Card
              noPointer
              name="Goal Execution"
              isLoading={loadingCards}
              number={
                cardData.organizing_and_executing_median == null
                  ? ""
                  : Math.round(
                      cardData.organizing_and_executing_median.toFixed(2)
                    )
              }
              wrap
            />
            <Card
              noPointer
              name="Adaptive"
              isLoading={loadingCards}
              number={
                cardData.adapting_and_coping_median == null
                  ? ""
                  : Math.round(cardData.adapting_and_coping_median.toFixed(2))
              }
              wrap
            />
            <Card
              noPointer
              name="Resourcefulness"
              isLoading={loadingCards}
              number={
                cardData.enterprising_and_performing_median == null
                  ? ""
                  : Math.round(
                      cardData.enterprising_and_performing_median.toFixed(2)
                    )
              }
            />
          </Carousel>
        </CardContainer>
      )}

      <SortContainer>
        <SearchInput
          placeholder="Search by CAS ID"
          max={true}
          value={debouncedOptions.displaySearchText}
          onChange={(e) => {
            onChangeDebounced({ displaySearchText: e.target.value });
          }}
        />{" "}
        <SelectV2
          style={{ margin: "0 22px" }}
          label="Sort by:"
          options={SortCompetenciesTitles}
          selected={options.columnSorted}
          onChange={(columnSorted) => onChange({ columnSorted })}
        />
        <SortButton
          value={options.ascending}
          onChange={(value) => {
            onChange({ ascending: value });
          }}
        />{" "}
      </SortContainer>
      <TableContainer>
        <Table
          titles={CompentenciesTableTitle}
          label="Applicants per page"
          data={data.results}
          loading={isLoading}
          totalPages={options.totalPages}
          rowsPerPage={options.rowsPerPage}
          pageSelected={debouncedOptions.displayPageSelected}
          withDebouncer={true}
          onChange={onChange}
          withOptions
          onChangeDebounced={onChangeDebounced}
          renderRow={(item) => (
            <tr key={item.id}>
              <td>
                {" "}
                <TableOptionsComponent
                  id={item.student.id}
                  options={tableOptions}
                />{" "}
              </td>
              <td> {item.student.cas_id}</td>
              <td> {Math.round(item.leading_and_deciding)} </td>
              <td> {Math.round(item.supporting_and_cooperating)} </td>
              <td> {Math.round(item.interacting_and_presenting)} </td>
              <td> {Math.round(item.analyzing_and_interpreting)} </td>
              <td> {Math.round(item.creating_and_conceptualizing)} </td>
              <td> {Math.round(item.organizing_and_executing)} </td>
              <td> {Math.round(item.adapting_and_coping)} </td>
              <td> {Math.round(item.enterprising_and_performing)} </td>
            </tr>
          )}
        />
      </TableContainer>
    </div>
  );
}

const CardContainer = styled.section`
  display: flex;
  flex-direction: row;
  margin-top: 44px;
`;

const SortContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 44px;
`;

const TableContainer = styled.section`
  display: flex;
  flex: 1;
  margin-top: 22px;
`;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import SelectComponent from "../Select";

import {
  setPeriod,
  setProgram,
  institutionSelector,
  setInstitution,
} from "../../store/reducers/institutionReducer";
import { userSelector } from "../../store/reducers/userReducer";
import { mapPrograms } from "../../utils/analysis";
import UserService from "../../services/user";
import InstitutionService from "../../services/institution";

export default function Filters({ isOnlyInstitution = false }) {
  const dispatch = useDispatch();

  const [institutions, setInstitutions] = useState([]);

  const { is_staff, institution_id } = useSelector(userSelector);
  const { selectedInstitution } = useSelector(institutionSelector);

  const fetchInstitutions = async () => {
    const response = await InstitutionService.getAll({ limit: 99999999 });

    setInstitutions(response.data.results);

    if (!institution_id && !selectedInstitution.value) {
      dispatch(setInstitution(response.data.results[0]));
    }
  };

  useEffect(() => {
    if (is_staff) {
      fetchInstitutions();
    }
  }, []);

  return (
    <FilterContainer>
      {is_staff && (
        <div>
          <SelectComponent
            style={{ marginRight: "22px" }}
            label="Institution"
            options={institutions.map((institution) => {
              return {
                label: institution.name,
                value: institution.id,
              };
            })}
            placeholder={"Select an institution"}
            selected={selectedInstitution}
            onChange={(selected) => {
              dispatch(setInstitution(selected));
            }}
          />
        </div>
      )}
      <ProgramFilter hidden={isOnlyInstitution} />
      <PeriodFilter hidden={isOnlyInstitution} />
    </FilterContainer>
  );
}

function ProgramFilter({ hidden = false }) {
  const [params] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [programs, setPrograms] = useState([]);

  const { id, is_staff } = useSelector(userSelector);
  const { selectedInstitution, selectedProgram } =
    useSelector(institutionSelector);

  const isAnalyzedDataScreen = location.pathname.includes("analyzed");

  const fetchProgramsStaffUser = async (signal) =>
    InstitutionService.get(selectedInstitution.value, {
      signal,
    });

  const fetchProgramsUser = async (signal) =>
    UserService.getPrograms({ signal });

  const updatePrograms = (programs = []) => {
    if (!programs.length) {
      return dispatch(
        setProgram({
          label: "",
          value: "",
          is_predictions_enabled: true,
        })
      );
    }

    const hasOldSelectedProgram = programs?.some(
      (program) => program.id == selectedProgram.value
    );

    // Case institution have been changed
    if (!selectedProgram.value || !hasOldSelectedProgram) {
      dispatch(
        setProgram({
          label: programs[0].name,
          value: programs[0].id,
          is_predictions_enabled: programs[0].is_predictions_enabled,
        })
      );
    }

    setPrograms(programs);
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchPrograms = async () => {
      try {
        const response =
          is_staff && selectedInstitution.value
            ? await fetchProgramsStaffUser(abortController.signal)
            : await fetchProgramsUser(abortController.signal);
        const programs = is_staff ? response.data?.programs : response.data;

        updatePrograms(programs.filter((program) => program.is_active) ?? []);
      } catch (error) {
        if (error.name === "AbortError") return;
      }
    };

    fetchPrograms();

    return () => {
      abortController.abort();
    };
  }, [selectedInstitution.value, selectedProgram.value]);

  useEffect(() => {
    const setSelectedProgramFromUrl = async () => {
      const programId = Number(params.get("program"));
      const periodId = Number(params.get("period"));

      if (!(programs.length && isAnalyzedDataScreen && programId && periodId)) {
        return;
      }

      const program = programs.find((p) => p.id === programId);

      if (program) {
        const period = program.periods.find((p) => p.id === periodId);

        if (period) {
          dispatch(
            setProgram({
              label: program.name,
              value: program.id,
              is_predictions_enabled: program.is_predictions_enabled,
            })
          );
          dispatch(
            setPeriod({
              label: period.name,
              value: period.id,
            })
          );
          await UserService.setSelectedPeriod(id, period.id);
        }
      }

      // Remove params to prevent rerenders
      params.delete("program");
      params.delete("period");
    };

    setSelectedProgramFromUrl();

    return () => {};
  }, [isAnalyzedDataScreen, params, programs]);

  return hidden ? null : (
    <div style={{ overflow: "visible", maxHeight: "200px" }}>
      <SelectComponent
        style={{ marginRight: "22px" }}
        label="Program"
        options={mapPrograms(programs)}
        placeholder={"Select a Program"}
        selected={selectedProgram}
        onChange={(program) => {
          dispatch(setProgram(program));
        }}
      />
    </div>
  );
}

function PeriodFilter({ hidden = false }) {
  const location = useLocation();
  const isFileStorageScreen = location.pathname.includes("storage");

  const dispatch = useDispatch();

  const [periods, setPeriods] = useState([]);

  const { id } = useSelector(userSelector);
  const { selectedPeriod, selectedProgram } = useSelector(institutionSelector);

  const fetchPeriods = async (signal) => {
    try {
      const { data: periods } = await InstitutionService.getPeriods(
        selectedProgram.value,
        { signal }
      );
      const parsedPeriods =
        periods?.map((period) => ({
          value: period.id,
          label: period.name,
        })) ?? [];

      setPeriods(parsedPeriods);

      const hasOldSelectedPeriod = periods?.some(
        (period) => period.id == selectedPeriod.value
      );

      if (!selectedPeriod.value || !hasOldSelectedPeriod) {
        dispatch(setPeriod(parsedPeriods[0] || { value: "", label: "" }));
      }
    } catch (error) {
      if (error.name === "AbortError") return;
    }
  };

  const savePeriod = async () => {
    UserService.setSelectedPeriod(id, selectedPeriod.value);
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (selectedProgram && selectedProgram.value) {
      fetchPeriods(abortController.signal);
    } else {
      dispatch(setPeriod({ value: "", label: "" }));
    }

    return () => {
      abortController.abort();
    };
  }, [selectedProgram.value, selectedPeriod.value]);

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.value) savePeriod();
  }, [selectedPeriod.value]);

  return hidden || isFileStorageScreen ? null : (
    <div>
      <SelectComponent
        label="Period"
        options={periods}
        placeholder={"Select a period"}
        selected={selectedPeriod}
        onChange={(period) => {
          dispatch(setPeriod(period));
        }}
      />
    </div>
  );
}

const FilterContainer = styled.section`
  padding-top: 22px;
  display: flex;
  flex-direction: row;
  padding-left: 44px;
  max-height: 91px;
  width: 100%;
  background: white;
  z-index: 300;
`;

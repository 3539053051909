import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Panel from "../../components/Panel";
import Header from "../../components/Header";
import BackComponent from "../../components/Back";
import ResetPasswordButton from "../../components/ResetPasswordButton";
import ErrorModal from "../../components/ErrorModal";

import UserService from "../../services/user";
import { userSelector } from "../../store/reducers/userReducer";

export default function ViewUser() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = useSelector(userSelector);

  const [user, setUser] = useState({});
  const [warningMessage, setWarningMessage] = useState(["", ""]);
  const [errorMessage, setErrorMessage] = useState(["", ""]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(async () => {
    const { data } = await UserService.getUser(params.id);
    setUser({
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      number: data.phone_number,
      email: data.email,
      userAdmin: data.is_user_admin,
      programs: data.programs,
      isActive: data.is_active,
    });
  }, [params.id]);

  const activateUser = async () => {
    try {
      const response = await UserService.enable(user.id);
      if (response) navigate("/home/users");
    } catch (e) {
      setErrorMessage([e.response.status, e.response.statusText]);
    }
  };

  const deleteUser = async () => {
    try {
      await UserService.disable(user.id);
      navigate("/home/users");
    } catch (e) {
      setErrorMessage([e.response.status, e.response.statusText]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Header title="User Administration" />{" "}
      <BackComponent
        to="/home/users"
        label="Back to all Users"
        style={{ marginBottom: "12.5px" }}
      />
      <Container>
        <Panel
          title="View User"
          disable={!!user.isActive && String(id) !== params.id}
          enable={!user.isActive && String(id) !== params.id}
          edit={true}
          color="#eee"
          disableOnClick={deleteUser}
          enableOnClick={activateUser}
        >
          <Content>
            <FormContent>
              <div>
                <FormTitle> First Name </FormTitle>
                <p>{user.firstName}</p>
              </div>
              <div>
                <FormTitle> Last Name </FormTitle>
                <p>{user.lastName}</p>
              </div>
            </FormContent>
            <Divider />
            <FormContent>
              <div>
                <FormTitle> User/Email Address </FormTitle>
                <p>{user.email}</p>
              </div>
              <div>
                <FormTitle> Phone Number </FormTitle>
                <p>{user.number}</p>
              </div>
            </FormContent>
            <Divider />
            <FormContent>
              <div>
                <FormTitle> User Administration Access </FormTitle>
                <p>{user.userAdmin ? "Allowed" : "Not allowed"} </p>
              </div>
            </FormContent>
            <Divider />
            <FormContent>
              <div>
                <FormTitle> Programs </FormTitle>
                <ProgramsList>
                  {user?.programs?.length > 0
                    ? user.programs.map((item) => (
                        <ProgramName>{item.name}</ProgramName>
                      ))
                    : "No program selected"}
                </ProgramsList>
              </div>
            </FormContent>
          </Content>
          <ResetPasswordButton
            email={user.email}
            onReset={(message) => setWarningMessage([message, "success"])}
          />
          <ErrorModal
            color="#eee"
            buttonLabel={"OK"}
            error={false}
            callback={() => setWarningMessage(["", ""])}
            isOpen={warningMessage[0]}
            errorMessage={warningMessage}
          />
          <ErrorModal
            color="#eee"
            buttonLabel={"OK"}
            error={false}
            callback={() => setErrorMessage(["", ""])}
            isOpen={errorMessage[0]}
            errorMessage={errorMessage}
          />
        </Panel>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 22px;
  background-color: #f9f9f9;

  height: 100%;

  span {
    display: flex;

    p {
      font-family: "Lato";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #093156;
      margin-left: 10px;
    }
  }
`;

const FormTitle = styled.h4`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
`;

const Content = styled.section`
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  gap: 44px;
`;

const FormContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Divider = styled.hr`
  border: 1px solid #c2c2c2;
`;

const ProgramName = styled.p`
  margin-right: 10px;
`;

const ProgramsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
`;

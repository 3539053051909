import styled from "styled-components";

const Warning = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f9fec3;
  border: 1px solid #966522;
  border-radius: 5px;
  color: #966522;

  svg {
    margin-right: 12px;
  }
`;

export default Warning;

import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import NavBar from "../components/Nav/NavBar";
import InactivityModal from "../components/InactivityModal";

export default function HomeLayout() {
  return (
    <Container>
      <NavBar />
      <InactivityModal />
      <Content id="root-container">
        <Outlet />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  max-height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 0px 0px 88px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  background: #f9f9f9;
`;

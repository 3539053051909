import moment from "moment";

import { UserStatusEnum } from "./users";

/**
 * @param {String|Date|Number} date
 * @returns
 */
export const parseDate = (date, format = "MM/DD/YYYY") => {
  if (!date) return "";

  return moment(new Date(date)).format(format);
};

/**
 * @param {String} name
 * @returns
 */
export const parseName = (name) => {
  if (name.length <= 30) return name;

  return name.slice(0, 30) + "...";
};

/**
 * Reference: https://stackoverflow.com/a/18650828/19180129
 * @param {Number} bytes
 * @returns
 */
export const parseByteSizes = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB"];
  if (bytes == 0) return "0 Byte";

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

/**
 * @param {Number} statusNumber
 * @returns
 */
export const parseUserStatus = (statusNumber) => {
  switch (statusNumber) {
    case UserStatusEnum.DISABLED:
      return "Disabled";
    case UserStatusEnum.ACTIVE:
      return "Active";
    case UserStatusEnum.CREATED:
      return "Created";

    default:
      return "Unknown";
  }
};

import styled from "styled-components";

import { ReactComponent as AscIcon } from "../../assets/AscIcon.svg";
import { ReactComponent as DescIcon } from "../../assets/DescIcon.svg";

const SortButton = (props) => {
  const { onChange, value } = props;

  return (
    <Wrap>
      <Desc onClick={() => onChange(false)} selected={!value}>
        <DescIcon alt="Descending" fill={!value ? "#FFFFFF" : "#093156"} />
      </Desc>
      <Asc onClick={() => onChange(true)} selected={value}>
        <AscIcon alt="Ascending" fill={value ? "#FFFFFF" : "#093156"} />
      </Asc>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
`;

const Button = styled.button`
  height: 46px;
  width: 44px;
  border: none;
  background: ${(props) => (props.selected ? "#093156" : "#FFFFFF")};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#093156")};
  cursor: pointer;
`;

const Desc = styled(Button)`
  border-radius: 5px 0px 0px 5px;
  border: ${(props) =>
    props.selected ? "1px solid #093156" : "1px solid #C2C2C2"};
`;

const Asc = styled(Button)`
  border-radius: 0px 5px 5px 0px;
  border: ${(props) =>
    props.selected ? "1px solid #093156" : "1px solid #C2C2C2"};
`;

export default SortButton;

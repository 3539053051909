const SET_INSTITUTION = "SET_INSTITUTION";
const SET_PROGRAM = "SET_PROGRAM";
const SET_PERIOD = "SET_PERIOD";
const CLEAR_DATA = "CLEAR_DATA";
const SET_OVERRIDE_PERIOD = "SET_OVERRIDE_PERIOD";

export function setInstitution(institution) {
  return {
    type: SET_INSTITUTION,
    institution,
  };
}

export function setProgram(program) {
  return {
    type: SET_PROGRAM,
    program,
  };
}

export function setPeriod(period) {
  return {
    type: SET_PERIOD,
    period,
  };
}

export function setOverridePeriod(shouldOverride) {
  return {
    type: SET_OVERRIDE_PERIOD,
    overridePeriod: shouldOverride,
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

export const initialState = {
  selectedInstitution: {
    label: "",
    value: "",
  },
  selectedProgram: {
    label: "",
    value: "",
  },
  selectedPeriod: {
    label: "",
    value: "",
  },
  overridePeriod: true,
};

export default function institutionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OVERRIDE_PERIOD:
      return {
        ...state,
        overridePeriod: action.overridePeriod,
      };
    case SET_INSTITUTION:
      return {
        ...state,
        selectedInstitution: {
          ...action.institution,
        },
      };
    case SET_PROGRAM:
      return {
        ...state,
        selectedProgram: {
          ...action.program,
        },
      };
    case SET_PERIOD:
      return {
        ...state,
        selectedPeriod: {
          ...action.period,
        },
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export function institutionSelector(state) {
  let result = { ...state.institution };

  return result;
}

import { useEffect, useCallback, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Panel from "../../components/Panel";
import ResetPasswordButton from "../../components/ResetPasswordButton";
import ErrorModal from "../../components/ErrorModal";
import { ReactComponent as CheckIconCircle } from "../../assets/CheckIconCircle.svg";
import { ReactComponent as CloseCircle } from "../../assets/CloseCircle.svg";

import InstitutionService from "../../services/institution";

export default function ViewInstitution() {
  const params = useParams();
  const navigate = useNavigate();
  const [warningMessage, setWarningMessage] = useState(["", ""]);
  const [institution, setInstitution] = useState({
    institutionName: "",
    email: "",
    firstName: "",
    lastName: "",
    number: "",
    accountRep: "",
    programs: [],
    is_active: false,
  });

  const toggleWarning = (message) => {
    setWarningMessage(message);
  };

  const getParsedInstitutionJson = () => {
    const jsonData = {
      name: institution.institutionName,
      accountRepresentativeId: institution.accountRepId,
      id: institution.id,
    };
    return jsonData;
  };

  const activateInstitution = useCallback(async () => {
    try {
      await InstitutionService.enable(getParsedInstitutionJson());
      navigate("/home/admin/institutions");
    } catch (e) {
      console.log(e);
    }
  }, [institution]);

  const disableInstitution = async () => {
    try {
      await InstitutionService.delete(params.id);
      navigate("/home/admin/institutions");
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async () => {
    const { data } = await InstitutionService.get(params.id);

    setInstitution({
      institutionName: data.name,
      id: data.id,
      accountRepId: data.account_representative.id,
      is_active: data.is_active,
      accountRep:
        data.account_representative.first_name +
        " " +
        data.account_representative.last_name,
      programs: data.programs,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <Panel
          title="Institution"
          disableOnClick={disableInstitution}
          enableOnClick={activateInstitution}
          disable={institution.is_active}
          enable={!institution.is_active}
          edit={true}
          color="#eee"
        >
          <Content>
            <FormContent>
              <div>
                <FormTitle> Institution </FormTitle>
                <p>{institution.institutionName}</p>
              </div>
              <div>
                <FormTitle> Account Rep </FormTitle>
                <p>{institution.accountRep}</p>
              </div>
            </FormContent>
            <Divider />
            <FormContent>
              <div>
                <FormTitle> Programs </FormTitle>
                <ProgramsList>
                  {institution.programs.map((program) => {
                    return (
                      <ProgramContainer
                        key={program.id}
                        enabled={program.is_predictions_enabled}
                      >
                        <ProgramName>{program.name}</ProgramName>
                        <span>
                          {program.is_predictions_enabled ? (
                            <CheckIconCircle width={14} height={14} />
                          ) : (
                            <CloseCircle width={14} height={14} />
                          )}
                          Predictions{" "}
                          {program.is_predictions_enabled
                            ? "enabled"
                            : "disabled"}
                        </span>
                      </ProgramContainer>
                    );
                  })}
                </ProgramsList>
              </div>
            </FormContent>
          </Content>
          <ResetPasswordButton
            email={institution.email}
            onReset={(message) => {
              toggleWarning([message, "success"]);
            }}
          />
          <ErrorModal
            color="#eee"
            buttonLabel={"OK"}
            error={false}
            callback={() => {
              toggleWarning(["", ""]);
            }}
            isOpen={warningMessage[0]}
            errorMessage={warningMessage}
          />
        </Panel>
      </Container>
    </>
  );
}

const ProgramName = styled.p`
  margin-right: 10px;
`;

const ProgramContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;

  p {
    color: #332525;
    margin-left: 0;
  }

  > span {
    align-items: center;
    color: grey;
    font-size: 14px;
    font-style: italic;

    path {
      stroke: ${(props) => (props.enabled ? "limegreen" : "red")};
    }
  }
`;

const ProgramsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  span {
    display: flex;

    p {
      font-family: "Lato";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #093156;
      margin-left: 10px;
    }
  }
`;

const FormTitle = styled.h4`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
`;

const Content = styled.section`
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  gap: 44px;
`;

const FormContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Divider = styled.hr`
  border: 1px solid #c2c2c2;
`;

import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { ReactComponent as HelpIcon } from "../../assets/Help.svg";
import { ReactComponent as Ellipse } from "../../assets/Ellipse.svg";
import { ReactComponent as CloseIconSmall } from "../../assets/CloseIconSmall.svg";

import { userSelector } from "../../store/reducers/userReducer";
import NotificationService from "../../services/notifications";
import useClickOutside from "../../hooks/useClickOutside";

export default function Header({
  title,
  content,
  options = [],
  testID,
  ...props
}) {
  const user = useSelector(userSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const ref = useRef(null);

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  useClickOutside(ref, toggling, [isOpen]);

  const fetchNotifications = async () => {
    const response = await NotificationService.get();
    setNotifications(response.data.results);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeNotification = (id) => {
    let newData = notifications.filter((item) => item.id !== id);
    setNotifications(newData);
    fetchNotifications();
  };

  const clearAllNotifications = async () => {
    await NotificationService.deleteAll();
    setNotifications([]);
    setIsOpen(false);
  };

  const deleteNotifications = async (id) => {
    if (notifications.length === 1) {
      setIsOpen(false);
    }
    await NotificationService.delete(id);
    removeNotification(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchNotifications();
  }, [isOpen]);

  return (
    <Container data-e2e-id={`${testID}-Header`} {...props}>
      <Title>{title}</Title>
      <RightHeaderContainer>
        {/*  We have dumb div for flex layout  to work in case we dont need to render any content */}
        {content && user.is_staff ? content : <div />}
        <NotificationContainer ref={ref} id="floatingSelect">
          {notifications.length !== 0 ? (
            <HelpButton
              fill="black"
              onClick={() => {
                toggling();
              }}
            />
          ) : (
            <HelpButton fill="gray" />
          )}
          {notifications.length > 0 && (
            <EllipseIcon
              onClick={() => {
                toggling();
              }}
            />
          )}
          {isOpen && (
            <ListContainer>
              <List>
                {notifications.map((item) =>
                  !item.read ? (
                    <ListItem key={item.id}>
                      <div style={{ width: "70%", fontSize: "14px" }}>
                        {item.message}
                      </div>
                      <span> {moment(item.send_date).fromNow()}</span>
                      <CloseIconSmall
                        onClick={() => deleteNotifications(item.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </ListItem>
                  ) : (
                    ""
                  )
                )}
              </List>
              <ClearAllButton
                onClick={clearAllNotifications}
                style={{ cursor: "pointer" }}
              >
                Clear All
              </ClearAllButton>
            </ListContainer>
          )}
        </NotificationContainer>
      </RightHeaderContainer>
    </Container>
  );
}

const Container = styled.header`
  display: flex;
  flex-direction: row;
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 1rem;
  background: white;
`;

const RightHeaderContainer = styled.div`
  margin-left: 10%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.h1`
  font-family: Bebas Neue;
  font-size: 50px;
  line-height: 72px;
  letter-spacing: 0.05em;
  color: #093156;
`;

const HelpButton = styled(HelpIcon)`
  margin-left: 1.5rem;
  cursor: pointer;
`;

const EllipseIcon = styled(Ellipse)`
  margin-left: calc(1.5rem + 16px);
  margin-top: -12.5px;
  cursor: pointer;
`;

const NotificationContainer = styled.div`
  z-index: 500;
  display: flex;
  flex-direction: column;
`;

const ClearAllButton = styled.div`
  width: 356px;
  padding: 8px 20px;
  text-align: center;
  text-decoration: underline;
  line-height: 12px;
  font-size: 10px;
`;

const ListContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 356px;
  margin-left: -302px;
  margin-top: 45px;
  border-radius: 5px;
  background: #f9f9f9;
  border: 1px solid #c2c2c2;
`;

const List = styled.ul`
  width: 354px;
  display: grid;
  padding: 0px 5px;
  background: #f9f9f9;
  max-height: 500px;
  overflow: auto;
  border-radius: 5px;

  li:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    right: 0.6em;
    border: 0.4rem solid transparent;
    border-top: none;
    border-bottom-color: #f9f9f9;
    filter: drop-shadow(0 -0.068rem 0.068rem rgba(184, 194, 194, 0.9));
  }

  li:first-child {
    border-top: none;
  }

  span {
    font-family: "Lato", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    width: 20%;
    color: #767676;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
    -webkit-box-shadow: inset 0 0 6px #111;
  }
`;

const ListItem = styled.li`
  list-style: none;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  border-left: none;
  font-family: "Lato";
  font-size: 14px;
  line-height: 20px;
  color: #332525;
  min-height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px;
  margin: 0px 15px;
`;

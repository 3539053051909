import { useRef, useState } from "react";
import styled from "styled-components";

import arrowDown from "../assets/arrow-down.svg";
import arrowUp from "../assets/arrow-up.svg";

import useClickOutside from "../hooks/useClickOutside";

export default function SelectComponent({
  selected = { value: "", label: "" },
  options,
  onChange,
  listColor,
  placeholder = "",
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const ref = useRef(null);

  useClickOutside(ref, toggling, [isOpen]);

  const onOptionClicked = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <FormFloating ref={ref} {...props}>
      <Label htmlFor="floatingSelect">{props.label}</Label>
      <Selected onClick={toggling}>
        <SelectedText listColor={listColor}>
          {selected.value ? selected.label : placeholder}
        </SelectedText>
        <Image src={isOpen ? arrowUp : arrowDown} alt="arrow down" />
      </Selected>
      <Select id="floatingSelect" onClick={toggling}>
        {isOpen && (
          <List>
            {options.map((item) => (
              <ListItem key={item.value} onClick={() => onOptionClicked(item)}>
                {item.label}
              </ListItem>
            ))}
          </List>
        )}
      </Select>
    </FormFloating>
  );
}

const FormFloating = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => (props.error ? "red" : "#c2c2c2")};
  box-sizing: border-box;
  border-radius: 5px;
  width: 200px;
  padding: 8px 11px 11px;
`;

const Select = styled.div`
  z-index: 1;
  max-height: 200px;
  overflow: auto;
`;

const Selected = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 3px 0px 0px;
`;

const SelectedText = styled.span`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${(props) => props.listColor};
`;

const List = styled.ul`
  border-top: none;
  border-bottom: none;
  margin-left: -13px;
  background: #fff;
  padding: 8px 10px 0px 12px;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  list-style: none;
  padding-top: 8px;
  cursor: pointer;
  font-family: "Lato";
  color: #332525;

  &:hover {
    background-color: lightgray;
  }
`;

const Label = styled.label`
  color: #767676;
  font-weight: bold;
`;

const Image = styled.img`
  width: 12px;
  height: 6.15px;
`;

import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "../../components/Button/Button";
import SelectV2 from "../../components/SelectV2";
import SortButton from "../../components/Sort/SortButton";
import Table from "../../components/Table/Table";
import SearchInput from "../../components/SearchInput";
import TableOptionsComponent from "../../components/TableOptions";
import ErrorModal from "../../components/ErrorModal";

import { InstitutionTableTitles } from "../../services/data";
import InstitutionService from "../../services/institution";
import usePagination from "../../hooks/usePagination";

const TITLES = [
  { label: "Institution", value: "name" },
  { label: "# of  Programs", value: "programs__count" },
  { label: "Account Rep", value: "account_representative__first_name" },
  { label: "Status", value: "is_active" },
];

const testID = "InstitutionsScreen";

export default function Institutions() {
  const navigate = useNavigate();

  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    forceReload,
  ] = usePagination(InstitutionService.getAll, TITLES[0]);

  const [errorMessage, setErrorMessage] = useState(["", ""]);
  const toggleError = (message) => {
    setErrorMessage(message);
  };

  const getParsedInstitutionJson = (id) => {
    const institution = data.results.filter((item) => item.id === id)[0];
    const jsonData = {
      name: institution.name,
      accountRepresentativeId: institution.account_representative.id,
      id: institution.id,
    };
    return jsonData;
  };

  const activateUser = useCallback(
    async (id) => {
      try {
        await InstitutionService.enable(getParsedInstitutionJson(id));
      } catch (e) {
        setErrorMessage([e.response.status, e.response.statusText]);
        console.log(e);
        forceReload();
      }
      forceReload();
    },
    [forceReload]
  );

  const deleteUser = useCallback(
    async (id) => {
      try {
        await InstitutionService.delete(id);
      } catch (e) {
        setErrorMessage([e.response.status, e.response.statusText]);
        console.log(e);
      }
      forceReload();
    },
    [forceReload]
  );

  const getDisable = (user) => {
    if (user.is_active) {
      return {
        label: "Disable",
        callback: async (id) => {
          deleteUser(id);
        },
      };
    }
    return {
      label: "Activate",
      callback: async (id) => {
        activateUser(id);
      },
    };
  };

  const getTableOptions = (user) => {
    return [
      getDisable(user),
      {
        label: "Edit",
        callback: async (id) => navigate(`${id}/edit`),
        newTabOption: true,
        newTabCallback: async (id) =>
          window.open(`/home/admin/institutions/${id}/edit`),
      },
      {
        label: "View",
        callback: async (id) => navigate(`${id}/`),
        newTabOption: true,
        newTabCallback: async (id) =>
          window.open(`/home/admin/institutions/${id}/`),
      },
    ];
  };

  return (
    <Container>
      <ButtonContainer>
        <Button testID={`${testID}-Create`} to="new">
          Create new Institution
        </Button>
      </ButtonContainer>
      <SortContainer>
        <SearchInput
          testID={testID}
          placeholder="Search by Institution"
          max={true}
          value={debouncedOptions.displaySearchText}
          onChange={(e) => {
            onChangeDebounced({ displaySearchText: e.target.value });
          }}
        />
        <SelectV2
          testID={testID}
          style={{ margin: "0 22px" }}
          label="Sort by:"
          options={TITLES}
          selected={options.columnSorted}
          onChange={(columnSorted) => onChange({ columnSorted })}
        />
        <SortButton
          value={options.ascending}
          onChange={(value) => {
            onChange({ ascending: value });
          }}
        />
      </SortContainer>
      <Table
        testID={testID}
        titles={InstitutionTableTitles}
        label="Institutions per page"
        data={data.results}
        totalPages={options.totalPages}
        rowsPerPage={options.rowsPerPage}
        pageSelected={debouncedOptions.displayPageSelected}
        withDebouncer={true}
        withOptions
        onChange={onChange}
        onChangeDebounced={onChangeDebounced}
        renderRow={(item, index) => (
          <tr key={item.id}>
            <td>
              <TableOptionsComponent
                id={item.id}
                testID={`${testID}-${index}`}
                options={getTableOptions(item)}
              />
            </td>
            <td> {item.name} </td>
            <td> {item.programs.length} </td>
            <td>
              {" "}
              {item.account_representative.first_name}{" "}
              {item.account_representative.last_name}{" "}
            </td>
            <td>
              {item.is_active ? (
                <Active onClick={() => navigate(`${item.id}`)}> Active </Active>
              ) : (
                <Disabled href="/#">Disabled </Disabled>
              )}
            </td>
          </tr>
        )}
      />
      <ErrorModal
        color="#eee"
        buttonLabel={"Back"}
        callback={() => {
          toggleError(["", ""]);
        }}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
      />
    </Container>
  );
}

const Container = styled.section``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SortContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 22px;
`;

const Active = styled.a`
  color: #0c7b7e;
  text-decoration: none;
`;

const Disabled = styled.a`
  text-decoration: none;
  color: #767676;
`;

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ReactComponent as DisableIcon } from "../assets/Close.svg";
import { ReactComponent as ActivateIcon } from "../assets/CheckIconDark.svg";
import { ReactComponent as EditIcon } from "../assets/Edit.svg";

export default function Panel({
  testID,
  title,
  children,
  disable,
  edit,
  close,
  onClick,
  color,
  disableOnClick,
  enable,
  enableOnClick,
  noBorder = false,
  isModal = false,
  deleteModal = false,
}) {
  const navigate = useNavigate();

  return (
    <Container data-e2e-id={testID}>
      <Header data-e2e-id={`${testID}-Header`} color={color} isModal={isModal}>
        <Title>{title}</Title>
        <OptionsContainer>
          {disable && (
            <OptionContainer onClick={disableOnClick}>
              <DisableIcon fill="#332525" />
              <OptionLabel>Disable</OptionLabel>
            </OptionContainer>
          )}
          {enable && (
            <OptionContainer onClick={enableOnClick}>
              <ActivateIcon width={19} height={20} fill="#332525" />
              <OptionLabel>Activate</OptionLabel>
            </OptionContainer>
          )}
          {close && (
            <OptionContainer onClick={onClick} isModal={isModal}>
              <DisableIcon fill="#332525" />
            </OptionContainer>
          )}
          {edit && (
            <OptionContainer onClick={() => navigate("edit")}>
              <EditIcon stroke="#093156" />
              <OptionLabel style={{ color: "#093156" }}>Edit</OptionLabel>
            </OptionContainer>
          )}
        </OptionsContainer>
      </Header>
      <ChildrenContainer
        data-e2e-id={`${testID}-Body`}
        deleteModal={deleteModal}
        noBorder={noBorder}
      >
        {children}
      </ChildrenContainer>
    </Container>
  );
}

const getPadding = (isModal, color) => {
  if (!isModal) {
    return "padding: 10px 22px;";
  }
  if (color === "#eee" || color === "#EEE") {
    return "padding: 13px 22px 12px 22px; height: 44px;";
  }
  return "padding: 22px;";
};

const Container = styled.div`
  background: #ffffff;
  border-radius: 5px;
`;

const ChildrenContainer = styled.div`
  padding: 22px;
  ${(props) => {
    return props.deleteModal ? "padding-top: 0px;" : "";
  }}
  ${(props) => {
    if (!props.noBorder) {
      return `border-right: 1px solid #c2c2c2;
      border-left: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;`;
    }
    return ``;
  }}
  border-radius: 0 0 5px 5px;
`;

const Header = styled.div`
  background: ${(props) => props.color};
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props) => {
    return getPadding(props.isModal, props.color);
  }}
  border-radius: 5px 5px 0 0;
`;

const Title = styled.h3`
  font-family: "Bebas Neue", sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #332525;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 44px;

  &:hover {
    cursor: pointer;
  }

  svg {
    ${(props) => {
      return !props.isModal ? "margin-right: 7px;" : "";
    }}
  }
`;

const OptionLabel = styled.div`
  font-size: 14px;
`;

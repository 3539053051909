import styled from "styled-components";

import magnifier from "../assets/magnifierBlue.svg";

export default function SearchInput({ placeholder, testID, ...props }) {
  return (
    <Container data-e2e-id={`${testID}-Search`} {...props}>
      <img src={magnifier} alt="Magnifier Glass" />
      <Input
        placeholder={placeholder}
        onChange={props.onChange}
        value={props.value}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 5px;

  img {
    width: 22px;
    height: 22px;
    position: absolute;
    left: 10px;
  }
`;

const Input = styled.input`
  width: 80%;
  height: 40px;
  border: none;
  padding-left: 50px; // img left position + width + real padding

  ::placeholder {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #767676;
  }

  :focus {
    outline: none;
  }
`;

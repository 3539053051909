import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";

const SaveAndCancel = ({
  testID,
  children,
  to = "",
  onClick = () => {},
  loading = false,
  ...props
}) => {
  const navigate = useNavigate();

  function _onClick() {
    if (!loading) {
      onClick();
      to && navigate(to);
    }
  }

  return (
    <Wrap data-e2e-id={`${testID}-FormButtons`}>
      {" "}
      <ContainerCancel {...props} onClick={_onClick}>
        <p>Cancel</p>
      </ContainerCancel>
      <ContainerSave {...props} htmlType="submit">
        {loading ? (
          <ClipLoader color={"#FFF"} size={20} loading={loading} />
        ) : (
          <p> Save </p>
        )}
      </ContainerSave>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: -22px;
  gap: 22px;
`;

const ContainerSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #093156;
  border-radius: 30px;
  padding: 10px 44px;
  background: #093156;
  margin-bottom: 22px;
  cursor: pointer;

  svg {
    margin-right: 12px;
    position: relative;
    top: 2px;
  }

  p {
    color: #fff;
    font-weight: 500;
  }
`;

const ContainerCancel = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #093156;
  border-radius: 30px;
  padding: 10px 44px;
  background: #fff;
  margin-bottom: 22px;
  cursor: pointer;

  svg {
    margin-right: 12px;
    position: relative;
    top: 2px;
  }

  p {
    color: #093156;
    font-weight: 400;
  }
`;

export default SaveAndCancel;

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import NavItem from "./NavItem";
import AnalysisIcon from "../../assets/Analysis.svg";
import AdminPanelIcon from "../../assets/AdminPanel.svg";
import LogoIcon from "../../assets/Logo.svg";
import LogoutIcon from "../../assets/Logout.svg";
import QuestionIcon from "../../assets/Question.svg";
import TransferIcon from "../../assets/Transfer.svg";
import UserAdminIcon from "../../assets/UserAdmin.svg";

import { logout, userSelector } from "../../store/reducers/userReducer";
import AuthService from "../../services/authentication";
import { clearData } from "../../store/reducers/institutionReducer";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onLogout() {
    dispatch(logout());
    dispatch(clearData());
    AuthService.logout();
    navigate("/login");
  }
  const user = useSelector(userSelector);

  return (
    <Container>
      <TopItems>
        <img alt="Logo" src={LogoIcon} />
        <NavItemWrap>
          <NavItem
            testID={"Analysis-Tab"}
            icon={AnalysisIcon}
            label="Analysis"
            to="/home/analysis"
          />
          <NavItem
            testID={"Transfer-Tab"}
            icon={TransferIcon}
            label="Transfer"
            to="/home/transfer"
          />
          {(user.is_user_admin || user.is_staff) && (
            <NavItem
              testID={"UserAdmin-Tab"}
              icon={UserAdminIcon}
              label="User Admin"
              to="/home/users"
            />
          )}
          {user.is_staff && (
            <NavItem
              testID={"AdminPanel-Tab"}
              icon={AdminPanelIcon}
              label="Admin Panel"
              to="/home/admin"
            />
          )}
        </NavItemWrap>
      </TopItems>
      <BottomItems>
        <NavItem
          testID={"Support-Tab"}
          icon={QuestionIcon}
          to="/home/support"
        />
        <NavItem
          testID={"Logout-Tab"}
          icon={LogoutIcon}
          label="Logout"
          onClick={onLogout}
        />
      </BottomItems>
    </Container>
  );
};

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 88px;
  height: 100vh;
  padding: 7px 0 22px;
  z-index: 1;
  background: linear-gradient(225deg, #1c3f5c 0%, #2d6672 100%);
  position: fixed;
`;

const TopItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;
const NavItemWrap = styled.div`
  margin-top: 50px;
`;

const BottomItems = styled.div`
  width: 100%;
`;

export default Navbar;

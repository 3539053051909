import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import Modal from "../../components/Modal";
import Subtitle from "../../components/Subtitle";
import Table from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import ErrorModal from "../../components/ErrorModal";
import Warning from "../../components/Warning/Warning";
import InputV2 from "../../components/Input/TextnputV2";
import TextInput from "../../components/Input/TextInput";
import RadioButton from "../../components//Button/RadioButton";
import TableOptionsComponent from "../../components/TableOptions";

import { ReactComponent as InfoIcon } from "../../assets/circle-alert.svg";
import { ReactComponent as FilesIcon } from "../../assets/Files.svg";
import { ReactComponent as Line } from "../../assets/Line.svg";

import OriginDataService from "../../services/originData";
import { OriginDataTableTitle } from "../../services/data";
import useQuery from "../../hooks/useQuery";
import usePagination from "../../hooks/usePagination";
import { userSelector } from "../../store/reducers/userReducer";
import { institutionSelector } from "../../store/reducers/institutionReducer";
import { setPeriod, setProgram } from "../../store/reducers/institutionReducer";
import {
  Section,
  TableContainer,
  BoldText,
  DeleteModalText,
  DeleteModalTextContainer,
  HorizontalLine,
  LineContainer,
  RightContainer,
  Select,
  SelectedFileContainer,
  Text,
  LeftContainer,
} from "./styles";
import {
  fetchProgramsStaffUser,
  fetchPeriods,
  mapPrograms,
} from "../../utils/analysis";
import { parseDate, parseName } from "../../utils/parses";

export default function OriginData() {
  const query = useQuery();
  const warning = query.get("warning");

  const {
    is_staff,
    institution: institutionState,
    programs: userPrograms,
  } = useSelector(userSelector);
  const { selectedInstitution, selectedPeriod, selectedProgram } =
    useSelector(institutionSelector);
  const dispatch = useDispatch();

  const programsState =
    (is_staff ? institutionState?.programs : userPrograms) ?? [];
  const parsedPrograms = mapPrograms(programsState);

  const [warningMessage, setWarningMessage] = useState(["", ""]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteModalFile, setDeleteModalFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [newPeriodInput, setNewPeriodInput] = useState(false);
  const [programs, setPrograms] = useState(parsedPrograms);
  const [programSelected, setProgramSelected] = useState(selectedProgram);
  const [periods, setPeriods] = useState(null);
  const [periodSelected, setPeriodSelected] = useState(selectedPeriod);
  const [newPeriod, setNewPeriod] = useState("");
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(["", ""]);
  const [isMultipleErrors, setIsMultipleErrors] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");

  const toggleError = (message) => {
    setErrorMessage(message);
  };
  const toggleWarning = (message) => {
    setWarningMessage(message);
  };

  useEffect(() => {
    if (openModal) {
      setProgramSelected(selectedProgram);
      setPeriodSelected(selectedPeriod);
    }
  }, [openModal]);

  useEffect(() => {
    if (warning) {
      toggleWarning([
        "You are being redirected to the Origin Data page because the program you have access has no periods yet.",
        "Warning: Redirect",
      ]);
    }
  }, [warning]);

  const { getRootProps, open, acceptedFiles, getInputProps, isDragReject } =
    useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      maxFiles: 1,
      accept: ".csv",
    });

  const [
    data,
    options,
    debouncedOptions,
    onChange,
    onChangeDebounced,
    forceReload,
    isLoading,
  ] = usePagination(OriginDataService.getAll, {}, true);

  const getColor = () => {
    if (isDragReject) {
      return "blue";
    }
    return "black";
  };

  const clearFiles = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
  };

  const uploadData = async () => {
    let shouldReload = true;
    setIsLoadingUpload(true);

    try {
      if (acceptedFiles.length > 0) {
        const bodyFormData = new FormData();
        bodyFormData.append("file", acceptedFiles[0]);
        bodyFormData.append("program_id", programSelected.value);
        bodyFormData.append("existing_period", !newPeriodInput);
        bodyFormData.append(
          "institution_id",
          selectedInstitution.value || institutionState.id
        );

        if (!newPeriodInput) {
          bodyFormData.append("period_id", periodSelected.value);
        } else {
          bodyFormData.append("period_name", newPeriod);
        }

        const response = await OriginDataService.create(bodyFormData);
        if (response.status !== 201) {
          throw response;
        }

        await dispatch(
          setProgram({
            value: programSelected.value,
            label: programSelected.label,
            is_predictions_enabled: programSelected.is_predictions_enabled,
          })
        );
        await dispatch(
          setPeriod({
            value: response?.data.period.id,
            label: response?.data.period.name,
          })
        );
      }
    } catch (e) {
      shouldReload = false;
      handleError(e);
    } finally {
      clearFiles();
      setIsLoadingUpload(false);

      if (shouldReload) {
        setOpenModal(false);
        forceReload();
      }
    }
  };

  const handleError = (e) => {
    setIsMultipleErrors(false);
    setOverrideMessage(true);
    if (e?.response?.data?.file) {
      if (e?.response.data.file.errors) {
        setIsMultipleErrors(true);
        setErrorMessage([e.response.status, e.response.data.file.errors]);
        return;
      } else {
        setOverrideMessage(true);
        setErrorMessage([e.response.status, e.response.data.file.error]);
      }
    } else {
      setErrorMessage([e.response.status, e.response.statusText]);
    }
  };

  const tableOptions = [
    {
      label: "Download",
      callback: async (id) => {
        const response = await OriginDataService.getDownload(id);
        window.open(response.data);
      },
    },
    {
      label: "Delete",
      callback: async (id) => {
        setOpenDeleteModal(true);
        setDeleteModalFile(id);
      },
    },
  ];

  const deleteData = async () => {
    setIsLoadingDelete(true);
    try {
      const response = await OriginDataService.delete(deleteModalFile);
      if (response) {
        setOpenDeleteModal(false);
        forceReload();
        setIsLoadingDelete(false);
      }
    } catch (e) {
      setOpenDeleteModal(false);
      setErrorMessage([e.response.status, e.response.statusText]);
      setIsLoadingDelete(false);
    }
  };

  const handleClick = () => {
    setOpenModal(false);
    setNewPeriodInput(false);
    clearFiles();
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteModalFile(null);
    setConfirmationText("");
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      const programs = is_staff
        ? await fetchProgramsStaffUser(selectedInstitution.value)
        : mapPrograms(programsState);

      setPrograms(programs);
      setProgramSelected(programs[0]);
    };

    fetchPrograms();

    return () => {};
  }, [selectedInstitution?.value]);

  useEffect(() => {
    if (programSelected && programSelected.value) {
      fetchPeriods(programSelected.value).then((parsedPeriods) => {
        setPeriods(parsedPeriods);
        if (parsedPeriods[0]) {
          setPeriodSelected(parsedPeriods[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programSelected?.value]);

  return (
    <>
      {data.results &&
        (data.results.length === 0 || selectedPeriod.value === "") &&
        !isLoading && (
          <Warning>
            <InfoIcon />
            No data available for the selected period
          </Warning>
        )}
      <Section>
        <Subtitle>DATA UPLOAD HISTORY</Subtitle>
        <Button
          style={{ margin: "0px", position: "relative" }}
          onClick={() => setOpenModal(!openModal)}
        >
          Upload New Data
        </Button>
      </Section>
      <TableContainer>
        <Table
          withOptions
          titles={OriginDataTableTitle}
          label="Files per page"
          data={data.results}
          loading={isLoading}
          totalPages={options.totalPages}
          rowsPerPage={options.rowsPerPage}
          pageSelected={debouncedOptions.displayPageSelected}
          withDebouncer={true}
          onChange={onChange}
          onChangeDebounced={onChangeDebounced}
          renderRow={(item, i) => (
            <tr key={item.id}>
              <td>
                {" "}
                <TableOptionsComponent
                  id={item.id}
                  options={tableOptions ? tableOptions : []}
                />{" "}
              </td>
              <td>{item.file_name}</td>
              <td> {item.num_of_applicants} </td>
              <td> {parseDate(item.uploaded_date) || "-"} </td>
              <td>
                {" "}
                {item.uploaded_by.first_name +
                  " " +
                  item.uploaded_by.last_name}{" "}
              </td>
              <td> {parseDate(item.prediction_analyzed) || "-"} </td>
              <td> {parseDate(item.insights_analyzed) || "-"} </td>
              <td> {parseDate(item.competencies_analyzed) || "-"} </td>
            </tr>
          )}
        />
      </TableContainer>
      {openDeleteModal && (
        <Modal
          flex={true}
          onClick={closeDeleteModal}
          label={"Delete Data"}
          onSubmit={deleteData}
          isLoading={isLoadingDelete}
          noBorder
          deleteModal={true}
          disabled={confirmationText !== "DELETE PERMANENTLY"}
        >
          <DeleteModalTextContainer>
            <DeleteModalText>
              Are you sure you want to delete data from{" "}
              {
                data.results.find((item) => item.id === deleteModalFile)
                  ?.file_name
              }
              ?
            </DeleteModalText>
            <DeleteModalText>
              Type “DELETE PERMANENTLY” in order to delete your data. This
              cannot be undone.
            </DeleteModalText>
            <TextInputV2
              type="text"
              name="confirmationText"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              placeholder="Type Here"
              inputColor={"#332525"}
            />
          </DeleteModalTextContainer>
        </Modal>
      )}
      {openModal && (
        <Modal
          flex={true}
          onClick={handleClick}
          label="Save"
          disabled={newPeriodInput && !newPeriod}
          onSubmit={uploadData}
          title="Applicant Data Upload"
          isLoading={isLoadingUpload}
          color="#eee"
        >
          <LeftContainer>
            <Select
              label="Program"
              options={programs.length ? programs : []}
              selected={programSelected}
              onChange={setProgramSelected}
              size={15}
              title={programSelected?.label}
            />{" "}
            <RadioButtonsWrap>
              <RadioButton
                buttonLabel="Existing Period"
                name="existingPeriod"
                dark={true}
                value={!newPeriodInput}
                onChange={() => {
                  if (newPeriodInput) {
                    if (periods.length > 0) {
                      setNewPeriodInput(!newPeriodInput);
                    }
                  } else {
                    setNewPeriodInput(!newPeriodInput);
                  }
                }}
              />
              <RadioButton
                buttonLabel="New Period"
                name="newPeriod"
                dark={true}
                value={newPeriodInput}
                onChange={() => {
                  if (newPeriodInput) {
                    if (periods.length > 0) {
                      setNewPeriodInput(!newPeriodInput);
                    }
                  } else {
                    setNewPeriodInput(!newPeriodInput);
                  }
                }}
              />
            </RadioButtonsWrap>
            {newPeriodInput ? (
              <Input
                label="Period"
                value={newPeriod}
                placeholder="Type Here"
                inputColor="#332525"
                onChange={(e) => {
                  setNewPeriod(e.target.value);
                }}
              />
            ) : (
              <Select
                label="Period"
                options={periods ? periods : [{ value: "", label: "" }]}
                selected={periodSelected}
                onChange={setPeriodSelected}
                size={15}
                title={periodSelected?.label}
              />
            )}
          </LeftContainer>
          <RightContainer
            color={getColor()}
            {...getRootProps({ className: "dropzone" })}
          >
            {!(acceptedFiles.length > 0) ? (
              <>
                <h3>Drag and Drop</h3>
                <LineContainer>
                  <Line />
                  <span>or </span>
                  <Line />
                </LineContainer>
                <input {...getInputProps()} />
                <Button type="button" onClick={open}>
                  Upload
                </Button>
                <span style={{ marginTop: "-35px" }}> CSV File Only </span>
              </>
            ) : (
              <SelectedFileContainer>
                <FilesIcon />
                <Text>
                  <BoldText>{parseName(acceptedFiles[0].name)}</BoldText>
                  &nbsp;
                  {"is attached!"}
                </Text>
                <LineContainer>
                  <HorizontalLine />
                </LineContainer>
              </SelectedFileContainer>
            )}
          </RightContainer>{" "}
        </Modal>
      )}
      <ErrorModal
        testID={"OriginData-Warning"}
        color="#eee"
        buttonLabel={"OK"}
        error={false}
        callback={() => {
          toggleWarning(["", ""]);
        }}
        isOpen={warningMessage[0]}
        errorMessage={warningMessage}
      />
      <ErrorModal
        color="#eee"
        buttonLabel={"Back"}
        isMultipleErrors={isMultipleErrors}
        overrideMessage={overrideMessage}
        callback={() => {
          toggleError(["", ""]);
          setIsMultipleErrors(false);
          setOverrideMessage(false);
        }}
        isOpen={errorMessage[0]}
        errorMessage={errorMessage}
      />
    </>
  );
}

export const TextInputV2 = styled(TextInput)`
  height: 44px;
  padding: 0px;
  width: 100%;
  padding: 10px 22px;
  margin-top: 19px;
  border: 1px solid black;
  p {
    display: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const RadioButtonsWrap = styled.div`
  padding: 22px 0px;

  p {
    margin-right: 0;
  }
`;

const Input = styled(InputV2)`
  height: 44px;
  width: 162px;
`;

import React from "react";
import styled from "styled-components";
import DEFAULT_IMAGE from "./DEFAULT_IMAGE.jpg";
import Button from "../Button/Button";

const ImageInput = ({
  buttonText,
  headerText,
  descriptionText,
  onChange,
  image,
  testID,
}) => {
  const handleFile = (event) => {
    onChange(event.target.files[0]);
  };

  return (
    <Container data-e2e-id={`${testID}-ImageInput`}>
      <ImageContainer src={image ? image : DEFAULT_IMAGE} />
      <InputContainer>
        <InputContainerHeader>{headerText}</InputContainerHeader>
        <FileUploader buttonText={buttonText} handleFile={handleFile} />
        <InputContainerDescription>{descriptionText}</InputContainerDescription>
      </InputContainer>
    </Container>
  );
};

export default ImageInput;

const InputContainerDescription = styled.p`
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
`;

const InputContainerHeader = styled.p`
  color: #332525;
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ImageContainer = styled.img`
  height: 90px;
  width: 90px;
  border-radius: 45px;
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FileUploader = (props) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    props.handleFile(event);
  };
  return (
    <>
      <Button type={"button"} onClick={handleClick}>
        {props.buttonText}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};

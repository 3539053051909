import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";

import Button from "../components/Button/Button";
import Header from "../components/Header";
import Panel from "../components/Panel";
import DEFAULT_IMAGE from "../components/ImageInput/DEFAULT_IMAGE.jpg";
import ProgramFilter from "../components/Sort/Filter";
import Success from "../components/Success/Success";
import { ReactComponent as CheckCircle } from "../assets/CheckCircle.svg";

import { institutionSelector } from "../store/reducers/institutionReducer";
import { userSelector } from "../store/reducers/userReducer";
import InstitutionService from "../services/institution";
import SupportService from "../services/support";

function Support() {
  const user = useSelector(userSelector);
  const { selectedInstitution } = useSelector(institutionSelector);
  const [supportUser, setSupportUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState({ message: "" });
  const [showReceivedMessage, setShowReceivedMessage] = useState(false);
  const { message } = inputValue;

  const fetchUser = useCallback(async () => {
    if (!user.is_staff) {
      setSupportUser(user.institution.account_representative);
    } else {
      const response = await InstitutionService.get(selectedInstitution.value);
      setSupportUser(response.data.account_representative);
    }
  }, [selectedInstitution]);

  useEffect(() => fetchUser(), [fetchUser]);

  const clearForm = () => {
    setShowReceivedMessage(true);
    setInputValue({
      message: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await SupportService.create({
        institution_id: user.institution_id
          ? user.institution_id
          : selectedInstitution.value,
        message,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    clearForm();
  };

  const parseUrl = (url) => {
    if (!url) {
      return DEFAULT_IMAGE;
    }
    if (url.startsWith("https://s3")) {
      return url;
    } else if (url) {
      return "https://s3-us-west-2.amazonaws.com/harqen-development/" + url;
    }
  };

  return (
    <>
      <Header testID={"Support"} title="Support" />
      <ProgramFilter isOnlyInstitution />
      <Container>
        <Panel title="Support" edit={false} color="#EEEEEE" disable={false}>
          {showReceivedMessage && (
            <Success>
              <CheckCircle size={24} color={"#155724"} />
              <SuccessContainer>
                <h2 style={{ color: "inherit" }}>
                  Thanks {user?.first_name}! <br />{" "}
                </h2>
                <p style={{ color: "inherit" }}>
                  {" "}
                  We received your email and will respond shortly{" "}
                </p>
              </SuccessContainer>
            </Success>
          )}
          <TextWrap>
            <Text>
              <p>
                Questions you need answered? Something not going quite right?
                Reach out to your account representative.
              </p>
              <TextImageWrap>
                <Image
                  src={parseUrl(supportUser.profile_picture)}
                  alt="Profile Picture"
                />
                <TextImage>
                  <Rep>Your account representative</Rep>
                  <Name>
                    {supportUser.first_name} {supportUser.last_name}
                  </Name>
                  <Number>{supportUser.phone_number}</Number>
                  <Mail>{supportUser.email}</Mail>
                </TextImage>
              </TextImageWrap>
            </Text>
            <form onSubmit={handleSubmit}>
              <TextareaWrap>
                <span>Send {supportUser.first_name} a message</span>
                <Textarea
                  onChange={handleChange}
                  value={message}
                  name="message"
                />
                <ButtonContainer>
                  <Button style={{ marginBottom: "0px" }}>
                    {loading ? (
                      <ClipLoader size={24} loading={loading} color={"#FFF"} />
                    ) : (
                      "Send Message"
                    )}
                  </Button>
                </ButtonContainer>
              </TextareaWrap>
            </form>
          </TextWrap>
        </Panel>
      </Container>
    </>
  );
}

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #155724 !important;
`;

const Container = styled.div`
  width: 100%;
  background: #f9f9f9;
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 22px;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 90px;
  height: 90px;
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 20px;
`;

const TextImageWrap = styled.div`
  display: flex;
  margin-top: 20px;
  img {
    margin-left: 22px;
  }
`;

const TextImage = styled.div`
  margin-left: 20px;
`;

const Rep = styled.p`
  color: #767676;
  font-weight: 700;
`;

const Name = styled.p`
  font-weight: bold;
`;

const Number = styled.p`
  font-weight: 500;
`;

const Mail = styled.p`
  color: #093156;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

const TextareaWrap = styled.div`
  padding-top: 10px;
  span {
    color: #767676;
    font-weight: 700;
  }
`;

const Textarea = styled.textarea`
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #c2c2c2;
  outline: none;
  min-height: 312px;
  width: 100%;
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: flex-end;
`;

export default Support;

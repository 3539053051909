import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { ReactComponent as ThreeDots } from "../assets/ThreeDots.svg";
import { ReactComponent as NewTabIconGrey } from "../assets/NewTabIconGrey.svg";
import { ReactComponent as NewTabIconBlack } from "../assets/NewTabIconBlack.svg";

import useClickOutside from "../hooks/useClickOutside";

export default function TableOptionsComponent({
  options = [],
  isLastItem = false,
  changePadding = () => {},
  id,
  testID,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const ref = useRef(null);

  const toggling = () => {
    if (isLastItem) {
      changePadding();
    }
    setIsOpen(!isOpen);
  };

  const scrollEvent = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("scroll", scrollEvent, true);
    return () => {
      document.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  useClickOutside(ref, toggling, [isOpen]);

  return (
    <TableOptionsContainer
      ref={ref}
      data-e2e-id={`${testID}-Options`}
      onClick={(e) => {
        setXPos(e.pageX);
        setYPos(
          Math.min(e.pageY, window.innerHeight - 5 - options.length * 46)
        );
        toggling();
      }}
    >
      <StyledThreeDots />
      <Select
        data-e2e-id={`${testID}-Select`}
        xPos={xPos}
        yPos={yPos}
        id="floatingSelect"
        onClick={toggling}
      >
        {isOpen && (
          <List>
            {options.map((item, i) => (
              <ListItemComponent
                item={item}
                key={i}
                onClick={() => item.callback(id)}
                onClickNewTab={() => item.newTabCallback(id)}
              />
            ))}
          </List>
        )}
      </Select>
    </TableOptionsContainer>
  );
}

const ListItemComponent = ({ key, onClick, item, onClickNewTab }) => {
  const [hover, setHover] = useState(false);

  return (
    <ListItem key={key} onClick={onClick}>
      <ListItemContainer>
        <div>{item.label}</div>
        {item.newTabOption && (
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => {
              setHover(false);
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClickNewTab();
            }}
          >
            {!hover && <NewTabIconGrey />}
            {hover && <NewTabIconBlack />}
          </div>
        )}
      </ListItemContainer>
    </ListItem>
  );
};

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledThreeDots = styled(ThreeDots)`
  margin-bottom: 2px;
  margin-left: 4px;
`;

const TableOptionsContainer = styled.div`
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  :hover {
    background: #e7ebee;
  }
`;

const Select = styled.div`
  z-index: 10;
  left: ${(props) => props.xPos + 10}px;
  top: ${(props) => props.yPos}px;
  position: fixed;
  border-radius: 10px;
`;

const List = styled.ul`
  width: 200px;
  border: 1px solid #767676;
  border-radius: 5px;
  margin-left: -13px;
  background: #fff;
  li:first-child {
    border-top: none;
  }
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
  border: 1px solid #767676;
  border-bottom: none;
  border-right: none;
  border-left: none;
  font-family: "Lato";
  color: #332525;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  &:hover {
    background-color: lightgray;
  }
`;

import { useRef, useState } from "react";
import styled from "styled-components";

import arrowDown from "../assets/arrow-down.svg";
import arrowUp from "../assets/arrow-up.svg";

import useClickOutside from "../hooks/useClickOutside";

export default function SelectV2({
  selected,
  options,
  onChange,
  small,
  testID,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggling = () => setIsOpen(!isOpen);

  useClickOutside(ref, toggling, [isOpen]);

  const onOptionClicked = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  let biggestLabel = "";
  for (const option of options) {
    if (option.label.length > biggestLabel.length) {
      biggestLabel = option.label;
    }
  }

  return (
    <Container
      data-e2e-id={`${testID}-Select`}
      ref={ref}
      size={biggestLabel.length}
      onClick={toggling}
      {...props}
    >
      <Label small={props.small}>{props?.label}</Label>
      <Selected onClick={toggling}>
        <SelectedText>{selected?.label}</SelectedText>
        <Image src={isOpen ? arrowUp : arrowDown} alt="arrow down" />
      </Selected>
      {isOpen && (
        <List>
          {options.map((item) => (
            <ListItem key={item.value} onClick={() => onOptionClicked(item)}>
              {item?.label}
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => (props.error ? "red" : "#c2c2c2")};
  border-radius: 5px;
  padding: 12px 12px 8px 12px;
  align-items: center;
  justify-content: space-between;
  min-width: 107px;
  width: 100%;
  max-width: ${(props) => props.size * 10 + "px"};
  white-space: nowrap;
`;

const Selected = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

const SelectedText = styled.span`
  font-size: 16px;
  margin-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const List = styled.ul`
  background-color: white;
  position: absolute;
  z-index: 500;
  top: 95%;
  left: -1px;
  max-height: 200px;
  overflow: auto;
  width: calc(100% + 2px);
  border: 1px solid #c2c2c2;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.5rem;
  padding: 8px 0px 10px 10px;
  cursor: pointer;
  font-family: "Lato";
  color: #332525;

  &:hover {
    background-color: lightgray;
  }
`;

const Label = styled.label`
  font-family: "Lato";
  color: #093156;
  background: #f9f9f9;
  white-space: nowrap;
  position: absolute;
  font-size: 14px;
  line-height: 20px;
  top: -11px;
  left: 12px;
`;

const Image = styled.img`
  width: 12px;
  height: 6.15px;
`;

import api from "./api";
import { UserStatusEnum } from "../utils/users";

export default class StaffService {
  static async getAll(params) {
    return api.get("/staffs/", {
      params,
    });
  }
  static async delete(id) {
    const response = await api.delete("/staffs/" + id + "/");
    return response;
  }
  static async enable(id) {
    const response = await api.patch(`staffs/${id}/`, {
      is_active: UserStatusEnum.ACTIVE,
    });
    return response;
  }
  static async save(userInfo, id) {
    if (id != null) {
      const response = api({
        method: "put",
        url: `/staffs/${id}/`,
        data: userInfo,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response;
    } else {
      const response = api({
        method: "post",
        url: `/staffs/`,
        data: userInfo,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response;
    }
  }
  static async get(id) {
    const response = await api.get(`/staffs/${id}/`);
    return response;
  }
  static async resendActivationEmail(id) {
    return api.put(`/staffs/${id}/resend-activation-email/`);
  }
}

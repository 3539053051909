import { UserStatusEnum } from "../../utils/users";

const SET_USER = "SET_USER";
const LOGOUT = "LOGOUT";

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

const initialState = {
  id: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  institution: { id: "" },
  email: "",
  is_staff: false,
  is_active: UserStatusEnum.CREATED,
  is_account_representative: false,
  is_user_admin: false,
  profile_picture: "",
  token: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.user,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}

export function userSelector(state) {
  return { ...state.user, isLoggedIn: Boolean(state.user.id) };
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { institutionSelector } from "../store/reducers/institutionReducer";
import { userSelector } from "../store/reducers/userReducer";
import useDebounce from "./useDebounce";

export default function usePagination(
  getter,
  defaultSortedColumn = {},
  useDropdownFilters,
  useInstitutionDropdownFilter = false
) {
  const location = useLocation();
  const isFileStorageScreen = location.pathname.includes("storage");

  const { selectedPeriod, selectedInstitution, selectedProgram } =
    useSelector(institutionSelector);
  const { institution_id } = useSelector(userSelector);
  const isInitialMount = useRef(true);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({ results: [], count: 0 });
  const [options, setOptions] = useState({
    searchText: "",
    columnSorted: defaultSortedColumn,
    rowsPerPage: 10,
    pageSelected: 1,
    ascending: true,
    totalPages: Math.ceil(1),
  });
  const [totalPages, setTotalPages] = useState(1);

  const getInstitutionDropdownFilters = () => {
    return {
      institution__id: selectedInstitution.value
        ? selectedInstitution.value
        : institution_id,
    };
  };

  const getDropdownFilters = () => {
    return {
      student__period: selectedPeriod.value,
      origin_data__period: selectedPeriod.value,
      period__id: selectedPeriod.value,
      program__id: selectedProgram.value,
    };
  };

  const [debouncedOptions, setDebouncedOptions] = useState({
    displaySearchText: "",
    displayPageSelected: 1,
  });

  const forceReload = () => {
    fetchData();
  };

  const onChange = (changes = {}) => {
    const newOptions = { ...options, ...changes };
    setOptions(newOptions);
  };

  const onChangeDebounced = (changes = {}) => {
    const newOptions = { ...debouncedOptions, ...changes };
    setDebouncedOptions(newOptions);
  };

  const debouncedChange = useDebounce(onChange, 500);

  const getCurrentFilters = () => {
    let filters = {
      search: options.searchText,
      ordering: `${options.ascending ? "" : "-"}` + options.columnSorted.value,
      limit: options.rowsPerPage,
      offset: (options.pageSelected - 1) * options.rowsPerPage,
    };
    if (useDropdownFilters) {
      filters = { ...filters, ...getDropdownFilters() };
    }
    if (useInstitutionDropdownFilter) {
      filters = { ...filters, ...getInstitutionDropdownFilters() };
    }
    return filters;
  };

  const fetchData = async () => {
    const filters = getCurrentFilters();
    setIsLoading(true);

    try {
      const response = await getter(filters);

      if (response.data) {
        setData(response.data);
        if (isLoading === true) {
          setOptions({
            ...options,
            totalPages: Math.ceil(response.data.count / options.rowsPerPage),
          });
        }
        setTotalPages(Math.ceil(response.data.count / options.rowsPerPage));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (options.searchText !== debouncedOptions.displaySearchText) {
        debouncedChange({
          searchText: debouncedOptions.displaySearchText,
          pageSelected: 1,
        });
        onChangeDebounced({
          displayPageSelected: 1,
        });
      } else {
        debouncedChange({
          pageSelected: debouncedOptions.displayPageSelected,
        });
      }
    }
  }, [
    debouncedOptions.displaySearchText,
    debouncedOptions.displayPageSelected,
  ]);

  useEffect(() => {
    fetchData();
  }, [
    useInstitutionDropdownFilter
      ? selectedInstitution.value
      : isFileStorageScreen
      ? selectedProgram.value
      : selectedPeriod.value,
  ]);

  useEffect(() => {
    onChangeDebounced({
      displayPageSelected: 1,
    });
    fetchData();
  }, [options.rowsPerPage]);

  useEffect(() => {
    fetchData();
  }, [
    options.searchText,
    options.ascending,
    options.pageSelected,
    options.columnSorted,
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  return [
    data,
    { ...options, totalPages: totalPages },
    debouncedOptions,
    onChange,
    onChangeDebounced,
    forceReload,
    isLoading,
  ];
}
